import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "dialog-header" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "card-header" }
const _hoisted_5 = {
  class: "show-gap",
  style: {"flex-direction":"column"}
}
const _hoisted_6 = { class: "show-gap" }
const _hoisted_7 = {
  class: "show-gap",
  style: {"flex-direction":"column"}
}
const _hoisted_8 = { class: "show-gap" }

import {ref} from "vue" // 导入 Vue 的核心功能
import {useI18n} from "vue-i18n" // 导入国际化插件
import {ElScrollbar} from "element-plus";
import {autoHeight} from "@/services/tools";
import {Network} from 'vis-network';
import 'vis-network/styles/vis-network.css';
import {useFileStore} from "@/stores/fileStore"


export default /*@__PURE__*/_defineComponent({
  __name: 'FileGraph',
  props: { // 定义 props
  file_hash: String,
  file_name: String
},
  emits: ["update:isShow"],
  setup(__props, { emit: __emit }) {

const {t} = useI18n()

const graphDialog = ref<boolean>(false)
const graphHeight = autoHeight(240);
const sliceHeight = autoHeight(240);
const currentPage = ref(1);  // 当前页码
const pageSize = ref(10);  // 每页显示条目数
const totalItems = ref(0);  // 总条目数
const networkContainer = ref<HTMLDivElement | null>(null);
const networkInstance = ref<Network | null>(null);
const fileStore = useFileStore();
const data = ref<{ nodes: any[], edges: any[], total: number }>({nodes: [], edges: [], total: 0});
const activeTab = ref<string>('slice') // 默认打开“概览”页
const slice_loading = ref<boolean>(false)
const options = {
  nodes: {
    shape: 'circle',
    font: {
      size: 14,
    },
    color: {
      highlight: {
        background: "rgb(248, 152.1, 152.1)", // 背景红色
        border: "rgb(248, 152.1, 152.1)"  // 背景红色
      }
    },
  },
  edges: {
    arrows: 'to',
    font: {
      align: 'middle',
      size: 12,
    },
  },
  groups: {
    Document: {
      color: {
        background: 'rgb(51.2, 126.4, 204)',
        border: 'rgb(51.2, 126.4, 204)',
      },
      widthConstraint: {
        minimum: 70,
        maximum: 70, // 固定大小
      },
    },
    DocumentSlice: {
      color: {
        background: 'rgb(121.3, 187.1, 255)',
        border: 'rgb(121.3, 187.1, 255)',
      },
      widthConstraint: {
        minimum: 50,
        maximum: 50, // 固定大小
      },
    },
    Entity: {
      color: {
        background: 'rgb(247.5, 227.1, 196.5)',
        border: 'rgb(247.5, 227.1, 196.5)',
      },
      widthConstraint: {
        minimum: 30,
        maximum: 30, // 固定大小
      },
    },
    Concept: {
      color: {
        background: 'rgb(209.4, 236.7, 195.9)',
        border: 'rgb(209.4, 236.7, 195.9)',
      },
      widthConstraint: {
        minimum: 30,
        maximum: 30, // 固定大小
      },
    },
  },
  physics: {
    enabled: true,
  },
  layout: {
    improvedLayout: false
  }
}


// 定义组件的 props 和 emits
const props = __props
const emit = __emit // 定义 emits


const ini_dialog = async () => {

  await handleCurrentChange(1)
}

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

const load_graph = async (name: string) => {
  if (name != 'graph') return
  if (networkContainer.value && data.value) {
    slice_loading.value = true

    await sleep(200)
    networkInstance.value = new Network(networkContainer.value, data.value, options);

    slice_loading.value = false
  }
}

const save_graph = async () => {

}

// 关闭对话框并重置表单
const closeDialog = async () => {
  try {
    graphDialog.value = false // 隐藏对话框
    data.value = {nodes: [], edges: [], total: 0}
    activeTab.value = 'slice'
    // 清除图形时
    if (networkInstance.value) {
      networkInstance.value.destroy();
      networkInstance.value = null;
    }

    emit('update:isShow', false) // 触发 update:isShow 事件

  } catch (error) {
    console.error('Error closing dialog:', error) // 打印错误信息
  }
};

const handleSizeChange = async (val: number) => {
  if (props.file_hash) {
    slice_loading.value = true
    data.value = await fileStore.getFileGraph(props.file_hash, currentPage.value, val)
    totalItems.value = data.value.total
    pageSize.value = val;
    if (activeTab.value=="graph"){
      await load_graph("graph")
    }
    slice_loading.value = false
  }
};

const handleCurrentChange = async (val: number) => {
  if (props.file_hash) {
    slice_loading.value = true
    data.value = await fileStore.getFileGraph(props.file_hash, val, pageSize.value)
    totalItems.value = data.value.total
    currentPage.value = val;
    if (activeTab.value=="graph"){
      await load_graph("graph")
    }
    slice_loading.value = false
  }
};


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    visible: graphDialog.value,
    width: "90%",
    onOpen: ini_dialog,
    "align-center": "",
    center: "",
    "close-on-click-modal": false,
    "show-close": false,
    style: {"min-width":"1300px"}
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(__props.file_name), 1),
        _createVNode(_component_el_button, {
          type: "danger",
          onClick: closeDialog,
          circle: "",
          size: "small"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, { icon: "xmark" })
          ]),
          _: 1
        })
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_button, {
          type: "danger",
          onClick: closeDialog,
          plain: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "xmark",
              class: "icon-text-button"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('message.close')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_tabs, {
        modelValue: activeTab.value,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((activeTab).value = $event)),
        type: "border-card",
        class: "dialog-graph",
        onTabChange: load_graph
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tab_pane, { name: "slice" }, {
            label: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString(_ctx.$t("knowledge.slice")), 1)
            ]),
            default: _withCtx(() => [
              _withDirectives((_openBlock(), _createBlock(_unref(ElScrollbar), {
                class: "chat-content",
                height: _unref(sliceHeight)
              }, {
                default: _withCtx(() => [
                  (data.value.nodes)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.nodes, (node) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: node.id
                          }, [
                            (node.group =='DocumentSlice')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                  _createVNode(_component_el_card, {
                                    shadow: "hover",
                                    class: "system-card"
                                  }, {
                                    header: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_4, [
                                        _createVNode(_component_el_text, {
                                          tag: "b",
                                          size: "large"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t("knowledge.slice-num", {number: node.label})), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ])
                                    ]),
                                    footer: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_5, [
                                        _createElementVNode("div", null, [
                                          _createVNode(_component_el_text, {
                                            tag: "b",
                                            size: "large"
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.$t("knowledge.entity")), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _createElementVNode("div", _hoisted_6, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.nodes.filter
                              (n => data.value.edges.some(e => e.from === node.id && e.to === n.id && n.group === 'Entity')), (entity) => {
                                            return (_openBlock(), _createBlock(_component_el_tag, {
                                              type: "warning",
                                              round: "",
                                              key: entity.id
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(entity.title), 1)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128))
                                        ])
                                      ]),
                                      _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                                      _createElementVNode("div", _hoisted_7, [
                                        _createElementVNode("div", null, [
                                          _createVNode(_component_el_text, {
                                            tag: "b",
                                            size: "large"
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.$t("knowledge.concept")), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _createElementVNode("div", _hoisted_8, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.value.nodes.filter
                              (n => data.value.edges.some(e => e.from === node.id && e.to === n.id && n.group === 'Concept')), (concept) => {
                                            return (_openBlock(), _createBlock(_component_el_tag, {
                                              type: "success",
                                              round: "",
                                              key: concept.id
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(concept.title), 1)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128))
                                        ])
                                      ])
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_text, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(node.title), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["height"])), [
                [_directive_loading, slice_loading.value]
              ]),
              _createVNode(_component_el_pagination, {
                "current-page": currentPage.value,
                "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((currentPage).value = $event)),
                "page-size": pageSize.value,
                "onUpdate:pageSize": _cache[1] || (_cache[1] = ($event: any) => ((pageSize).value = $event)),
                "page-sizes": [10,20, 30],
                layout: "total, sizes ,prev, pager, next, jumper",
                total: totalItems.value,
                onSizeChange: _cache[2] || (_cache[2] = ($event: any) => (handleSizeChange(pageSize.value))),
                onCurrentChange: _cache[3] || (_cache[3] = ($event: any) => (handleCurrentChange(currentPage.value)))
              }, null, 8, ["current-page", "page-size", "total"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_tab_pane, { name: "graph" }, {
            label: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString(_ctx.$t("knowledge.graph")), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_row, { gutter: 10 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 21 }, {
                    default: _withCtx(() => [
                      _withDirectives((_openBlock(), _createBlock(_unref(ElScrollbar), {
                        class: "chat-content",
                        height: _unref(graphHeight)
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            ref_key: "networkContainer",
                            ref: networkContainer,
                            style: _normalizeStyle({ width: '100%', height: _unref(graphHeight) + 'px' })
                          }, null, 4)
                        ]),
                        _: 1
                      }, 8, ["height"])), [
                        [_directive_loading, slice_loading.value]
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 3 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_text, { "line-clamp": "10" }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode("左侧知识图谱实时展示当前文档页面的内容切片、实体与知识点间的关联关系。"),
                          _createElementVNode("br", null, null, -1),
                          _createElementVNode("br", null, null, -1),
                          _createTextVNode("可视化网络结构直观呈现知识节点间的逻辑关系与架构。"),
                          _createElementVNode("br", null, null, -1),
                          _createElementVNode("br", null, null, -1),
                          _createTextVNode("支持翻页浏览及鼠标滚轮缩放操作。")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_pagination, {
                "current-page": currentPage.value,
                "onUpdate:currentPage": _cache[4] || (_cache[4] = ($event: any) => ((currentPage).value = $event)),
                "page-size": pageSize.value,
                "onUpdate:pageSize": _cache[5] || (_cache[5] = ($event: any) => ((pageSize).value = $event)),
                "page-sizes": [10,20, 30],
                layout: "total, sizes ,prev, pager, next, jumper",
                total: totalItems.value,
                onSizeChange: _cache[6] || (_cache[6] = ($event: any) => (handleSizeChange(pageSize.value))),
                onCurrentChange: _cache[7] || (_cache[7] = ($event: any) => (handleCurrentChange(currentPage.value)))
              }, null, 8, ["current-page", "page-size", "total"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})