<script setup lang="ts">
// import {useRouter} from 'vue-router';
import {onMounted, ref, watch} from "vue";
import router from "@/router";
// 响应式变量记录激活的父菜单 index
const activeSubMenuIndex = ref<string | null>(null);
const cur_index = ref("0")

const isCollapse = ref<boolean>(localStorage.getItem("menu_export") === "true" || false)
defineProps({
  defaultActive: String
});

const emit = defineEmits(["selected_menu_item"]);

const exportMenu = () => {
  isCollapse.value = !isCollapse.value
  localStorage.setItem("menu_export", String(isCollapse.value));
}

const intoFunction = async (name: string) => {
  switch (name) {
    case 'menu-21':
      await router.push('/usermanage');
      break;
    case 'menu-12':
      await router.push('/prompt');
      break;
    case 'menu-11':
      await router.push('/robot');
      break;
    case 'menu-13':
      await router.push('/topic');
      break;
    case 'menu-14':
      await router.push('/chat_check');
      break;
    case 'menu-32':
      await router.push('/system');
      break;
    case 'menu-31':
      await router.push('/llm_setup');
      break;
    case 'menu-41':
      await router.push('/files');
      break;
    case 'menu-42':
      await router.push('/vectorization');
      break;
    case 'menu-43':
      await router.push('/search');
      break;
    case 'menu-51':
      await router.push('/api_manage');
      break;
    case 'menu-52':
      await router.push('/api_check');
      break;
    default:
      break;
  }
  emit('selected_menu_item', name);
};

// 统一处理菜单点击
const handleMenuItemClick = (parentIndex: string, menuKey: string) => {
  cur_index.value = parentIndex
  if (isCollapse.value) {
    activeSubMenuIndex.value = parentIndex; // 更新父级高亮状态
  } else {
    activeSubMenuIndex.value = "0"
  }
  intoFunction(menuKey); // 原有逻辑
};

const resetMenu = () => {
  if (isCollapse.value) {
    activeSubMenuIndex.value = cur_index.value
  } else {
    activeSubMenuIndex.value = "0"
  }
}

watch([cur_index,isCollapse], resetMenu)


onMounted(() => {
  handleMenuItemClick('5', 'menu-51')
});
</script>

<template>
  <el-button @click="exportMenu"
             size="large"
             type="primary"
             text
             bg
             style="border-radius: 0;width: 100%">
    <font-awesome-icon icon="bars" style="font-size: 22px;"/>
  </el-button>
  <el-menu
      :default-openeds="['5']"
      :default-active="defaultActive"
      :collapse="isCollapse"
      unique-opened
      class="el-menu-custom"
  >
    <el-sub-menu index="5" :class="{ 'active-submenu': activeSubMenuIndex === '5' }">
      <template #title>
        <font-awesome-icon icon="plug-circle-plus" class="title-icon"/>
        <span class="title">
          {{ $t('message.menu-5') }}</span>
      </template>
      <el-menu-item index="5-1" @click="handleMenuItemClick('5', 'menu-51')">
        <template #title>
          <font-awesome-icon icon="plug-circle-bolt" rotation=90 class="icon-text-button"/>
          <span>{{ $t('message.menu-51') }}</span>
        </template>
      </el-menu-item>
      <el-menu-item index="5-2" @click="handleMenuItemClick('5', 'menu-52')">
        <template #title>
          <font-awesome-icon icon="plug-circle-check" class="icon-text-button"/>
          <span>{{ $t('message.menu-52') }}</span>
        </template>
      </el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="1" :class="{ 'active-submenu': activeSubMenuIndex === '1' }">
      <template #title>
        <font-awesome-icon icon="hand-spock" class="title-icon"/>
        <span class="title">
          {{ $t('message.menu-1') }}</span>
      </template>
      <el-menu-item index="1-1" @click="handleMenuItemClick('1', 'menu-11')">
        <template #title>
          <font-awesome-icon icon="robot" class="icon-text-button"/>
          <span>{{ $t('message.menu-11') }}</span>
        </template>
      </el-menu-item>
      <el-menu-item index="1-2" @click="handleMenuItemClick('1', 'menu-12')">
        <template #title>
          <font-awesome-icon icon="chess-board" class="icon-text-button"/>
          <span>{{ $t('message.menu-12') }}</span>
        </template>
      </el-menu-item>
      <el-menu-item index="1-3" @click="handleMenuItemClick('1', 'menu-13')">
        <template #title>
          <font-awesome-icon icon="tags" class="icon-text-button"/>
          <span>{{ $t('message.menu-13') }}</span>
        </template>
      </el-menu-item>
      <el-menu-item index="1-4" @click="handleMenuItemClick('1', 'menu-14')">
        <template #title>
          <font-awesome-icon icon="list-check" class="icon-text-button"/>
          <span>{{ $t('message.menu-14') }}</span>
        </template>
      </el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="4" :class="{ 'active-submenu': activeSubMenuIndex === '4' }">
      <template #title>
        <font-awesome-icon icon="warehouse" class="title-icon"/>
        <span class="title">
          {{ $t('message.menu-4') }}</span>
      </template>
      <el-menu-item index="4-1" @click="handleMenuItemClick('4', 'menu-41')">
        <template #title>
          <font-awesome-icon icon="folder-tree" class="icon-text-button"/>
          <span>{{ $t('message.menu-41') }}</span>
        </template>
      </el-menu-item>
      <el-menu-item index="4-2" @click="handleMenuItemClick('4', 'menu-42')">
        <template #title>
          <font-awesome-icon icon="arrows-turn-to-dots" class="icon-text-button"/>
          <span>{{ $t('message.menu-42') }}</span>
        </template>
      </el-menu-item>
      <el-menu-item index="4-3" @click="handleMenuItemClick('4', 'menu-43')">
        <template #title>
          <font-awesome-icon icon="list-check" class="icon-text-button"/>
          <span>{{ $t('message.menu-43') }}</span>
        </template>
      </el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="2" :class="{ 'active-submenu': activeSubMenuIndex === '2' }">
      <template #title>
        <font-awesome-icon icon="users" class="title-icon"/>
        <span class="title">
          {{ $t('message.menu-2') }}</span>
      </template>
      <el-menu-item index="2-1" @click="handleMenuItemClick('2', 'menu-21')">
        <template #title>
          <font-awesome-icon icon="user-gear" class="icon-text-button"/>
          <span>{{ $t('message.menu-21') }}</span>
        </template>
      </el-menu-item>
      <el-menu-item index="2-2" @click="handleMenuItemClick('2', 'menu-22')">
        <template #title>
          <font-awesome-icon icon="address-book" class="icon-text-button"/>
          <span>{{ $t('message.menu-22') }}</span>
        </template>
      </el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="3" :class="{ 'active-submenu': activeSubMenuIndex === '3' }">
      <template #title>
        <font-awesome-icon icon="gear" class="title-icon"/>
        <span class="title">
          {{ $t('message.menu-3') }}</span>
      </template>
      <el-menu-item index="3-1" @click="handleMenuItemClick('3', 'menu-31')">
        <template #title>
          <font-awesome-icon icon="brain" class="icon-text-button"/>
          <span>{{ $t('message.menu-31') }}</span>
        </template>
      </el-menu-item>
      <el-menu-item index="3-2" @click="handleMenuItemClick('3', 'menu-32')">
        <template #title>
          <font-awesome-icon icon="gears" class="icon-text-button"/>
          <span>{{ $t('message.menu-32') }}</span>
        </template>
      </el-menu-item>
    </el-sub-menu>
  </el-menu>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

.el-menu-custom {
  height: auto;
  --el-menu-active-color: #{$header-font-color-light};
  --el-menu-border-color: Transparent;
  background-color: $menu-bg;
  --el-menu-bg-color: #{$menu-bg};
  --el-menu-hover-bg-color: #{$menu-hover-bg};
  border: 0;


  html.dark & {
    background-color: $menu-bg-dark;
    --el-menu-bg-color: #{$menu-bg-dark};
    --el-menu-hover-bg-color: #{$menu-sub-dark-bg};
  }
}


.el-menu-item.is-active {

  background-color: white;

  html.dark & {
    background-color: $main-background-dark;
  }
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin-left: 15px;
}

.title-icon {

  text-align: center;
  font-size: 17px;
  font-weight: bold;
}

/* 修改默认文字颜色 */
:deep(.el-sub-menu__title) {
  color: $header-font-color-light !important;
}

:deep(.active-submenu .el-sub-menu__title) {
  background-color: $header-font-color-light !important;
  color: #fff !important;
}

</style>
