import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "chat-window" }
const _hoisted_2 = { class: "message-wrapper" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "avatar-container"
}
const _hoisted_5 = { class: "message-col" }
const _hoisted_6 = {
  key: 0,
  class: "reason-card"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { key: 3 }
const _hoisted_11 = {
  key: 0,
  class: "left-button"
}
const _hoisted_12 = {
  key: 1,
  class: "right-button"
}
const _hoisted_13 = {
  key: 1,
  class: "avatar-container"
}
const _hoisted_14 = { class: "down-area" }

import {nextTick, onMounted, ref, watch} from 'vue';
import {autoHeight, showNotification,formatDate} from "@/services/tools";
import MarkdownIt from 'markdown-it';
import markdownItMathjax from 'markdown-it-mathjax3';
import 'github-markdown-css/github-markdown.css';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import Clipboard from 'clipboard';
import {ElScrollbar} from 'element-plus';
import {useI18n} from "vue-i18n";
import {useUserStore} from '@/stores/userStore';
import {ChatMessage} from '@/types/LLM';
import {useLLMStore} from '@/stores/llmStore';
import loadingImage from '@/assets/loading.gif';
import {SystemOption} from "@/types/system";



export default /*@__PURE__*/_defineComponent({
  __name: 'ChatForm',
  emits: ['messagesResponse'],
  setup(__props, { expose: __expose, emit: __emit }) {

const emit = __emit;

// 定义用户头像的URL
const avatarUrl = ref('');
const ai_avatarUrl = ref('');

// 国际化工具
const {t} = useI18n();
// 滚动条引用
const scrollbarRef = ref<InstanceType<typeof ElScrollbar>>();
// 用户存储
const userStore = useUserStore();
const llmStore = useLLMStore();
// 定义消息和UI状态的响应式属性
const messages = ref<ChatMessage[]>([]);
const newMessage = ref("");
const iconState = ref<boolean[]>([]);
const editState = ref<boolean[]>([]);
const editInputRefs = ref<(HTMLElement | null)[]>([]);
const inputWidths = ref<string[]>([]);
const tempEditTxt = ref('');
const sessionId = ref('');
const sessionName = ref('');
const creating = ref(false);


// 配置MarkdownIt用于高亮代码块
const md: MarkdownIt = new MarkdownIt({
  highlight: function (str, lang) {
    // 预处理：转换所有需要的字符到HTML实体
    const safeStr = str
        .replace(/&/g, '&#38;')
        .replace(/</g, '&#60;')
        .replace(/>/g, '&#62;')
        .replace(/"/g, '&#34;')
        .replace(/'/g, '&#39;')
        .replace(/\n/g, '&#10;');

    // 生成代码块的公共头部HTML
    const headerHtml = `<div class="code-header">
<span class="code-lang">${lang || 'Text'}</span>
<button class="copy-button" data-clipboard-text="${safeStr}">` + t('robot.copy-code') + `</button>
</div>`;

    let codeHtml;

    // 如果指定了语言且支持，则高亮代码
    if (lang && hljs.getLanguage(lang)) {
      try {
        codeHtml = hljs.highlight(str, {language: lang}).value;
      } catch (error) {
        console.error('代码高亮失败:', error);
        codeHtml = md.utils.escapeHtml(str);
      }
    } else {
      codeHtml = md.utils.escapeHtml(str);
    }

    // 返回完整的代码块HTML
    return `${headerHtml}<div class="code-card"><pre class="hljs"><code>${codeHtml}</code></pre></div>`;
  }
});

// 包装数学公式
const wrapMathEquations = (content: string): string => {
  if (!content) return content
  // 处理行内公式
  content = content.replace(/\\\((.*?)\\\)/g, (match) => `$${match.slice(2, -2).trim()}$`);
  // 处理块级公式
  content = content.replace(/\\\[[^\]]+\\]/g, (match) => `$$${match.slice(2, -2).trim()}$$`);

  return content;
};

// 设置内容高度
const contentHeight = autoHeight(160);

// 渲染Markdown内容的函数
const renderMarkdown = (content: string) => {
  if (!content) return content;
  const formattedContent = content.replace(/\n/g, '  \n');
  return md.render(formattedContent);
};

// 转义 HTML，避免 XSS 注入
const escapeHtml = (text: string): string => {
  return text
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
      .replace(/\n/g, '<br>');
};

// 处理内容的函数
const renderContent = (message: any, index: number) => {
  if (message.role === 'user') {
    return escapeHtml(message.content);
  } else {
    return renderMarkdown(wrapMathEquations(llmStore.streamAnswer[index]));
  }
};

// MarkdownIt插件：处理视频
function videoPlugin(md: MarkdownIt) {
  // 默认的图像处理规则
  const defaultRender = md.renderer.rules.image || function (tokens, idx, options, self) {
    return self.renderToken(tokens, idx, options);
  };

  // 自定义图像处理规则
  md.renderer.rules.image = function (tokens, idx, options, env, self) {
    const token = tokens[idx];
    const src = token.attrs ? token.attrs[token.attrIndex('src')][1] : '';

    // 如果是视频链接，则生成 <video> 标签
    if (src.endsWith('.mp4')) {
      return `
        <video controls width="100%" height="auto">
          <source src="${src}" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      `;
    }

    // 否则使用默认的渲染规则
    return defaultRender(tokens, idx, options, env, self);
  };
}

// 再次发送消息的函数
const reSendMsg = async (index: number) => {
  if (!index) return;
  try {
    creating.value = true
    messages.value.splice(index)
    llmStore.streamAnswer.splice(index - 1);
    llmStore.streamReasoning.splice(index - 1);
    messages.value.push({
      createdAt: Date.now(),
      session_id: "",
      session_name: "",
      content: "",
      sys_content: "",
      role: 'ai',
      topic: '',
      robot_content: '',
      relevance: false,
      no_prompt: 1,
      lang: userStore.loginUser?.native_language?.trim() ? userStore.loginUser.native_language : "中文",
      source: "",
      reasoning: "",
      reasoning_station: ""
    });
    await processLLMResponse(messages.value);
    creating.value = false
  } catch (error) {
    creating.value = false
    console.error('发送消息失败:', error);
  }
}

// 发送消息的函数
const sendMessage = async () => {
  // 清空编辑状态
  editState.value = editState.value.map(() => false);


  try {
    if (!userStore.loginUser) {
      return
    }
    creating.value = true
    messages.value.push({
      createdAt: Date.now(),
      session_id: sessionId.value == '' ? Date.now().toString() + 'U' + userStore.loginUser.user_id?.toString() : sessionId.value,
      session_name: sessionName.value == '' ? '新话题' + Date.now().toString().slice(-6) : sessionName.value,
      content: newMessage.value,
      sys_content: newMessage.value,
      role: 'user',
      topic: messages.value.length > 0 ? messages.value[messages.value.length - 2].topic : '',
      robot_content: messages.value.length > 0 ? messages.value[messages.value.length - 2].robot_content : '',
      lang: userStore.loginUser?.native_language?.trim() ? userStore.loginUser.native_language : "中文",
      relevance: false,
      no_prompt: 1,
      source: "",
      reasoning: "",
      reasoning_station: ""
    });

    newMessage.value = '';

    messages.value.push({
      createdAt: Date.now(),
      session_id: "",
      session_name: "",
      content: "",
      sys_content: "",
      role: 'ai',
      topic: '',
      robot_content: '',
      lang: userStore.loginUser?.native_language?.trim() ? userStore.loginUser.native_language : "中文",
      relevance: false,
      no_prompt: 1,
      source: "",
      reasoning: "",
      reasoning_station: ""
    });

    // 调用提取出来的函数来处理LLM响应
    await processLLMResponse(messages.value);

    creating.value = false

  } catch (error) {
    creating.value = false
    console.error('发送消息失败:', error);
  }
};

const stopMessage = async () => {
  llmStore.abortController.abort()
  creating.value = false
}
const processLLMResponse = async (msgs: ChatMessage[]) => {
  const lastMsgIndex = msgs.length - 1;

  try {
    await llmStore.sendToLLMStreaming(msgs.slice(0, -1));


    msgs[lastMsgIndex].content = llmStore.streamAnswer[lastMsgIndex];
    msgs[lastMsgIndex].reasoning = llmStore.streamReasoning[lastMsgIndex];
    msgs[lastMsgIndex - 1].sys_content = llmStore.chat_msg.sys_content;
    msgs[lastMsgIndex - 1].relevance = llmStore.chat_msg.relevance;
    msgs[lastMsgIndex - 1].robot_content = llmStore.chat_msg.robot_content;
    msgs[lastMsgIndex - 1].topic = llmStore.chat_msg.topic;

    // 通过 emit 回传 megs 对象给父组件
    emit('messagesResponse', msgs);

    // 保存聊天记录
    await llmStore.saveNowChat(msgs.slice(-2));

  } catch (error: any) {
    let detail = "null"
    if (error.response) {
      detail = error.response.data.detail;
    }
    switch (detail) {
      case 'InvalidInput':
        showNotification('error', '发送消息', "参数不正确");
        break;
      case 'BdAPIToken':
        showNotification('error', '发送消息', "百度合规检查接口错误");
        break;
      case 'SteamError':
        llmStore.streamAnswer[lastMsgIndex] = t("message.llm-error-info");
        msgs[lastMsgIndex].content = llmStore.streamAnswer[lastMsgIndex];
        msgs[lastMsgIndex - 1].sys_content = llmStore.chat_msg.sys_content;
        msgs[lastMsgIndex - 1].relevance = llmStore.chat_msg.relevance;
        msgs[lastMsgIndex - 1].robot_content = llmStore.chat_msg.robot_content;
        msgs[lastMsgIndex - 1].topic = llmStore.chat_msg.topic;
        break;
      case 'null':
        break;
      default:
        showNotification('error', '未知状态', error.response.data.detail);
        break;
    }
    return null;
  }

};


// 滚动到聊天窗口底部的函数
const scrollToBottom = () => {
  nextTick(() => {
    if (scrollbarRef.value && scrollbarRef.value.wrapRef) {
      scrollbarRef.value.setScrollTop(scrollbarRef.value.wrapRef.scrollHeight);
    }
  });
};


// 暴露方法给父组件调用
const setMessages = (chatHistory: ChatMessage[]) => {
  // 清空现有的数组内容，但保持引用
  messages.value.splice(0, messages.value.length, ...chatHistory);
  scrollToBottom()
};
const setSession = (id: string, name: string) => {
  sessionId.value = id;
  sessionName.value = name;
}
// 暴露 messages 变量直接供父组件操作
__expose({setMessages, setSession});

// 处理按键事件，Ctrl + Enter 发送消息
const handleKeyUp = (event: KeyboardEvent) => {
  if (event.ctrlKey && event.key === 'Enter' && newMessage.value != "" && !creating.value) {
    sendMessage();
  }
};

// 复制消息内容的函数
const copyMessage = (index: number) => {
  try {
    const messageElement = document.querySelector(`.message-row:nth-child(${index + 1}) .message`) as HTMLElement;
    const messageText = messageElement?.innerText || messageElement?.textContent || '';
    navigator.clipboard.writeText(messageText).then(() => {
      iconState.value[index] = true;
      setTimeout(() => {
        iconState.value[index] = false;
      }, 1000);
    }).catch(err => {
      console.error('复制失败:', err);
    });
  } catch (error) {
    console.error('复制消息失败:', error);
  }
};

// 设置编辑输入框引用
const setEditInputRef = (el: HTMLTextAreaElement | null, index: number) => {
  editInputRefs.value[index] = el;
};

// 编辑消息的函数
const editMessage = (index: number) => {
  try {
    const messageElement = document.querySelector(`.message-col`) as HTMLElement;
    inputWidths.value[index] = messageElement.offsetWidth * 0.66 + 'px';
    tempEditTxt.value = messages.value[index].content || '';
    editState.value = editState.value.map(() => false);
    editState.value[index] = true;
    nextTick(() => {
      const inputElement = editInputRefs.value[index];
      if (inputElement) {
        inputElement.focus();
      }
    });
  } catch (error) {
    console.error('编辑消息失败:', error);
  }
};

// 取消编辑消息
const cancelEdit = (index: number) => {
  editState.value[index] = false;
};

// 提交编辑后的消息
const submitEdit = (index: number) => {
  try {
    messages.value[index].content = tempEditTxt.value;
    messages.value[index].sys_content = tempEditTxt.value;
    editState.value[index] = false;
    reSendMsg(index + 1);
  } catch (error) {
    console.error('提交编辑消息失败:', error);
  }
};


// 监听消息变化，自动滚动到底部
watch([messages, llmStore.streamAnswer, llmStore.streamReasoning], () => {
  scrollToBottom();
  // 监听 llmStore.streamAnswer[index] 的变化

  // 如果流内容为空或者消息内容为空，则直接返回
  if (llmStore.streamAnswer.length === 0 || messages.value.length === 0) return;

  // 获取最后一个索引
  const index = llmStore.streamAnswer.length - 1;

  // 确保 messages.value[index] 已初始化
  if (!messages.value[index]) {
    messages.value[index] = {};
  }

  // 根据 llmStore.streamAnswer 或 llmStore.streamReasoning 设置 reasoning_station
  messages.value[index].reasoning_station = llmStore.streamAnswer[index] ? t('chat.reason') : t('chat.reasoning');

});


// 组件挂载时初始化
onMounted(() => {
  try {
    new Clipboard('.copy-button');
    if (!llmStore.streamReasoning) {
      llmStore.streamReasoning = [];
    }
    messages.value.length = 0
    llmStore.streamAnswer.length = 0
    llmStore.streamReasoning.length = 0
    if (userStore.loginUser) {
      avatarUrl.value = userStore.loginUser.avatar_url || ''
    }
    ai_avatarUrl.value = SystemOption['ai_avatar']
    md.use(videoPlugin)
    md.use(markdownItMathjax)
  } catch (error) {
    console.error('初始化失败:', error);
  }
});

return (_ctx: any,_cache: any) => {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(ElScrollbar), {
      ref_key: "scrollbarRef",
      ref: scrollbarRef,
      class: "chat-content",
      height: _unref(contentHeight)
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(messages.value, (message, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: message ? message.createdAt : Date.now()
            }, [
              message
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _withDirectives(_createElementVNode("div", { class: "chat-time" }, _toDisplayString(_unref(formatDate)(_unref(t),message.createdAt)), 513), [
                      [_vShow, 
                  message.createdAt &&
                  message.role === 'user' &&
                  (index === 0 || (messages.value[index - 1] && (new Date(message.createdAt).getTime() - new Date(messages.value[index - 1].createdAt!).getTime()) > 300000))
                ]
                    ]),
                    _createElementVNode("div", {
                      class: _normalizeClass(['message-row', message.role])
                    }, [
                      (message.role !== 'user')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createVNode(_component_el_avatar, {
                              src: ai_avatarUrl.value,
                              class: "avatar-display-left"
                            }, null, 8, ["src"])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", {
                          class: _normalizeClass(['message', message.role]),
                          ref_for: true,
                          ref: 'message-' + index
                        }, [
                          (message.role !== 'user' && _unref(llmStore).streamReasoning[index])
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createVNode(_component_el_collapse, {
                                  accordion: "",
                                  class: "collage"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_collapse_item, {
                                      title: message.reasoning_station,
                                      name: "1",
                                      class: "collage-item"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", {
                                          innerHTML: escapeHtml(_unref(llmStore).streamReasoning[index]),
                                          class: "item-text"
                                        }, null, 8, _hoisted_7)
                                      ]),
                                      _: 2
                                    }, 1032, ["title"])
                                  ]),
                                  _: 2
                                }, 1024)
                              ]))
                            : _createCommentVNode("", true),
                          (!editState.value[index])
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: "markdown-body",
                                innerHTML: renderContent(message, index)
                              }, null, 8, _hoisted_8))
                            : _createCommentVNode("", true),
                          (message.role !== 'user' && _unref(llmStore).streamAnswer[index]=='')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createVNode(_component_el_image, {
                                  src: _unref(loadingImage),
                                  style: {"height":"16px","width":"16px","margin-top":"5px"}
                                }, null, 8, ["src"])
                              ]))
                            : _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_10, [
                                _createVNode(_component_el_input, {
                                  modelValue: tempEditTxt.value,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((tempEditTxt).value = $event)),
                                  resize: "none",
                                  type: "textarea",
                                  autosize: { minRows: 1, maxRows: 20 },
                                  class: "edit-body",
                                  style: _normalizeStyle({ width: inputWidths.value[index] }),
                                  ref_for: true,
                                  ref: (el) => setEditInputRef(el, index)
                                }, null, 8, ["modelValue", "style"]),
                                _createVNode(_component_el_button, {
                                  round: "",
                                  type: "primary",
                                  onClick: ($event: any) => (submitEdit(index)),
                                  class: "edit-button"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('message.send')), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]),
                                _createVNode(_component_el_button, {
                                  round: "",
                                  plain: "",
                                  type: "info",
                                  onClick: ($event: any) => (cancelEdit(index)),
                                  class: "edit-button"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('message.cancel')), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ], 512)), [
                                [_vShow, editState.value[index]]
                              ])
                        ], 2),
                        (message.role !== 'user')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                              _createVNode(_component_el_tooltip, {
                                content: _ctx.$t('message.copy'),
                                placement: "bottom"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_button, {
                                    circle: "",
                                    size: "small",
                                    class: "small-button",
                                    onClick: ($event: any) => (copyMessage(index))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_font_awesome_icon, {
                                        icon: iconState.value[index] ? 'check' : 'copy'
                                      }, null, 8, ["icon"])
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]),
                                _: 2
                              }, 1032, ["content"]),
                              _createVNode(_component_el_tooltip, {
                                content: _ctx.$t('chat.resubmit'),
                                placement: "bottom"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_button, {
                                    circle: "",
                                    size: "small",
                                    class: "small-button",
                                    onClick: ($event: any) => (reSendMsg(index))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_font_awesome_icon, { icon: "arrows-rotate" })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]),
                                _: 2
                              }, 1032, ["content"])
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              _createVNode(_component_el_tooltip, {
                                content: _ctx.$t('message.modify'),
                                placement: "bottom"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_button, {
                                    circle: "",
                                    size: "small",
                                    class: "small-button",
                                    onClick: ($event: any) => (editMessage(index))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_font_awesome_icon, { icon: "pen" })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]),
                                _: 2
                              }, 1032, ["content"])
                            ]))
                      ]),
                      (message.role === 'user')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createVNode(_component_el_avatar, {
                              src: avatarUrl.value,
                              class: "avatar-display-right"
                            }, null, 8, ["src"])
                          ]))
                        : _createCommentVNode("", true)
                    ], 2)
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["height"]),
    _createElementVNode("div", _hoisted_14, [
      _createVNode(_component_el_button, {
        type: "info",
        circle: "",
        size: "large",
        class: "attached-button"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, {
            icon: "paperclip",
            class: "icon-enabled"
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_input, {
        modelValue: newMessage.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((newMessage).value = $event)),
        type: "textarea",
        placeholder: _ctx.$t('robot.input-tip'),
        onKeyup: handleKeyUp,
        class: "input-area",
        autosize: { minRows: 1, maxRows: 20 },
        resize: "none"
      }, null, 8, ["modelValue", "placeholder"]),
      _withDirectives(_createVNode(_component_el_button, {
        type: "info",
        onClick: sendMessage,
        circle: "",
        size: "large",
        disabled: !newMessage.value.trim(),
        class: "send-button"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, {
            icon: "circle-arrow-up",
            class: _normalizeClass({ 'icon-enabled': newMessage.value.trim(), 'icon-disabled': !newMessage.value.trim() })
          }, null, 8, ["class"])
        ]),
        _: 1
      }, 8, ["disabled"]), [
        [_vShow, !creating.value]
      ]),
      _withDirectives(_createVNode(_component_el_button, {
        type: "info",
        onClick: stopMessage,
        circle: "",
        size: "large",
        class: "send-button"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, {
            icon: "circle-stop",
            class: "icon-enabled"
          })
        ]),
        _: 1
      }, 512), [
        [_vShow, creating.value]
      ])
    ])
  ]))
}
}

})