import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "dialog-header" }
const _hoisted_2 = { style: {"width":"100%"} }

import {computed, ref, watch} from "vue" // 导入 Vue 的核心功能
import {useI18n} from "vue-i18n" // 导入国际化插件
import {FormInstance} from 'element-plus' // 导入 Element Plus 的表单和上传组件
import {Topic} from "@/types/Topic" // 导入 User 类型定义
import {useTopicStore} from "@/stores/topicStore" // 导入用户存储
import {showNotification} from "@/services/tools"

// 使用用户存储和国际化

export default /*@__PURE__*/_defineComponent({
  __name: 'NewTopic',
  props: { // 定义 props
  isShow: {
    type: Boolean,
    default: false
  },
  topic_info: {
    type: Object as () => Topic,
    default: null
  }
},
  emits: ["refreshList", "update:isShow"],
  setup(__props, { emit: __emit }) {

const topicStore = useTopicStore() // 使用用户存储
const {t} = useI18n() // 使用国际化

// 响应式状态变量
const newTopicMessage = ref<string>('') // 新模板信息提示
const topicForm = ref<FormInstance>() // 模板表单实例
const topicDialog = ref(false) // 创建对话框显示状态
const topic = ref<Topic>({} as Topic) // 创建用户对象
const TopicData = ref({ // 模板数据对象
  name: '',
  description: ''
})
// 初始化模板数据
const defaultTopicData = {
  name: '',
  description: ''
}

// 定义组件的 props 和 emits
const props = __props
const emit = __emit // 定义 emits

// 监听 props.isShow 的变化并初始化相关状态
watch(() => props.isShow, (val) => {
  topicDialog.value = val // 设置对话框显示状态
  newTopicMessage.value = "" // 清空提示信息
}, {immediate: true}) // 立即执行

// 监听 props.topic_info 的变化并初始化相关状态
watch(() => props.topic_info, (newVal) => {
  if (newVal) {
    topic.value = newVal;
    ini_form(topic.value)
  } else {
    topic.value = {} as Topic;
  }
}, {immediate: true}) // 立即执行

// 表单验证规则
const topicRules = computed(() => ({
  name: [{required: true, max: 100, message: t('topic.name-check'), trigger: 'blur'}], // 名称验证规则
  description: [{required: true, max: 300, message: t('topic.description-check'), trigger: 'blur'}] // 描述验证规则
}))

// 实用函数：刷新父列表
const refreshFatherList = () => {
  try {
    emit("refreshList") // 触发 refreshList 事件
  } catch (error) {
    console.error('Error refreshing father list:', error) // 打印错误信息
  }
}

// 关闭对话框并重置表单
const closeDialog = async () => {
  try {
    topicDialog.value = false // 隐藏对话框
    newTopicMessage.value = "" // 清空提示信息
    refreshFatherList()
    emit('update:isShow', false) // 触发 update:isShow 事件
  } catch (error) {
    console.error('Error closing dialog:', error) // 打印错误信息
  }
}

// 上传用户信息
const save_topic = async (formEl: FormInstance | undefined) => {
  if (!formEl) return


  try {
    topic.value.name = TopicData.value.name // 设置模板名称
    topic.value.description = TopicData.value.description // 设置模板描述

    newTopicMessage.value = "" // 清空提示信息
    try {
      const valid = await formEl.validate() // 验证表单

      if (valid) {
        await topicStore.createOrUpdateTopic(topic.value)
        TopicData.value = {...defaultTopicData} //清空TopicData数据
        refreshFatherList() // 刷新父组件列表
        await closeDialog() // 关闭对话框
        showNotification('success', t('message.success'), t('topic.new-success-info')) // 显示成功通知
      }
    } catch (error: any) {
      newTopicMessage.value = "" // 清除错误提示
      let detail = "null"
      if (error.response) {
        detail = error.response.data.detail;
      }
      switch (detail) {
        case 'InvalidInput':
          newTopicMessage.value = "传入参数无效";
          break;
        case 'TopicHasExisted':
          newTopicMessage.value = t('topic.name-exist') // 显示话题名已存在提示
          break;
        case 'null':
          break;
        default:
          console.log(error.response.data)
          showNotification('error', '未知错误', t('topic.unknown-error'));
          break;
      }
    }

  } catch (error) {
    console.error('Failed to validate form:', error) // 打印错误信息
    newTopicMessage.value = t('topic.unknown-error') // 显示未知错误提示
  }
}

const ini_form = async (robot: Topic) => {
  if (robot) {
    TopicData.value.name = robot.name || '' // 设置用户头像 URL
    TopicData.value.description = robot.description || ''// 设置用户昵称
  }
}

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    visible: topicDialog.value,
    width: "1100px",
    "align-center": "",
    center: "",
    draggable: "",
    "close-on-click-modal": false,
    "show-close": false
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('topic.new-title')), 1),
        _createVNode(_component_el_button, {
          type: "danger",
          onClick: closeDialog,
          circle: "",
          size: "small"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, { icon: "xmark" })
          ]),
          _: 1
        })
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (save_topic(topicForm.value)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "check",
              class: "icon-text-button"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('message.save')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "danger",
          onClick: closeDialog,
          plain: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "xmark",
              class: "icon-text-button"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('message.cancel')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref_key: "topicForm",
        ref: topicForm,
        model: TopicData.value,
        rules: topicRules.value,
        "label-width": "80px",
        "label-position": 'right'
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              (newTopicMessage.value)
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 0,
                    type: "warning",
                    description: newTopicMessage.value,
                    closable: false,
                    "show-icon": "",
                    effect: 'light',
                    class: "reg-alert"
                  }, null, 8, ["description"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('topic.name'),
                  prop: "name"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: TopicData.value.name,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((TopicData.value.name) = $event)),
                      placeholder: _ctx.$t('topic.name-tip'),
                      clearable: "",
                      "show-word-limit": "",
                      maxlength: "100",
                      style: {"width":"100%"}
                    }, null, 8, ["modelValue", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_form_item, {
                  label: _ctx.$t('topic.description'),
                  prop: "description"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: TopicData.value.description,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((TopicData.value.description) = $event)),
                      placeholder: _ctx.$t('topic.description-tip'),
                      clearable: "",
                      type: "textarea",
                      rows: 4,
                      "show-word-limit": "",
                      maxlength: "300"
                    }, null, 8, ["modelValue", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})