import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "dialog-header" }
const _hoisted_2 = { class: "top-row" }
const _hoisted_3 = { style: {"display":"flex","flex-direction":"row","justify-content":"center","align-content":"center"} }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { style: {"display":"flex","flex-direction":"row","gap":"10px"} }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { style: {"display":"flex","flex-direction":"row","gap":"10px"} }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { style: {"display":"flex","flex-direction":"row","gap":"15px"} }
const _hoisted_13 = {
  key: 0,
  class: "permission_tag"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { class: "overlay-text" }

import {onMounted, ref} from 'vue';
import {autoHeight, showNotification} from '@/services/tools';
import treeIcon from '@/components/treeIcon.vue'; // 引入你的自定义图标组件
import {TreeNode} from "@/types/system";
import {useFileStore} from "@/stores/fileStore";
import {useI18n} from "vue-i18n"
import {get_tree} from "@/services/systemService"; // 导入国际化插件

interface Props {
  // 传递的 treeName 必须是字符串
  file_ids: number[]
  treeName: string
}

// 使用 defineProps 接收父组件传递的 props

export default /*@__PURE__*/_defineComponent({
  __name: 'selectTreeList',
  props: {
    file_ids: {},
    treeName: {}
  },
  emits: ["refreshList", "update:isShow"],
  setup(__props: any, { emit: __emit }) {

const treeData = ref<TreeNode[]>([]);
const selectTreeNodeDialog = ref<boolean>(false)
const selectedNode = ref<any>(null); // 存储当前选中的节点
const fileStore = useFileStore();  // 使用用户存储
const treeRef = ref();
const errorMessage = ref<string>('')
const contentHeight = autoHeight(300);

// 使用国际化功能
const {t} = useI18n();

// 定义 trans
const props = __props;
const emit = __emit // 定义 emits

const tree_node = {
  value: 'id',
  children: 'children',
  label: 'label'
};

const handleClickNode = (data: any, node: any) => {
  selectedNode.value = node;  // 将选中的节点存储
  errorMessage.value =""
}

const refreshTree = async () => {
  errorMessage.value = ""
  treeRef.value = null
  treeData.value = await get_tree(props.treeName)
  selectedNode.value = null
}


const refreshFatherList = () => {
  try {
    emit("refreshList") // 触发 refreshList 事件
  } catch (error) {
    console.error('Error refreshing father list:', error) // 打印错误信息
  }
}
// 关闭对话框并重置表单
const closeDialog = async () => {
  try {
    selectTreeNodeDialog.value = false // 隐藏对话框
    errorMessage.value = ""
    await refreshTree()
    refreshFatherList()
    emit('update:isShow', false) // 触发 update:isShow 事件
  } catch (error) {
    console.error('Error closing dialog:', error) // 打印错误信息
  }
}

const move_file = async () => {
  try {
    if (selectedNode.value) {
      errorMessage.value =""
      const response = await fileStore.moveFiles(selectedNode.value.data.id, props.file_ids)
      const count = response.message
      showNotification("success", t("knowledge.move-title"), t("knowledge.move-file-success", {count}))
      await closeDialog()
    } else {
      errorMessage.value = t('message.last-one')
    }
  } catch (error: any) {
    errorMessage.value = ""
    let detail = "null"
    if (error.response) {
      detail = error.response.data.detail;
    }
    switch (detail) {
      case 'InvalidInput':
        errorMessage.value = t('message.last-one')
        break;
      case 'null':
        break;
      default:
        showNotification('error', '未知状态', error.response.data.detail);
        break;
    }
  }
}

onMounted(async () => {
  try {
    treeData.value = await get_tree(props.treeName)

  } catch (error) {
    console.error('Error fetching tree data:', error);
  }
});


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    visible: selectTreeNodeDialog.value,
    width: "1100px",
    "align-center": "",
    center: "",
    draggable: "",
    "close-on-click-modal": false,
    "show-close": false
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('knowledge.move-title')), 1),
        _createVNode(_component_el_button, {
          type: "danger",
          onClick: closeDialog,
          circle: "",
          size: "small"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, { icon: "xmark" })
          ]),
          _: 1
        })
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: move_file
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "check",
              class: "icon-text-button"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('message.move')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "danger",
          onClick: closeDialog,
          plain: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "xmark",
              class: "icon-text-button"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('message.cancel')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      (errorMessage.value)
        ? (_openBlock(), _createBlock(_component_el_alert, {
            key: 0,
            type: "warning",
            description: errorMessage.value,
            closable: false,
            "show-icon": "",
            effect: 'light',
            class: "error_alert"
          }, null, 8, ["description"]))
        : _createCommentVNode("", true),
      _createVNode(_component_el_scrollbar, {
        class: "tree-content",
        height: _unref(contentHeight)
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tree, {
            ref_key: "treeRef",
            ref: treeRef,
            data: treeData.value,
            props: tree_node,
            "node-key": "id",
            "highlight-current": "",
            "default-expanded-keys": [-1],
            accordion: "",
            icon: treeIcon,
            onNodeClick: handleClickNode,
            class: "tree-top"
          }, {
            default: _withCtx(({ data }) => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  (data.file_count!=0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_el_badge, {
                          value: data.file_count,
                          max: 999999,
                          offset: [15, 9],
                          type: "warning"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_5, [
                              (data.children.length === 0 && data.parent_id != -1)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                    _createVNode(_component_font_awesome_icon, {
                                      icon: "circle",
                                      size: "xs"
                                    })
                                  ]))
                                : _createCommentVNode("", true),
                              (data.parent_id === -1)
                                ? (_openBlock(), _createBlock(_component_el_text, {
                                    key: 1,
                                    type: "primary",
                                    tag: "b"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(data.label), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(data.label), 1))
                            ])
                          ]),
                          _: 2
                        }, 1032, ["value"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          (data.children.length === 0 && data.parent_id != -1)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                _createVNode(_component_font_awesome_icon, {
                                  icon: "circle",
                                  size: "xs"
                                })
                              ]))
                            : _createCommentVNode("", true),
                          (data.parent_id === -1)
                            ? (_openBlock(), _createBlock(_component_el_text, {
                                key: 1,
                                type: "primary",
                                tag: "b"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(data.label), 1)
                                ]),
                                _: 2
                              }, 1024))
                            : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(data.label), 1))
                        ])
                      ]))
                ]),
                _createElementVNode("div", _hoisted_12, [
                  (data.label_names)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.label_names.slice(0, 6), (item, index) => {
                          return (_openBlock(), _createElementBlock("div", { key: index }, [
                            (_openBlock(), _createBlock(_component_el_tag, {
                              key: index,
                              type: "success",
                              style: {"margin-right":"3px"}
                            }, {
                              default: _withCtx(() => [
                                (index<5)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(item), 1))
                                  : (index==5)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, "..."))
                                    : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024))
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _: 1
          }, 8, ["data"]),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_el_text, {
              size: "large",
              tag: "b",
              type: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('knowledge.title')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              type: "primary",
              size: "small",
              onClick: refreshTree,
              circle: "",
              style: {"margin-top":"8px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, { icon: "arrow-rotate-right" })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["height"])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})