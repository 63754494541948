import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loading-form" }
const _hoisted_2 = { style: {"display":"flex","justify-content":"center","margin-bottom":"20px"} }
const _hoisted_3 = { class: "form-label" }
const _hoisted_4 = { class: "form-label" }
const _hoisted_5 = { class: "container" }
const _hoisted_6 = { class: "left-side" }
const _hoisted_7 = { class: "icon-text-button-up" }
const _hoisted_8 = { class: "right-side" }
const _hoisted_9 = { style: {"padding":"0","margin":"0"} }

import {computed, reactive, ref} from 'vue';
import {FormInstance} from 'element-plus';
import {useRouter} from 'vue-router';
import {useUserStore} from '@/stores/userStore';
import {useI18n} from "vue-i18n";
import {User} from '@/types/User';
import {showNotification} from "@/services/tools";
import {validateConfirmPassword, validatePassword} from "@/services/validators"
import {SystemOption} from "@/types/system";
// 控制注册和忘记密码对话框的显示

export default /*@__PURE__*/_defineComponent({
  __name: 'LoginForm-up',
  setup(__props) {

const registerDialog = ref(false); // 注册对话框的显示状态
const forgotDialog = ref(false); // 忘记密码对话框的显示状态

// 错误消息变量
const errorMessage = ref<string>(''); // 登录错误消息
const regMessage = ref<string>(''); // 注册错误消息
const forgotMessage = ref<string>(''); // 忘记密码错误消息
const forgotCompleted = ref(false); // 忘记密码操作是否完成
const login_state = ref(false);

// 使用国际化功能
const {t} = useI18n();

// 表单实例引用
const loginForm = ref<FormInstance>(); // 登录表单实例
const registerForm = ref<FormInstance>(); // 注册表单实例
const forgotForm = ref<FormInstance>(); // 忘记密码表单实例

// Vue Router实例
const router = useRouter();

// 用户存储实例
const userStore = useUserStore();

// 登录表单数据
const loginFormData = reactive({
  login_name: '', // 登录名
  pass: '' // 密码
});

// 注册表单数据
const regData = reactive({
  loginName: '', // 登录名
  password: '', // 密码
  confirmPassword: '', // 确认密码
  nickName: '' // 昵称
});

// 忘记密码表单数据
const forgotData = reactive({
  loginName: '', // 登录名
  email: '' // 邮箱
});

// 定义登录表单验证规则
const loginRules = computed(() => ({
  login_name: [{required: true, message: t('user.login-name-check'), trigger: 'blur'}],
  pass: [{required: true, min: 3, max: 50, message: t('user.password-check'), trigger: 'blur'}]
}));

// 定义注册表单验证规则
const regRules = computed(() => ({
  nickName: [{required: false, max: 30, message: t('user.nickname-check'), trigger: 'blur'}],
  loginName: [{required: true, max: 30, message: t('user.reg-login-name-check'), trigger: 'blur'}],
  password: [
    {
      required: true,
      trigger: 'blur',
      validator: (rule: any, value: any, callback: any) => validatePassword(t, rule, value, callback, true) // 密码验证规则
    }
  ],
  confirmPassword: [
    {
      required: true,
      trigger: 'blur',
      validator: (rule: any, value: any, callback: any) => validateConfirmPassword(t, rule, value, callback, regData.password) // 确认密码验证规则
    }
  ],
}));

// 定义忘记密码表单验证规则
const forgotRules = computed(() => ({
  loginName: [{required: true, max: 30, message: t('user.reg-login-name-check'), trigger: 'blur'}],
  email: [
    {required: true, message: t('user.email-check'), trigger: 'blur'},
    {type: 'email', message: t('user.email-invalid'), trigger: 'blur'}
  ]
}));

/**
 * 登录逻辑处理函数
 * @param {FormInstance | undefined} formEl - 登录表单实例
 */
const login = async (formEl: FormInstance | undefined) => {
  if (!formEl) return; // 如果表单实例不存在，则返回
  // 显示加载指示器


  try {
    login_state.value = true;
    // 验证表单数据
    const valid = await formEl.validate();
    if (valid) {
      // 调用用户存储中的登录方法
      const response = await userStore.login(loginFormData.login_name, loginFormData.pass);
      if (response === '0') {
        errorMessage.value = "";
        await router.push('/robot'); // 跳转到首页
      }
    }
  } catch (error: any) {
    let detail = ""
    errorMessage.value = "";
    if (error.response) {
      detail = error.response.data.detail;
    }
    switch (detail) {
      case 'UserNotFound':
        errorMessage.value = t('user.login-name-error'); // 登录名错误
        break;
      case 'Invalid Password':
        errorMessage.value = t('user.password-error'); // 密码错误
        break;
    }
  } finally {
    login_state.value = false;
  }
};

/**
 * 注册逻辑处理函数
 * @param {FormInstance | undefined} formEl - 注册表单实例
 */
const register = async (formEl: FormInstance | undefined) => {
  if (!formEl) return; // 如果表单实例不存在，则返回

  // 定义用户数据
  const user = reactive({
    name: regData.nickName,
    login_name: regData.loginName,
    password: regData.password
  } as User);

  try {
    regMessage.value = "";
    const valid = await formEl.validate();
    if (valid) {
      // 调用用户存储中的注册方法
      const response = await userStore.register(user, null);

      if (response === '0') {
        registerDialog.value = false; // 关闭注册对话框
        loginFormData.login_name = userStore.user.login_name as string; // 设置登录名为注册的用户名
        showNotification("success", t('user.register-success'), t('user.register-success-info')); // 显示注册成功的通知
      }

    }
  } catch (error: any) {
    let detail = "null"
    regMessage.value = "";
    if (error.response) {
      detail = error.response.data.detail;
    }
    switch (detail) {
      case 'LoginNameExists':
        regMessage.value = t('user.register-name-exist'); // 注册名已存在
        break;
      case 'UserNotFound':
        regMessage.value = t('user.register-name-exist'); // 注册名已存在
        break;
      case 'CreateSchemaFailed':
        regMessage.value = t('user.create-schema-error'); // 未知错误
        break;
      case 'null':
        break;
      default:
        showNotification('error', '未知状态', error.response.data.detail);
        break;
    }
  }
};

/**
 * 忘记密码逻辑处理函数
 * @param {FormInstance | undefined} formEl - 忘记密码表单实例
 */
const forgotPassword = async (formEl: FormInstance | undefined) => {
  if (!formEl) return; // 如果表单实例不存在，则返回

  try {
    forgotMessage.value = "";
    const valid = await formEl.validate();
    if (valid) {
      // 调用用户存储中的忘记密码方法
      const result = await userStore.forgotPassword(forgotData.loginName, forgotData.email);

      // 根据返回的结果处理不同的情况
      switch (result) {
        case '0':
          forgotCompleted.value = true; // 忘记密码操作完成
          forgotMessage.value = t('user.forgot-success'); // 显示成功消息
          break;
        case "1":
          forgotMessage.value = t('user.unknown-error'); // 未知错误
          break;
        default:
          forgotMessage.value = t('user.unknown-error'); // 未知错误
          console.error(`后台返回了一个未知状态： ${forgotMessage.value}`);
          break;
      }
    }
  } catch (error) {
    console.error('表单验证失败:', error); // 捕获表单验证失败的错误
  }
};

/**
 * 重置表单
 * @param {FormInstance | undefined} formEl - 表单实例
 */
const resetForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return; // 如果表单实例不存在，则返回
  errorMessage.value = "";
  formEl.resetFields(); // 重置表单字段
};

/**
 * 打开注册对话框
 */
const openRegisterDialog = () => {
  resetForm(loginForm.value); // 重置登录表单
  resetForm(registerForm.value); // 重置注册表单
  regMessage.value = "";
  registerDialog.value = true; // 显示注册对话框
};

/**
 * 打开忘记密码对话框
 */
const openForgotDialog = () => {
  resetForm(loginForm.value); // 重置登录表单
  resetForm(registerForm.value); // 重置注册表单
  resetForm(forgotForm.value); // 重置忘记密码表单
  forgotMessage.value = "";
  forgotCompleted.value = false;
  forgotDialog.value = true; // 显示忘记密码对话框
};

return (_ctx: any,_cache: any) => {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref_key: "loginForm",
      ref: loginForm,
      model: loginFormData,
      rules: loginRules.value,
      "label-width": "auto",
      "label-position": 'top',
      class: "login-form"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_avatar, {
            size: 180,
            src: _unref(SystemOption)['ai_avatar']
          }, null, 8, ["src"])
        ]),
        _createVNode(_component_el_form_item, { prop: "login_name" }, {
          label: _withCtx(() => [
            _createElementVNode("span", _hoisted_3, [
              _createVNode(_component_font_awesome_icon, { icon: "user" }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('user.login-name')), 1)
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: loginFormData.login_name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((loginFormData.login_name) = $event)),
              placeholder: _ctx.$t('user.name-tip'),
              autocomplete: "on",
              clearable: ""
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { prop: "pass" }, {
          label: _withCtx(() => [
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_font_awesome_icon, { icon: "lock" }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('user.password')), 1)
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              type: "password",
              modelValue: loginFormData.pass,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((loginFormData.pass) = $event)),
              placeholder:  _ctx.$t('user.password-tip'),
              autocomplete: "off",
              "show-password": "",
              clearable: ""
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", null, [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (login(loginForm.value))),
                disabled: login_state.value,
                style: {height:'80px',width:'140px'}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "right-to-bracket",
                      size: "2xl",
                      fade: "",
                      style: {"margin-bottom":"6px"}
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('user.login')), 1)
                  ])
                ]),
                _: 1
              }, 8, ["disabled"])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", null, [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (resetForm(loginForm.value))),
                circle: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, { icon: "arrow-rotate-right" })
                ]),
                _: 1
              }),
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: openForgotDialog,
                style: {width:'100px'},
                round: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('user.forget')), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: openRegisterDialog,
                style: {width:'145px'},
                round: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "user-plus",
                    class: "icon-text-button"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('user.register')), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _createVNode(_component_el_form_item, { style: {"padding":"0","margin":"0"} }, {
          default: _withCtx(() => [
            _createVNode(_component_el_alert, {
              type: "warning",
              description: errorMessage.value,
              closable: false,
              "show-icon": "",
              effect: 'light',
              class: _normalizeClass({'el-alert-visible': errorMessage.value})
            }, null, 8, ["description", "class"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createVNode(_component_el_dialog, {
      modelValue: registerDialog.value,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((registerDialog).value = $event)),
      title: _ctx.$t('user.register-title'),
      width: "600",
      "align-center": "",
      center: "",
      draggable: "",
      "close-on-click-modal": false
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (register(registerForm.value)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: "check",
                class: "icon-text-button"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('message.confirm')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "danger",
            onClick: _cache[9] || (_cache[9] = ($event: any) => (registerDialog.value = false)),
            plain: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: "xmark",
                class: "icon-text-button"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('message.cancel')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref_key: "registerForm",
          ref: registerForm,
          model: regData,
          rules: regRules.value,
          "label-width": "auto",
          "label-position": 'right',
          class: "reg-form"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('user.nickname'),
              prop: "nickName"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: regData.nickName,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((regData.nickName) = $event)),
                  placeholder: _ctx.$t('user.nickname-tip'),
                  clearable: ""
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('user.login-name'),
              prop: "loginName"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: regData.loginName,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((regData.loginName) = $event)),
                  placeholder: _ctx.$t('user.login-name-tip' ),
                  clearable: ""
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('user.password'),
              prop: "password"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "password",
                  modelValue: regData.password,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((regData.password) = $event)),
                  placeholder:  _ctx.$t('user.input-password-tip'),
                  autocomplete: "off",
                  "show-password": "",
                  clearable: ""
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('user.confirm-password'),
              prop: "confirmPassword"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "password",
                  modelValue: regData.confirmPassword,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((regData.confirmPassword) = $event)),
                  placeholder:  _ctx.$t('user.confirm-password-tip'),
                  autocomplete: "off",
                  "show-password": "",
                  clearable: ""
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, { style: {"padding":"0","margin":"0"} }, {
              default: _withCtx(() => [
                _createVNode(_component_el_alert, {
                  type: "warning",
                  description: regMessage.value,
                  closable: false,
                  "show-icon": "",
                  effect: 'light',
                  class: _normalizeClass(['reg-alert', {'el-alert-visible': regMessage.value}])
                }, null, 8, ["description", "class"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(_component_el_dialog, {
      modelValue: forgotDialog.value,
      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((forgotDialog).value = $event)),
      title: _ctx.$t('user.forgot-title'),
      width: "600",
      "align-center": "",
      center: "",
      draggable: "",
      "close-on-click-modal": false
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          (!forgotCompleted.value)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                type: "primary",
                onClick: _cache[13] || (_cache[13] = ($event: any) => (forgotPassword(forgotForm.value)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "check",
                    class: "icon-text-button"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('message.confirm')), 1)
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_el_button, {
                key: 1,
                type: "primary",
                onClick: _cache[14] || (_cache[14] = ($event: any) => (forgotDialog.value = false))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "check",
                    class: "icon-text-button"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('message.close')), 1)
                ]),
                _: 1
              })),
          (!forgotCompleted.value)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 2,
                type: "danger",
                onClick: _cache[15] || (_cache[15] = ($event: any) => (forgotDialog.value = false)),
                plain: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "xmark",
                    class: "icon-text-button"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('message.cancel')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          ref_key: "forgotForm",
          ref: forgotForm,
          model: forgotData,
          rules: forgotRules.value,
          "label-width": "auto",
          "label-position": 'right',
          class: "reg-form"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('user.login-name'),
              prop: "loginName"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: forgotData.loginName,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((forgotData.loginName) = $event)),
                  placeholder: _ctx.$t('user.forgot-login-name-tip' ),
                  clearable: ""
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('user.email'),
              prop: "email"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: forgotData.email,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((forgotData.email) = $event)),
                  placeholder: _ctx.$t('user.email-tip'),
                  clearable: ""
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, { style: {"padding":"0","margin":"0"} }, {
              default: _withCtx(() => [
                _createVNode(_component_el_alert, {
                  type: "warning",
                  description: forgotMessage.value,
                  closable: false,
                  "show-icon": "",
                  effect: 'light',
                  class: _normalizeClass(['reg-alert', {'el-alert-visible': forgotMessage.value}])
                }, null, 8, ["description", "class"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ]))
}
}

})