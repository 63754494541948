// Path: src/assets/icons.ts
import {library} from '@fortawesome/fontawesome-svg-core';

import {
    faAddressBook,
    faArrowRotateRight,
    faBrain,
    faCheck,
    faChessBoard,
    faGears,
    faListCheck,
    faLock,
    faRectangleList,
    faRightToBracket,
    faUser,
    faUserPlus,
    faUsers,
    faUserGear,
    faXmark,
    faGear,
    faComments,
    faUserGroup,
    faMagnifyingGlass,
    faPen,
    faTrashCan,
    faCirclePlus,
    faPlus,
    faSun,
    faMoon,
    faPersonRunning,
    faRobot,
    faUserTie,
    faCircleArrowUp,
    faPaperclip,
    faArrowsRotate,
    faCopy,
    faCommentDots,
    faHandSpock,
    faWarehouse,
    faFolderTree,
    faArrowsTurnToDots,
    faCircleCheck,
    faCircleChevronRight,
    faAngleRight,
    faCircle,
    faUpload,
    faArrowRightArrowLeft,
    faFloppyDisk,
    faTags,
    faPlugCircleBolt,
    faPlugCircleCheck,
    faPlugCirclePlus,
    faBars,
    faCircleStop,
    faCircleNodes
} from '@fortawesome/free-solid-svg-icons';

// 添加所有图标到库
library.add(
    faUser,
    faLock,
    faRightToBracket,
    faArrowRotateRight,
    faUserPlus,
    faXmark,
    faCheck,
    faRectangleList,
    faChessBoard,
    faListCheck,
    faUsers,
    faUserGear,
    faAddressBook,
    faGears,
    faBrain,
    faGear,
    faComments,
    faUserGroup,
    faMagnifyingGlass,
    faPen,
    faTrashCan,
    faCirclePlus,
    faPlus,
    faSun,
    faMoon,
    faPersonRunning,
    faRobot,
    faUserTie,
    faCircleArrowUp,
    faPaperclip,
    faArrowsRotate,
    faCopy,
    faCommentDots,
    faHandSpock,
    faWarehouse,
    faFolderTree,
    faArrowsTurnToDots,
    faCircleCheck,
    faCircleChevronRight,
    faAngleRight,
    faCircle,
    faUpload,
    faArrowRightArrowLeft,
    faFloppyDisk,
    faTags,
    faPlugCircleBolt,
    faPlugCircleCheck,
    faPlugCirclePlus,
    faBars,
    faCircleStop,
    faCircleNodes
);
