<script setup lang="ts">
import {computed, ref, watch} from "vue" // 导入 Vue 的核心功能
import {useI18n} from "vue-i18n" // 导入国际化插件
import {FormInstance} from 'element-plus' // 导入 Element Plus 的表单和上传组件
import {Topic} from "@/types/Topic" // 导入 User 类型定义
import {useTopicStore} from "@/stores/topicStore" // 导入用户存储
import {showNotification} from "@/services/tools"

// 使用用户存储和国际化
const topicStore = useTopicStore() // 使用用户存储
const {t} = useI18n() // 使用国际化

// 响应式状态变量
const newTopicMessage = ref<string>('') // 新模板信息提示
const topicForm = ref<FormInstance>() // 模板表单实例
const topicDialog = ref(false) // 创建对话框显示状态
const topic = ref<Topic>({} as Topic) // 创建用户对象
const TopicData = ref({ // 模板数据对象
  name: '',
  description: ''
})
// 初始化模板数据
const defaultTopicData = {
  name: '',
  description: ''
}

// 定义组件的 props 和 emits
const props = defineProps({ // 定义 props
  isShow: {
    type: Boolean,
    default: false
  },
  topic_info: {
    type: Object as () => Topic,
    default: null
  }
})
const emit = defineEmits(["refreshList", "update:isShow"]) // 定义 emits

// 监听 props.isShow 的变化并初始化相关状态
watch(() => props.isShow, (val) => {
  topicDialog.value = val // 设置对话框显示状态
  newTopicMessage.value = "" // 清空提示信息
}, {immediate: true}) // 立即执行

// 监听 props.topic_info 的变化并初始化相关状态
watch(() => props.topic_info, (newVal) => {
  if (newVal) {
    topic.value = newVal;
    ini_form(topic.value)
  } else {
    topic.value = {} as Topic;
  }
}, {immediate: true}) // 立即执行

// 表单验证规则
const topicRules = computed(() => ({
  name: [{required: true, max: 100, message: t('topic.name-check'), trigger: 'blur'}], // 名称验证规则
  description: [{required: true, max: 300, message: t('topic.description-check'), trigger: 'blur'}] // 描述验证规则
}))

// 实用函数：刷新父列表
const refreshFatherList = () => {
  try {
    emit("refreshList") // 触发 refreshList 事件
  } catch (error) {
    console.error('Error refreshing father list:', error) // 打印错误信息
  }
}

// 关闭对话框并重置表单
const closeDialog = async () => {
  try {
    topicDialog.value = false // 隐藏对话框
    newTopicMessage.value = "" // 清空提示信息
    refreshFatherList()
    emit('update:isShow', false) // 触发 update:isShow 事件
  } catch (error) {
    console.error('Error closing dialog:', error) // 打印错误信息
  }
}

// 上传用户信息
const save_topic = async (formEl: FormInstance | undefined) => {
  if (!formEl) return


  try {
    topic.value.name = TopicData.value.name // 设置模板名称
    topic.value.description = TopicData.value.description // 设置模板描述

    newTopicMessage.value = "" // 清空提示信息
    try {
      const valid = await formEl.validate() // 验证表单

      if (valid) {
        await topicStore.createOrUpdateTopic(topic.value)
        TopicData.value = {...defaultTopicData} //清空TopicData数据
        refreshFatherList() // 刷新父组件列表
        await closeDialog() // 关闭对话框
        showNotification('success', t('message.success'), t('topic.new-success-info')) // 显示成功通知
      }
    } catch (error: any) {
      newTopicMessage.value = "" // 清除错误提示
      let detail = "null"
      if (error.response) {
        detail = error.response.data.detail;
      }
      switch (detail) {
        case 'InvalidInput':
          newTopicMessage.value = "传入参数无效";
          break;
        case 'TopicHasExisted':
          newTopicMessage.value = t('topic.name-exist') // 显示话题名已存在提示
          break;
        case 'null':
          break;
        default:
          console.log(error.response.data)
          showNotification('error', '未知错误', t('topic.unknown-error'));
          break;
      }
    }

  } catch (error) {
    console.error('Failed to validate form:', error) // 打印错误信息
    newTopicMessage.value = t('topic.unknown-error') // 显示未知错误提示
  }
}

const ini_form = async (robot: Topic) => {
  if (robot) {
    TopicData.value.name = robot.name || '' // 设置用户头像 URL
    TopicData.value.description = robot.description || ''// 设置用户昵称
  }
}
</script>

<template>
  <el-dialog :visible="topicDialog" width="1100px"
             align-center center draggable :close-on-click-modal="false" :show-close="false">
    <template #header="">
      <div class="dialog-header">
        <span>{{$t('topic.new-title')}}</span>
        <el-button type="danger" @click="closeDialog" circle size="small">
           <font-awesome-icon icon="xmark" />
        </el-button>
      </div>
    </template>
    <el-form ref="topicForm" :model="TopicData" :rules="topicRules" label-width="80px" :label-position="'right'"
    >

      <el-row>
        <el-alert v-if="newTopicMessage" type="warning" :description="newTopicMessage" :closable="false" show-icon
                  :effect="'light'" class="reg-alert">
        </el-alert>
        <div style="width: 100%">
          <el-form-item :label="$t('topic.name')" prop="name">
            <el-input v-model="TopicData.name"
                      :placeholder="$t('topic.name-tip')"
                      clearable
                      show-word-limit
                      maxlength="100"
                      style="width: 100%;"></el-input>
          </el-form-item>
          <el-form-item :label="$t('topic.description')" prop="description">
            <el-input
                v-model="TopicData.description"
                :placeholder="$t('topic.description-tip')"
                clearable
                type="textarea"
                :rows="4"
                show-word-limit
                maxlength="300"></el-input>
          </el-form-item>
        </div>
      </el-row>
    </el-form>
    <template #footer>
      <div>
        <el-button type="primary" @click="save_topic(topicForm)">
          <font-awesome-icon icon="check" class="icon-text-button"/>
          {{ $t('message.save') }}
        </el-button>
        <el-button type="danger" @click="closeDialog" plain>
          <font-awesome-icon icon="xmark" class="icon-text-button"/>
          {{ $t('message.cancel') }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

.div_right {
  justify-content: flex-end;
  width: 99%;
  display: flex;
}

.divider_text {
  font-weight: bold;
  color: $header-font-color-light;
}

/* 注册专用警告框样式 */
.reg-alert {
  min-height: 50px; /* 最小高度适用于较少内容 */
  margin: 5px 0 10px 0;
  padding: 0 30px 0 30px; /* 无顶部外边距 */
  width: 97%;
}


.div-row {
  height: 148px;
  width: 148px;
  display: flex; /* 使用 flex 布局以居中图片 */
  justify-content: center;
  align-items: center;
  margin-right: 5px;

}


</style>
