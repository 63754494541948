import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "title" }

import {onMounted, ref, watch} from "vue";
import router from "@/router";
// 响应式变量记录激活的父菜单 index

export default /*@__PURE__*/_defineComponent({
  __name: 'SidebarMenu',
  props: {
  defaultActive: String
},
  emits: ["selected_menu_item"],
  setup(__props, { emit: __emit }) {

// import {useRouter} from 'vue-router';
const activeSubMenuIndex = ref<string | null>(null);
const cur_index = ref("0")

const isCollapse = ref<boolean>(localStorage.getItem("menu_export") === "true" || false)


const emit = __emit;

const exportMenu = () => {
  isCollapse.value = !isCollapse.value
  localStorage.setItem("menu_export", String(isCollapse.value));
}

const intoFunction = async (name: string) => {
  switch (name) {
    case 'menu-21':
      await router.push('/usermanage');
      break;
    case 'menu-12':
      await router.push('/prompt');
      break;
    case 'menu-11':
      await router.push('/robot');
      break;
    case 'menu-13':
      await router.push('/topic');
      break;
    case 'menu-14':
      await router.push('/chat_check');
      break;
    case 'menu-32':
      await router.push('/system');
      break;
    case 'menu-31':
      await router.push('/llm_setup');
      break;
    case 'menu-41':
      await router.push('/files');
      break;
    case 'menu-42':
      await router.push('/vectorization');
      break;
    case 'menu-43':
      await router.push('/search');
      break;
    case 'menu-51':
      await router.push('/api_manage');
      break;
    case 'menu-52':
      await router.push('/api_check');
      break;
    default:
      break;
  }
  emit('selected_menu_item', name);
};

// 统一处理菜单点击
const handleMenuItemClick = (parentIndex: string, menuKey: string) => {
  cur_index.value = parentIndex
  if (isCollapse.value) {
    activeSubMenuIndex.value = parentIndex; // 更新父级高亮状态
  } else {
    activeSubMenuIndex.value = "0"
  }
  intoFunction(menuKey); // 原有逻辑
};

const resetMenu = () => {
  if (isCollapse.value) {
    activeSubMenuIndex.value = cur_index.value
  } else {
    activeSubMenuIndex.value = "0"
  }
}

watch([cur_index,isCollapse], resetMenu)


onMounted(() => {
  handleMenuItemClick('5', 'menu-51')
});

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_button, {
      onClick: exportMenu,
      size: "large",
      type: "primary",
      text: "",
      bg: "",
      style: {"border-radius":"0","width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, {
          icon: "bars",
          style: {"font-size":"22px"}
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_menu, {
      "default-openeds": ['5'],
      "default-active": __props.defaultActive,
      collapse: isCollapse.value,
      "unique-opened": "",
      class: "el-menu-custom"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_sub_menu, {
          index: "5",
          class: _normalizeClass({ 'active-submenu': activeSubMenuIndex.value === '5' })
        }, {
          title: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "plug-circle-plus",
              class: "title-icon"
            }),
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('message.menu-5')), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_menu_item, {
              index: "5-1",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (handleMenuItemClick('5', 'menu-51')))
            }, {
              title: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "plug-circle-bolt",
                  rotation: "90",
                  class: "icon-text-button"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-51')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_menu_item, {
              index: "5-2",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (handleMenuItemClick('5', 'menu-52')))
            }, {
              title: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "plug-circle-check",
                  class: "icon-text-button"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-52')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_el_sub_menu, {
          index: "1",
          class: _normalizeClass({ 'active-submenu': activeSubMenuIndex.value === '1' })
        }, {
          title: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "hand-spock",
              class: "title-icon"
            }),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('message.menu-1')), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_menu_item, {
              index: "1-1",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (handleMenuItemClick('1', 'menu-11')))
            }, {
              title: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "robot",
                  class: "icon-text-button"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-11')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_menu_item, {
              index: "1-2",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (handleMenuItemClick('1', 'menu-12')))
            }, {
              title: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "chess-board",
                  class: "icon-text-button"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-12')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_menu_item, {
              index: "1-3",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (handleMenuItemClick('1', 'menu-13')))
            }, {
              title: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "tags",
                  class: "icon-text-button"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-13')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_menu_item, {
              index: "1-4",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (handleMenuItemClick('1', 'menu-14')))
            }, {
              title: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "list-check",
                  class: "icon-text-button"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-14')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_el_sub_menu, {
          index: "4",
          class: _normalizeClass({ 'active-submenu': activeSubMenuIndex.value === '4' })
        }, {
          title: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "warehouse",
              class: "title-icon"
            }),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('message.menu-4')), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_menu_item, {
              index: "4-1",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (handleMenuItemClick('4', 'menu-41')))
            }, {
              title: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "folder-tree",
                  class: "icon-text-button"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-41')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_menu_item, {
              index: "4-2",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (handleMenuItemClick('4', 'menu-42')))
            }, {
              title: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "arrows-turn-to-dots",
                  class: "icon-text-button"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-42')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_menu_item, {
              index: "4-3",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (handleMenuItemClick('4', 'menu-43')))
            }, {
              title: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "list-check",
                  class: "icon-text-button"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-43')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_el_sub_menu, {
          index: "2",
          class: _normalizeClass({ 'active-submenu': activeSubMenuIndex.value === '2' })
        }, {
          title: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "users",
              class: "title-icon"
            }),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('message.menu-2')), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_menu_item, {
              index: "2-1",
              onClick: _cache[9] || (_cache[9] = ($event: any) => (handleMenuItemClick('2', 'menu-21')))
            }, {
              title: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "user-gear",
                  class: "icon-text-button"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-21')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_menu_item, {
              index: "2-2",
              onClick: _cache[10] || (_cache[10] = ($event: any) => (handleMenuItemClick('2', 'menu-22')))
            }, {
              title: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "address-book",
                  class: "icon-text-button"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-22')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_el_sub_menu, {
          index: "3",
          class: _normalizeClass({ 'active-submenu': activeSubMenuIndex.value === '3' })
        }, {
          title: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "gear",
              class: "title-icon"
            }),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('message.menu-3')), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_menu_item, {
              index: "3-1",
              onClick: _cache[11] || (_cache[11] = ($event: any) => (handleMenuItemClick('3', 'menu-31')))
            }, {
              title: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "brain",
                  class: "icon-text-button"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-31')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_menu_item, {
              index: "3-2",
              onClick: _cache[12] || (_cache[12] = ($event: any) => (handleMenuItemClick('3', 'menu-32')))
            }, {
              title: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "gears",
                  class: "icon-text-button"
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-32')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _: 1
    }, 8, ["default-active", "collapse"])
  ], 64))
}
}

})