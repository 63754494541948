<script setup lang="ts">

</script>

<!-- CustomIcon.vue -->
<template>
  <font-awesome-icon :icon="['fas', 'circle-chevron-right']" class="icon" />
</template>


<style scoped lang="scss">
@import '@/assets/styles/colors.scss';

  .icon {
    font-size: 16px;
    color: $header-font-color-light;
  }
</style>