<script setup lang="ts">
import {computed, reactive, ref} from 'vue';
import {FormInstance} from 'element-plus';
import {useRouter} from 'vue-router';
import {useUserStore} from '@/stores/userStore';
import {useI18n} from "vue-i18n";
import {User} from '@/types/User';
import {showNotification} from "@/services/tools";
import {validateConfirmPassword, validatePassword} from "@/services/validators"
import {SystemOption} from "@/types/system";
// 控制注册和忘记密码对话框的显示
const registerDialog = ref(false); // 注册对话框的显示状态
const forgotDialog = ref(false); // 忘记密码对话框的显示状态

// 错误消息变量
const errorMessage = ref<string>(''); // 登录错误消息
const regMessage = ref<string>(''); // 注册错误消息
const forgotMessage = ref<string>(''); // 忘记密码错误消息
const forgotCompleted = ref(false); // 忘记密码操作是否完成
const login_state = ref(false);

// 使用国际化功能
const {t} = useI18n();

// 表单实例引用
const loginForm = ref<FormInstance>(); // 登录表单实例
const registerForm = ref<FormInstance>(); // 注册表单实例
const forgotForm = ref<FormInstance>(); // 忘记密码表单实例

// Vue Router实例
const router = useRouter();

// 用户存储实例
const userStore = useUserStore();

// 登录表单数据
const loginFormData = reactive({
  login_name: '', // 登录名
  pass: '' // 密码
});

// 注册表单数据
const regData = reactive({
  loginName: '', // 登录名
  password: '', // 密码
  confirmPassword: '', // 确认密码
  nickName: '' // 昵称
});

// 忘记密码表单数据
const forgotData = reactive({
  loginName: '', // 登录名
  email: '' // 邮箱
});

// 定义登录表单验证规则
const loginRules = computed(() => ({
  login_name: [{required: true, message: t('user.login-name-check'), trigger: 'blur'}],
  pass: [{required: true, min: 3, max: 50, message: t('user.password-check'), trigger: 'blur'}]
}));

// 定义注册表单验证规则
const regRules = computed(() => ({
  nickName: [{required: false, max: 30, message: t('user.nickname-check'), trigger: 'blur'}],
  loginName: [{required: true, max: 30, message: t('user.reg-login-name-check'), trigger: 'blur'}],
  password: [
    {
      required: true,
      trigger: 'blur',
      validator: (rule: any, value: any, callback: any) => validatePassword(t, rule, value, callback, true) // 密码验证规则
    }
  ],
  confirmPassword: [
    {
      required: true,
      trigger: 'blur',
      validator: (rule: any, value: any, callback: any) => validateConfirmPassword(t, rule, value, callback, regData.password) // 确认密码验证规则
    }
  ],
}));

// 定义忘记密码表单验证规则
const forgotRules = computed(() => ({
  loginName: [{required: true, max: 30, message: t('user.reg-login-name-check'), trigger: 'blur'}],
  email: [
    {required: true, message: t('user.email-check'), trigger: 'blur'},
    {type: 'email', message: t('user.email-invalid'), trigger: 'blur'}
  ]
}));

/**
 * 登录逻辑处理函数
 * @param {FormInstance | undefined} formEl - 登录表单实例
 */
const login = async (formEl: FormInstance | undefined) => {
  if (!formEl) return; // 如果表单实例不存在，则返回
  // 显示加载指示器


  try {
    login_state.value = true;
    // 验证表单数据
    const valid = await formEl.validate();
    if (valid) {
      // 调用用户存储中的登录方法
      const response = await userStore.login(loginFormData.login_name, loginFormData.pass);
      if (response === '0') {
        errorMessage.value = "";
        await router.push('/robot'); // 跳转到首页
      }
    }
  } catch (error: any) {
    let detail = ""
    errorMessage.value = "";
    if (error.response) {
      detail = error.response.data.detail;
    }
    switch (detail) {
      case 'UserNotFound':
        errorMessage.value = t('user.login-name-error'); // 登录名错误
        break;
      case 'Invalid Password':
        errorMessage.value = t('user.password-error'); // 密码错误
        break;
    }
  } finally {
    login_state.value = false;
  }
};

/**
 * 注册逻辑处理函数
 * @param {FormInstance | undefined} formEl - 注册表单实例
 */
const register = async (formEl: FormInstance | undefined) => {
  if (!formEl) return; // 如果表单实例不存在，则返回

  // 定义用户数据
  const user = reactive({
    name: regData.nickName,
    login_name: regData.loginName,
    password: regData.password
  } as User);

  try {
    regMessage.value = "";
    const valid = await formEl.validate();
    if (valid) {
      // 调用用户存储中的注册方法
      const response = await userStore.register(user, null);

      if (response === '0') {
        registerDialog.value = false; // 关闭注册对话框
        loginFormData.login_name = userStore.user.login_name as string; // 设置登录名为注册的用户名
        showNotification("success", t('user.register-success'), t('user.register-success-info')); // 显示注册成功的通知
      }

    }
  } catch (error: any) {
    let detail = "null"
    regMessage.value = "";
    if (error.response) {
      detail = error.response.data.detail;
    }
    switch (detail) {
      case 'LoginNameExists':
        regMessage.value = t('user.register-name-exist'); // 注册名已存在
        break;
      case 'UserNotFound':
        regMessage.value = t('user.register-name-exist'); // 注册名已存在
        break;
      case 'CreateSchemaFailed':
        regMessage.value = t('user.create-schema-error'); // 未知错误
        break;
      case 'null':
        break;
      default:
        showNotification('error', '未知状态', error.response.data.detail);
        break;
    }
  }
};

/**
 * 忘记密码逻辑处理函数
 * @param {FormInstance | undefined} formEl - 忘记密码表单实例
 */
const forgotPassword = async (formEl: FormInstance | undefined) => {
  if (!formEl) return; // 如果表单实例不存在，则返回

  try {
    forgotMessage.value = "";
    const valid = await formEl.validate();
    if (valid) {
      // 调用用户存储中的忘记密码方法
      const result = await userStore.forgotPassword(forgotData.loginName, forgotData.email);

      // 根据返回的结果处理不同的情况
      switch (result) {
        case '0':
          forgotCompleted.value = true; // 忘记密码操作完成
          forgotMessage.value = t('user.forgot-success'); // 显示成功消息
          break;
        case "1":
          forgotMessage.value = t('user.unknown-error'); // 未知错误
          break;
        default:
          forgotMessage.value = t('user.unknown-error'); // 未知错误
          console.error(`后台返回了一个未知状态： ${forgotMessage.value}`);
          break;
      }
    }
  } catch (error) {
    console.error('表单验证失败:', error); // 捕获表单验证失败的错误
  }
};

/**
 * 重置表单
 * @param {FormInstance | undefined} formEl - 表单实例
 */
const resetForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return; // 如果表单实例不存在，则返回
  errorMessage.value = "";
  formEl.resetFields(); // 重置表单字段
};

/**
 * 打开注册对话框
 */
const openRegisterDialog = () => {
  resetForm(loginForm.value); // 重置登录表单
  resetForm(registerForm.value); // 重置注册表单
  regMessage.value = "";
  registerDialog.value = true; // 显示注册对话框
};

/**
 * 打开忘记密码对话框
 */
const openForgotDialog = () => {
  resetForm(loginForm.value); // 重置登录表单
  resetForm(registerForm.value); // 重置注册表单
  resetForm(forgotForm.value); // 重置忘记密码表单
  forgotMessage.value = "";
  forgotCompleted.value = false;
  forgotDialog.value = true; // 显示忘记密码对话框
};
</script>


<template>
  <div class="loading-form">
    <el-form ref="loginForm"
             :model="loginFormData"
             :rules="loginRules"
             label-width="auto"
             :label-position="'top'"
             class="login-form">
      <div style="display: flex; justify-content: center;margin-bottom: 20px">
        <el-avatar :size="180" :src="SystemOption['ai_avatar']"/>
      </div>
      <el-form-item prop="login_name">
        <template #label>
          <span class="form-label">
            <font-awesome-icon icon="user"/>
            {{ $t('user.login-name') }}
          </span>
        </template>
        <el-input v-model="loginFormData.login_name" :placeholder="$t('user.name-tip')" autocomplete="on"
                  clearable></el-input>
      </el-form-item>
      <el-form-item prop="pass">
        <template #label>
            <span class="form-label">
              <font-awesome-icon icon="lock"/>
              {{ $t('user.password') }}
            </span>
        </template>
        <el-input type="password" v-model="loginFormData.pass" :placeholder=" $t('user.password-tip')"
                  autocomplete="off" show-password clearable></el-input>
      </el-form-item>

      <div class="container">
        <div class="left-side">
          <div>
            <el-button type="primary" @click="login(loginForm)" :disabled="login_state"
                       :style="{height:'80px',width:'140px'}">
              <template #default>
                <div class="icon-text-button-up">
                  <font-awesome-icon icon="right-to-bracket" size="2xl" fade style="margin-bottom: 6px"/>
                  {{ $t('user.login') }}
                </div>
              </template>
            </el-button>
          </div>
        </div>
        <div class="right-side">
          <div>
            <el-button type="primary" @click="resetForm(loginForm)" circle>
              <template #default>
                <font-awesome-icon icon="arrow-rotate-right"/>
              </template>
            </el-button>
            <el-button type="primary" @click="openForgotDialog" :style="{width:'100px'}" round>
              {{ $t('user.forget') }}
            </el-button>
          </div>
          <div>
            <el-button type="primary" @click="openRegisterDialog" :style="{width:'145px'}" round>
              <template #default>
                <font-awesome-icon icon="user-plus" class="icon-text-button"/>
                {{ $t('user.register') }}
              </template>
            </el-button>
          </div>
        </div>
      </div>
      <el-form-item style="padding: 0;margin: 0;">
        <el-alert type="warning" :description="errorMessage" :closable="false" show-icon :effect="'light'"
                  :class="{'el-alert-visible': errorMessage}">
        </el-alert>
      </el-form-item>
    </el-form>

    <el-dialog v-model="registerDialog" :title="$t('user.register-title')" width="600" align-center center draggable
               :close-on-click-modal="false">
      <el-form
          ref="registerForm"
          :model="regData"
          :rules="regRules"
          label-width="auto"
          :label-position="'right'"
          class="reg-form"
      >
        <el-form-item :label="$t('user.nickname')" prop="nickName">
          <el-input v-model="regData.nickName" :placeholder="$t('user.nickname-tip')" clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.login-name')" prop="loginName">
          <el-input v-model="regData.loginName" :placeholder="$t('user.login-name-tip' )" clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.password')" prop="password">
          <el-input type="password" v-model="regData.password" :placeholder=" $t('user.input-password-tip')"
                    autocomplete="off" show-password clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.confirm-password')" prop="confirmPassword">
          <el-input type="password" v-model="regData.confirmPassword" :placeholder=" $t('user.confirm-password-tip')"
                    autocomplete="off" show-password clearable></el-input>
        </el-form-item>
        <el-form-item style="padding: 0;margin: 0;">
          <el-alert type="warning" :description="regMessage" :closable="false" show-icon :effect="'light'"
                    :class="['reg-alert', {'el-alert-visible': regMessage}]">
          </el-alert>
        </el-form-item>
      </el-form>

      <template #footer>
        <div>
          <el-button type="primary" @click="register(registerForm)">
            <template #default>
              <font-awesome-icon icon="check" class="icon-text-button"/>
              {{ $t('message.confirm') }}
            </template>
          </el-button>
          <el-button type="danger" @click="registerDialog = false" plain>
            <template #default>
              <font-awesome-icon icon="xmark" class="icon-text-button"/>
              {{ $t('message.cancel') }}
            </template>
          </el-button>
        </div>
      </template>

    </el-dialog>

    <el-dialog v-model="forgotDialog" :title="$t('user.forgot-title')" width="600" align-center center draggable
               :close-on-click-modal="false">
      <el-form
          ref="forgotForm"
          :model="forgotData"
          :rules="forgotRules"
          label-width="auto"
          :label-position="'right'"
          class="reg-form"
      >
        <el-form-item :label="$t('user.login-name')" prop="loginName">
          <el-input v-model="forgotData.loginName" :placeholder="$t('user.forgot-login-name-tip' )"
                    clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.email')" prop="email">
          <el-input v-model="forgotData.email" :placeholder="$t('user.email-tip')" clearable></el-input>
        </el-form-item>
        <el-form-item style="padding: 0;margin: 0;">
          <el-alert type="warning" :description="forgotMessage" :closable="false" show-icon :effect="'light'"
                    :class="['reg-alert', {'el-alert-visible': forgotMessage}]">
          </el-alert>
        </el-form-item>
      </el-form>

      <template #footer>
        <div style="padding: 0;margin: 0;">
          <el-button
              type="primary"
              @click="forgotPassword(forgotForm)"
              v-if="!forgotCompleted">
            <template #default>
              <font-awesome-icon icon="check" class="icon-text-button"/>
              {{ $t('message.confirm') }}
            </template>
          </el-button>
          <el-button
              type="primary"
              @click="forgotDialog = false"
              v-else>
            <template #default>
              <font-awesome-icon icon="check" class="icon-text-button"/>
              {{ $t('message.close') }}
            </template>
          </el-button>
          <el-button
              type="danger"
              @click="forgotDialog = false"
              plain
              v-if="!forgotCompleted">
            <template #default>
              <font-awesome-icon icon="xmark" class="icon-text-button"/>
              {{ $t('message.cancel') }}
            </template>
          </el-button>
        </div>
      </template>

    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

/* 按钮样式 */
.el-button {
  margin-top: 10px; /* 按钮顶部外边距 */
  border: none !important;
}


.el-input :deep(.el-input__inner) {
  color: $footer-font-color-light;

  html.dark & {
    color: $footer-font-color-light;
  }
}

.el-input :deep(.el-input__wrapper) {

  html.dark & {
    background-color: $input-background-light;
  }
}

.form-label {
  color: $footer-font-color-light;

  html.dark & {
    color: $footer-font-color-light;
  }
}


.el-input :deep(.el-input__wrapper){
  --el-input-border-color:0.1px solid white;
  border-radius: 15px;
  background: rgba(255,255,255,0.8);

  html.dark & {

    background: rgba(255,255,255,0.4);
  }
}

/* 警告框基础样式 */
.el-alert {
  min-height: 70px; /* 最小高度，确保内容可见 */
  visibility: hidden; /* 默认设置为不可见 */
}


/* 注册专用警告框样式 */
.reg-alert {
  min-height: 50px; /* 最小高度适用于较少内容 */
  margin-top: 0; /* 无顶部外边距 */
}

/* 可见警告框样式 */
.el-alert-visible {
  visibility: visible; /* 修改可见性以显示警告框 */
}

/* 登录表单样式 */
.login-form {
  width: 300px;
  height: 550px;
  justify-content: center; /* 水平居中对齐子元素 */
  margin: 0 auto; /* 自动外边距实现水平居中 */
}

/* 加载表单样式 */
.loading-form {
  width: 300px;
  height: 550px;
  position: relative; /* 相对定位 */
  background-color: transparent;
}

/* 注册表单样式 */
.reg-form {
  max-width: 90%; /* 最大宽度限制 */
  justify-content: center; /* 水平居中对齐子元素 */
  margin: 0 auto; /* 自动外边距实现水平居中 */
}

/* 容器样式 */
.container {
  display: flex; /* 使用弹性布局 */
  justify-content: center; /* 水平居中 */
  margin-top: 20px; /* 顶部外边距 */
}

/* 左右容器样式 */
.left-side,
.right-side {
  flex: 1; /* 弹性布局下的空间分配 */
  height: 90px; /* 固定高度 */
  justify-content: center; /* 水平居中 */
  align-items: self-start; /* 垂直对齐至起始位置 */
}

/* 右侧容器样式 */
.right-side {
  display: flex;
  flex-direction: column; /* 垂直布局 */
}

</style>
