import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["onMouseover", "onMouseleave"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { style: {"display":"flex","flex-direction":"row","gap":"5px"} }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { style: {"display":"flex","flex-direction":"row","gap":"10px"} }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 1,
  style: {"display":"flex","gap":"10px"}
}
const _hoisted_10 = { style: {"padding-top":"3px"} }
const _hoisted_11 = {
  key: 2,
  class: "permission_tag"
}
const _hoisted_12 = { class: "tags-row" }
const _hoisted_13 = { key: 3 }
const _hoisted_14 = { class: "overlay-text" }
const _hoisted_15 = {
  key: 0,
  style: {"align-content":"center"}
}
const _hoisted_16 = { class: "dialog-header" }
const _hoisted_17 = { class: "notice-txt" }

import {onMounted, ref, watch} from 'vue';
import {
  autoHeight,
  get_user_label,
  showMessageBox,
  showNotification
} from "@/services/tools";
import treeIcon from '@/components/treeIcon.vue'; // 引入你的自定义图标组件
import {LabelUserRelation, TreeNode} from "@/types/system";
import {useI18n} from "vue-i18n"
import {del_tree_node, get_tree, save_tree_node} from "@/services/systemService"; // 导入国际化插件
interface Props {
  treeName: string; // 传递的 treeName 必须是字符串
  m_select: boolean;
  node_ids: number[];
}

// 使用 defineProps 接收父组件传递的 props

export default /*@__PURE__*/_defineComponent({
  __name: 'TreeList',
  props: {
    treeName: {},
    m_select: { type: Boolean },
    node_ids: {}
  },
  emits: ["selected-node-changed", "updateNodeIds"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const treeData = ref<TreeNode[]>([]);
// 存储当前选中的节点
const selectedNode = ref<any>(null);
const treeRef = ref();
const tempEditTxt = ref('新目录');
const editStation = ref<boolean[]>([]);
const addStation = ref<boolean>(true);
const contentHeight = autoHeight(140);
const scrollbarHeight = ref<number>(100);
const modifyDialog = ref<boolean>(false);
const del_child_label = ref<boolean>(false);
const add_child_label = ref<boolean>(true);
const modifyName = ref<string>('');
const person_data = ref<LabelUserRelation[]>([]);
const permission_value = ref<number[]>([]);
const knowledge_node_ids = ref<number[]>([]);

// 使用国际化功能
const {t} = useI18n();
const emit = __emit;

// 定义 trans
const props = __props;

const tree_node = {
  value: 'id',
  children: 'children',
  label: 'label'
};

const addRoot = () => {
  // 查找 parent_id 为 null 的唯一根节点
  const rootNode = treeData.value.find(node => node.parent_id === null);
  if (!rootNode) return

  const rootId = rootNode.id
  tempEditTxt.value = "新知识库"
  editStation.value = editStation.value.map(() => false);
  addStation.value = false

  const newRoot: TreeNode = {
    id: Date.now(),
    label: tempEditTxt.value,
    children: [],
    parent_id: rootId,
    showButton: false,
    label_names: [],
    label_ids: [],
    tree_name: props.treeName,
    file_count: 0,
    isEditing: true,
    isNew: true
  }

  treeData.value.push(newRoot)
  treeData.value = [...treeData.value]
  newRoot.isEditing = false
  editStation.value[newRoot.id] = true
}

const append = (data: TreeNode) => {
  tempEditTxt.value = "新目录"
  editStation.value = editStation.value.map(() => false);
  addStation.value = false

  const newChild: TreeNode = {
    id: Date.now(),
    label: tempEditTxt.value,
    children: [],
    parent_id: data.id,
    showButton: false,
    label_names: [],
    label_ids: [],
    tree_name: props.treeName,
    file_count: 0,
    isEditing: true,
    isNew: true
  }

  if (!data.children) {
    data.children = []
  }

  data.children.push(newChild)
  treeData.value = [...treeData.value]
  newChild.isEditing = false
  editStation.value[newChild.id] = true
}

const remove = async (data: TreeNode, node: any) => {
  try {
    await showMessageBox(t, t("message.del-node"), "[ " + data.label + " ] " + t("message.del-node-alart"), false)
    const response = await del_tree_node(data.tree_name, data.id, false)
    if (Number(response) > 0) {
      const count = Number(response)
      showNotification("success", t("message.del-node"), "[ " + data.label + " ] " + t("message.del-node-success", {count}))
      const parent = node.parent
      const children: TreeNode[] = parent.data.children || parent.data
      const index = children.findIndex((d) => d.id === data.id)
      children.splice(index, 1)
      treeData.value = [...treeData.value]

    }
  } catch (error) {
    console.error("unknown error : ", error);
  }
}

const modify = async (data: TreeNode) => {
  modifyName.value = selectedNode.value.data.label
  editStation.value = editStation.value.map(() => false);
  addStation.value = false
  const response = await get_user_label(-1)

  if (response) {
    person_data.value = response
    if (data.label_ids) {
      permission_value.value = data.label_ids;
    } else {
      permission_value.value.length = 0
    }
    modifyDialog.value = true
  } else {
    console.error("unknown error")
    modifyDialog.value = false
  }

}

const save_modify_node = async () => {
  const data = selectedNode.value.data
  data.label = modifyName.value
  data.label_ids = permission_value.value
  const response = await save_tree_node(data, del_child_label.value, add_child_label.value)
  if (response != -1) {
    data.id = response
    selectedNode.value.data = data
    editStation.value[data.id] = false
    addStation.value = true
    treeData.value = await get_tree(props.treeName)
    modifyDialog.value = false
  } else {
    addStation.value = false
    modifyDialog.value = true
  }
}

const saveNode = async (data: any) => {
  if (tempEditTxt.value == '') return
  const temp_label = data.label
  data.label = tempEditTxt.value
  data.tree_name = props.treeName
  const response = await save_tree_node(data, false, false)
  if (response != -1) {
    data.id = response
    data.isNew = false
    editStation.value[data.id] = false
    addStation.value = true
  } else {
    data.label = temp_label
    editStation.value[data.id] = true
    addStation.value = false
  }
}

const closeDialog = () => {
  modifyDialog.value = false
  cancelNode(selectedNode.value.data, selectedNode.value)
}

const cancelNode = (data: any, node: any) => {
  tempEditTxt.value = data.label
  editStation.value[data.id] = false
  addStation.value = true
  if (data.isNew) {
    data.file_count = 0
    const parent = node.parent
    const children: TreeNode[] = parent.data.children || parent.data
    const index = children.findIndex((d) => d.id === data.id)
    children.splice(index, 1)
    treeData.value = [...treeData.value]
  }
}

const handleNodeDrop = async (before: any, after: any, inner: any) => {
  switch (inner) {
    case "inner":
      before.data.parent_id = after.data.id;
      break;
    case "before":
      before.data.parent_id = after.data.parent_id;
      break;
    default:
      before.data.parent_id = after.data.parent_id;
  }

  await save_tree_node(before.data, false, false)
}

// 递归函数：将所有节点的 showButton 属性设置为 false
const setShowButtonFalse = (nodes: TreeNode[]) => {
  nodes.forEach(node => {
    node.showButton = false;
    if (node.children && node.children.length > 0) {
      setShowButtonFalse(node.children);
    }
  });
};

const handleClickNode = (data: any, node: any) => {
  selectedNode.value = node;  // 将选中的节点存储
  setShowButtonFalse(treeData.value); // 将所有节点的 showButton 属性设置为 false
  data.showButton = !!data.parent_id;
  data.isEditing = true
}

const handleCheckNode = () => {
  knowledge_node_ids.value = treeRef.value.getCheckedKeys()
  const nodes: TreeNode[] = treeRef.value.getCheckedNodes()
  emit("updateNodeIds", knowledge_node_ids.value, nodes);
}

const handleMouseOver = (data: any) => {
  // 仅当鼠标悬浮在选中的节点上时才显示按钮
  if (props.m_select) return;
  if (!data.parent_id) return;
  const currentNode = treeRef.value ? treeRef.value.getCurrentNode() : null;
  data.showButton = currentNode && currentNode.id === data.id;
}

const refreshTree = async () => {
  treeData.value = await get_tree(props.treeName)
  addStation.value = true
  selectedNode.value = null
  if (props.node_ids) {
    treeRef.value.setCheckedKeys(props.node_ids, false)
    handleCheckNode()
  }
}

watch(selectedNode, (newValue) => {
  // 发出事件到父组件
  if (newValue) {
    emit('selected-node-changed', newValue);
  }
});

onMounted(async () => {
  try {
    treeData.value = await get_tree(props.treeName)
    addStation.value = true
    if (props.m_select) {
      scrollbarHeight.value = 400
    } else {
      scrollbarHeight.value = contentHeight.value
    }
    if (props.node_ids) {
      treeRef.value!.setCheckedKeys(props.node_ids, false)
    }

  } catch (error) {
    console.error('Error fetching tree data:', error);
  }
});


// 暴露 `getSelectedItems` 方法，让父组件能访问它
const getSelectedItems = () => {
  treeRef.value.getCheckedKeys
  return knowledge_node_ids.value
};

const setSelectedItems = (n_ids: number[]) => {
  knowledge_node_ids.value = n_ids
  treeRef.value.setCheckedKeys(knowledge_node_ids.value, false)
};

__expose({
  refreshTree,
  getSelectedItems,
  setSelectedItems
});


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_transfer = _resolveComponent("el-transfer")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_scrollbar, {
      class: "tree-content",
      height: scrollbarHeight.value
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tree, {
          ref_key: "treeRef",
          ref: treeRef,
          data: treeData.value,
          props: tree_node,
          "node-key": "id",
          draggable: !_ctx.m_select,
          "show-checkbox": _ctx.m_select,
          "check-strictly": true,
          "highlight-current": "",
          "default-expand-all": !_ctx.m_select,
          "default-expanded-keys": [-1],
          "expand-on-click-node": false,
          "auto-expand-parent": "",
          accordion: _ctx.m_select,
          icon: treeIcon,
          onCheckChange: handleCheckNode,
          onNodeClick: handleClickNode,
          onNodeDrop: handleNodeDrop,
          class: "tree-top"
        }, {
          default: _withCtx(({ data,node }) => [
            _createElementVNode("div", {
              class: "top-row",
              onMouseover: ($event: any) => (handleMouseOver(data)),
              onMouseleave: ($event: any) => (data.showButton=false)
            }, [
              (!editStation.value[data.id])
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (data.file_count!=0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createVNode(_component_el_badge, {
                            value: data.file_count,
                            max: 999999,
                            offset: [15, 9],
                            type: "warning"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_4, [
                                (data.children.length === 0 && data.parent_id != -1)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                      _createVNode(_component_font_awesome_icon, {
                                        icon: "circle",
                                        size: "xs"
                                      })
                                    ]))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_el_tooltip, {
                                  class: "box-item",
                                  content: data.label,
                                  effect: "customized",
                                  placement: "bottom-start",
                                  disabled: data.label.length<12
                                }, {
                                  default: _withCtx(() => [
                                    (data.parent_id === -1)
                                      ? (_openBlock(), _createBlock(_component_el_text, {
                                          key: 0,
                                          type: "primary",
                                          tag: "b",
                                          truncated: "",
                                          style: {"max-width":"180px"}
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(data.label), 1)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      : (_openBlock(), _createBlock(_component_el_text, {
                                          key: 1,
                                          truncated: "",
                                          style: {"max-width":"180px"}
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(data.label), 1)
                                          ]),
                                          _: 2
                                        }, 1024))
                                  ]),
                                  _: 2
                                }, 1032, ["content", "disabled"])
                              ])
                            ]),
                            _: 2
                          }, 1032, ["value"])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            (data.children.length === 0 && data.parent_id != -1)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                  _createVNode(_component_font_awesome_icon, {
                                    icon: "circle",
                                    size: "xs"
                                  })
                                ]))
                              : _createCommentVNode("", true),
                            _createVNode(_component_el_tooltip, {
                              class: "box-item",
                              content: data.label,
                              effect: "customized",
                              placement: "bottom-start",
                              disabled: data.label.length<12
                            }, {
                              default: _withCtx(() => [
                                (data.parent_id === -1)
                                  ? (_openBlock(), _createBlock(_component_el_text, {
                                      key: 0,
                                      type: "primary",
                                      tag: "b",
                                      truncated: "",
                                      style: {"max-width":"180px"}
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(data.label), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : (_openBlock(), _createBlock(_component_el_text, {
                                      key: 1,
                                      truncated: "",
                                      style: {"max-width":"180px"}
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(data.label), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                              ]),
                              _: 2
                            }, 1032, ["content", "disabled"])
                          ])
                        ]))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_el_input, {
                      modelValue: tempEditTxt.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((tempEditTxt).value = $event)),
                      maxlength: "100",
                      "show-word-limit": "",
                      resize: "none",
                      style: {"width":"auto"}
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("div", _hoisted_10, [
                      (data.isEditing)
                        ? (_openBlock(), _createBlock(_component_el_button, {
                            key: 0,
                            type: "success",
                            size: "small",
                            circle: "",
                            onClick: ($event: any) => (saveNode(data))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_font_awesome_icon, { icon: "check" })
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        : _createCommentVNode("", true),
                      (data.isEditing)
                        ? (_openBlock(), _createBlock(_component_el_button, {
                            key: 1,
                            type: "danger",
                            size: "small",
                            circle: "",
                            onClick: ($event: any) => (cancelNode(data,node))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_font_awesome_icon, { icon: "xmark" })
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        : _createCommentVNode("", true)
                    ])
                  ])),
              (data.label_names.length>0 && !data.showButton)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createVNode(_component_el_popover, {
                      placement: "right",
                      width: 300,
                      trigger: "hover",
                      "show-after": 500
                    }, {
                      reference: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "lock" })
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_12, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.label_names, (item, index) => {
                            return (_openBlock(), _createBlock(_component_el_tag, {
                              key: index,
                              type: "success"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item), 1)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ])
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                : _createCommentVNode("", true),
              (data.showButton && addStation.value && !_ctx.m_select)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      size: "small",
                      circle: "",
                      onClick: ($event: any) => (append(data))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "plus" })
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_el_button, {
                      type: "primary",
                      size: "small",
                      circle: "",
                      onClick: ($event: any) => (modify(data))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "pen" })
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_el_button, {
                      type: "danger",
                      size: "small",
                      circle: "",
                      onClick: ($event: any) => (remove(data,node))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "trash-can" })
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]))
                : _createCommentVNode("", true)
            ], 40, _hoisted_1)
          ]),
          _: 1
        }, 8, ["data", "draggable", "show-checkbox", "default-expand-all", "accordion"]),
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "overlay-back-text" }, null, -1)),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_el_text, {
            size: "large",
            tag: "b",
            type: "primary"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('knowledge.title')), 1)
            ]),
            _: 1
          }),
          (!_ctx.m_select)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createVNode(_component_el_button, {
                  type: "primary",
                  disabled: !addStation.value,
                  round: "",
                  onClick: addRoot,
                  size: "small"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('knowledge.add-knowledge-lib')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: refreshTree,
                  size: "small",
                  circle: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, { icon: "arrow-rotate-right" })
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["height"]),
    _createVNode(_component_el_dialog, {
      modelValue: modifyDialog.value,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((modifyDialog).value = $event)),
      width: "860px",
      "align-center": "",
      center: "",
      draggable: "",
      "close-on-click-modal": false,
      "show-close": false
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.modify-node-title')), 1),
          _createVNode(_component_el_button, {
            type: "danger",
            onClick: closeDialog,
            circle: "",
            size: "small"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "xmark" })
            ]),
            _: 1
          })
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: save_modify_node
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: "check",
                class: "icon-text-button"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('message.save')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "danger",
            onClick: closeDialog,
            plain: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: "xmark",
                class: "icon-text-button"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('message.cancel')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          "label-width": "90px",
          "label-position": 'right'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('message.node-name'),
              prop: "node_name",
              class: "item"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: modifyName.value,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((modifyName).value = $event)),
                  placeholder: _ctx.$t('message.node-name-tip'),
                  clearable: "",
                  "show-word-limit": "",
                  maxlength: "50"
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('message.permission_label'),
              prop: "permission_label",
              class: "item"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_transfer, {
                  modelValue: permission_value.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((permission_value).value = $event)),
                  data: person_data.value,
                  props: {key: 'label_id', label: 'label_name'},
                  "target-order": "unshift",
                  filterable: "",
                  titles: [_ctx.$t('user.user-label'), _ctx.$t('message.node-permission')],
                  format: {noChecked: '${total}', hasChecked: '${checked}/${total}'}
                }, null, 8, ["modelValue", "data", "titles"]),
                _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t("message.modify-node-tips")), 1),
                _createVNode(_component_el_row, {
                  gutter: 20,
                  style: {"height":"auto","margin-top":"0"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      span: 12,
                      class: "right-txt"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_switch, {
                          modelValue: add_child_label.value,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((add_child_label).value = $event)),
                          "inline-prompt": "",
                          "active-text": _ctx.$t('message.yes'),
                          "inactive-text": _ctx.$t('message.no')
                        }, null, 8, ["modelValue", "active-text", "inactive-text"]),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.modify-add-child')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_col, {
                      span: 12,
                      class: "right-txt"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_switch, {
                          modelValue: del_child_label.value,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((del_child_label).value = $event)),
                          "inline-prompt": "",
                          "active-text": _ctx.$t('message.yes'),
                          "inactive-text": _ctx.$t('message.no')
                        }, null, 8, ["modelValue", "active-text", "inactive-text"]),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.modify-del-child')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})