import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, withKeys as _withKeys, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "dialog-header" }
const _hoisted_2 = { class: "api-key" }
const _hoisted_3 = { style: {"display":"flex","justify-content":"space-between","width":"600px"} }
const _hoisted_4 = { style: {"width":"100%"} }
const _hoisted_5 = {
  key: 0,
  style: {"margin-top":"10px"}
}
const _hoisted_6 = { style: {"width":"100%"} }
const _hoisted_7 = {
  key: 0,
  style: {"margin-top":"10px"}
}
const _hoisted_8 = { style: {"width":"100%","display":"flex","justify-content":"flex-start"} }
const _hoisted_9 = { class: "title_text" }
const _hoisted_10 = { class: "tags-row" }
const _hoisted_11 = { class: "truncate-tag" }
const _hoisted_12 = { class: "title_text" }
const _hoisted_13 = { class: "tags-row" }
const _hoisted_14 = { class: "truncate-tag" }
const _hoisted_15 = { class: "dialog-header" }
const _hoisted_16 = { style: {"margin-right":"6px"} }
const _hoisted_17 = { style: {"width":"100%"} }
const _hoisted_18 = { class: "title_text" }
const _hoisted_19 = { class: "tags-row" }
const _hoisted_20 = { class: "truncate-tag" }
const _hoisted_21 = { class: "title_text" }
const _hoisted_22 = { class: "tags-row" }
const _hoisted_23 = { class: "truncate-tag" }

import {computed, nextTick, onMounted, reactive, ref, watch} from "vue" // 导入 Vue 的核心功能
import {useI18n} from "vue-i18n" // 导入国际化插件
import {ElTable, FormInstance} from 'element-plus' // 导入 Element Plus 的表单和上传组件
import {API} from "@/types/API" // 导入 User 类型定义
import {useApiStore} from "@/stores/apiStore" // 导入用户存储
import {formatDate, generateSecureApiKey, showLoading, showNotification} from "@/services/tools"
import {TreeNode} from "@/types/system";
import {Robot} from "@/types/Robot"; // 导入工具函数
import {Topic} from "@/types/Topic"
import {useRobotStore} from "@/stores/robotStore";
import {useTopicStore} from "@/stores/topicStore"
import {faCheck, faCopy} from "@fortawesome/free-solid-svg-icons"
import TreeList from "@/components/TreeList.vue";
import {KnowledgeFile} from "@/types/KnowledgeFile";
import {useFileStore} from "@/stores/fileStore";
import {get_nodes_name} from "@/services/systemService";

interface Node {
  id: number;
  label: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'NewAPI',
  props: { // 定义 props
  isShow: {
    type: Boolean,
    default: false
  },
  api_info: {
    type: Object as () => API,
    default: null
  }
},
  emits: ["refreshList", "update:isShow"],
  setup(__props, { emit: __emit }) {

const fileTable = ref<InstanceType<typeof ElTable> | null>(null);
const robot_options = ref<Robot[]>([])
const robot = ref<Robot>({} as Robot)
const robot_desc = ref<string>('')
const robotStore = useRobotStore()

const topic_options = ref<Topic[]>([])
const topic = ref<Topic>({} as Topic)
const topic_desc = ref<string>('')
const topicStore = useTopicStore()


// 使用用户存储和国际化
const apiStore = useApiStore() // 使用用户存储
const {t} = useI18n() // 使用国际化

// 响应式状态变量
let isDragging = false; // 是否正在拖拽
let startX = 0; // 鼠标起始位置
let startLeftColSpan = 8; // 拖拽前的左侧宽度
const leftColSpan = ref<number>(8);
const selectedNodes = ref<Node[]>([])
const selectedFiles = ref<KnowledgeFile[]>([]);
const treeListRef = ref<InstanceType<typeof TreeList> | null>(null);
const cur_node_id = ref(-1);  // 当前选中的节点ID
const cur_node_name = ref(""); // 当前选中的节点名称
const currentPage = ref(1);  // 当前页码
const pageSize = ref(10);  // 每页显示条目数
const totalItems = ref(0);  // 总条目数
const tData = ref<KnowledgeFile[]>([]);  // 表格数据
const fileStore = useFileStore();
const searchData = reactive({
  keyword: '',  // 搜索关键字
  all: true,
  text: "-1",
  graph: "-1",
  vectorization: "-1"
});

const buttonIcon = ref(faCopy)
const showKnowledge = ref(false)
const newApiMessage = ref<string>('') // 新模板信息提示
const apiForm = ref<FormInstance>() // 模板表单实例
const apiDialog = ref(false) // 创建对话框显示状态
const apiconfig = ref<API>({} as API) // 创建用户对象
const node_ids = ref<number[]>([])

const apiData = ref({ // 模板数据对象
  name: '',
  description: '',
  api_path: '',
  api_key: '',
  llm_type: 'main_llm',
  output_json: false,
  knowledge_node_ids: [] as number[],
  knowledge_file_ids: [] as number[],
  robot_id: -1,
  topic_id: -1,
  publish: false
})
// 初始化模板数据
const defaultApiData = {
  name: '',
  description: '',
  api_path: '',
  api_key: '',
  llm_type: 'main_llm',
  output_json: false,
  knowledge_node_ids: [] as number[],
  knowledge_file_ids: [] as number[],
  robot_id: -1,
  topic_id: -1,
  publish: false
}
// 定义一个默认的Robot对象
const defaultRobot: Robot = {
  rb_id: -1,
  name: t('message.select'),
  description: '',
  system_role_content: '',
  command: '',
  output_parser: '',
  publish: false,
  create_datetime: null,
  modify_datetime: null
};
// 定义一个默认的Topic对象
const defaultTopic: Topic = {
  tp_id: -1,
  name: t('message.select'),
  description: '',
  create_datetime: null,
  modify_datetime: null
};

// 定义组件的 props 和 emits
const props = __props
const emit = __emit // 定义 emits

// 监听 props.isShow 的变化并初始化相关状态
watch(() => props.isShow, (val) => {
  apiDialog.value = val // 设置对话框显示状态
  newApiMessage.value = "" // 清空提示信息
}, {immediate: true}) // 立即执行

// 监听 props.api_info 的变化并初始化相关状态
watch(() => props.api_info, (newVal) => {
  if (newVal) {
    apiconfig.value = newVal;
    ini_form(apiconfig.value)
  } else {
    apiconfig.value = {} as API;
  }
}, {immediate: true}) // 立即执行

// 表单验证规则
const apiRules = computed(() => ({
  name: [{required: true, max: 100, message: t('apiconfig.name-check'), trigger: 'blur'}], // 名称验证规则
  description: [{required: true, max: 200, message: t('apiconfig.description-check'), trigger: 'blur'}], // 描述验证规则
  api_path: [{required: true, max: 100, message: t('apiconfig.api_path-check'), trigger: 'blur'},
    {validator: validateApiPath, trigger: 'blur'}]
}))

const validateApiPath = (rule: any, value: string, callback: any) => {
  // 移除 http:// 或 https://
  const cleanPath = value.replace(/^https?:\/\//, "");
  apiData.value.api_path = cleanPath; // 直接修改 v-model

  // 正则校验路径格式（必须以 `/` 开头，只允许字母、数字、-、_ 和 /）
  const isValid = /^\/[a-zA-Z0-9\-_/]*$/.test(cleanPath);

  if (!isValid) {
    callback(new Error(t('apiconfig.api_path-check-validator')));
  } else {
    callback(); // 校验通过
  }
};

// 实用函数：刷新父列表
const refreshFatherList = () => {
  try {
    emit("refreshList") // 触发 refreshList 事件
  } catch (error) {
    console.error('Error refreshing father list:', error) // 打印错误信息
  }
}

// 关闭对话框并重置表单
const closeDialog = async () => {
  try {
    apiDialog.value = false // 隐藏对话框
    newApiMessage.value = "" // 清空提示信息
    selectedFiles.value.length = 0
    selectedNodes.value.length = 0
    apiData.value.api_key = generateSecureApiKey();
    refreshFatherList()
    emit('update:isShow', false) // 触发 update:isShow 事件

  } catch (error) {
    console.error('Error closing dialog:', error) // 打印错误信息
  }
}

const searchFiles = async (searchData: any) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await fileStore.getFiles(searchData, cur_node_id.value, 1, pageSize.value);
    tData.value = fileStore.knowledgeFileList
    await selectRows()
  } finally {
    loading.close();
  }
};

// 上传用户信息
const save_apiconfig = async (formEl: FormInstance | undefined) => {
  if (!formEl) return

  try {
    apiconfig.value.name = apiData.value.name // 设置模板名称
    apiconfig.value.description = apiData.value.description // 设置模板描述
    apiconfig.value.api_path = apiData.value.api_path // 设置模板系统角色内容
    apiconfig.value.api_key = apiData.value.api_key // 设置模板命令
    apiconfig.value.llm_type = apiData.value.llm_type // 设置模板输出解析器
    apiconfig.value.output_json = apiData.value.output_json
    apiconfig.value.publish = apiData.value.publish // 设置模板发布状态
    apiconfig.value.knowledge_node_ids = JSON.stringify(apiData.value.knowledge_node_ids)
    apiconfig.value.knowledge_file_ids = JSON.stringify(apiData.value.knowledge_file_ids)
    apiconfig.value.robot_id = apiData.value.robot_id
    apiconfig.value.topic_id = apiData.value.topic_id


    newApiMessage.value = "" // 清空提示信息
    try {
      const valid = await formEl.validate() // 验证表单

      if (valid) {
        await apiStore.createOrUpdateAPI(apiconfig.value)
        apiData.value = {...defaultApiData} //清空PromptData数据
        refreshFatherList() // 刷新父组件列表
        await closeDialog() // 关闭对话框
        showNotification('success', t('message.success'), t('apiconfig.new-success-info')) // 显示成功通知
      }
    } catch (error: any) {
      newApiMessage.value = "" // 清除错误提示
      let detail = "null"
      if (error.response) {
        detail = error.response.data.detail;
      }
      switch (detail) {
        case 'InvalidInput':
        case 'MissingApiId':
          newApiMessage.value = "传入参数无效";
          break;
        case 'APIHasExisted':
          newApiMessage.value = t('apiconfig.name-exist') // 显示用户名已存在提示
          break;
        case 'PathHasExisted':
          newApiMessage.value = t('apiconfig.api_path-exist') // 显示用户名已存在提示
          break;
        case 'APINotFound':
          newApiMessage.value = "更新的API找不到，可能已经被删除。" // 显示用户名已存在提示
          break;
        case 'null':
          break;
        default:
          console.log(error.response.data)
          showNotification('error', '未知错误', t('user.unknown-error'));
          break;
      }
    }

  } catch (error) {
    console.error('Failed to validate form:', error) // 打印错误信息
    newApiMessage.value = t('user.unknown-error') // 显示未知错误提示
  }
}

const load_file_name = async () => {
  await fileStore.getFilesByIds(apiData.value.knowledge_file_ids)
  selectedFiles.value = fileStore.knowledgeFileList
}

const load_node_name = async () => {
  const temp_nodes: TreeNode[] | null | undefined = await get_nodes_name(apiData.value.knowledge_node_ids)
  if (temp_nodes) {
    selectedNodes.value.length = 0
    temp_nodes.forEach((node: TreeNode) => {
      selectedNodes.value.push({id: node.id, label: node.node_name});
    });
  }
}

const ini_form = async (_api: API) => {
  if (_api) {
    apiData.value.name = _api.name // 设置模板名称
    apiData.value.description = _api.description || '' // 设置模板描述
    apiData.value.api_path = _api.api_path // 设置模板系统角色内容
    apiData.value.api_key = _api.api_key // 设置模板命令
    apiData.value.llm_type = _api.llm_type // 设置模板输出解析器
    apiData.value.publish = _api.publish // 设置模板发布状态
    apiData.value.output_json = _api.output_json
    apiData.value.knowledge_node_ids = _api.knowledge_node_ids ? JSON.parse(_api.knowledge_node_ids) : [];
    apiData.value.knowledge_file_ids = _api.knowledge_file_ids ? JSON.parse(_api.knowledge_file_ids) : [];
    apiData.value.robot_id = _api.robot_id || -1
    apiData.value.topic_id = _api.topic_id || -1
    node_ids.value = apiData.value.knowledge_node_ids


    await load_file_name()
    await load_node_name()

    if (_api.robot_id && _api.robot_id != -1) {
      await robotStore.getRobotById(_api.robot_id)
      robot.value = robotStore.robot
    } else {
      robot.value = defaultRobot
    }

    if (_api.topic_id && _api.topic_id != -1) {
      await topicStore.getTopicById(_api.topic_id)
      topic.value = topicStore.topic
    } else {
      topic.value = defaultTopic
    }

  }
}

// 获取选项数据的函数
const fetchOptionsRobot = async (robot_id: number) => {
  try {
    if (!robot_id) return
    if (robot_id == -1) {
      await robotStore.getRobotsSummary()
      robot_options.value = [defaultRobot, ...robotStore.robotSummary]
      apiData.value.robot_id = -1
      robot_desc.value = ""
      return
    } else {
      for (const item of robot_options.value) {
        if (item.rb_id == robot_id) {
          apiData.value.robot_id = robot_id
          robot_desc.value = item.description
          break
        }
      }
    }

  } catch (error) {
    console.error('Error fetching robot options:', error);
  }
}

// 获取选项数据的函数
const fetchOptionsTopic = async (topic_id: number) => {
  try {
    if (!topic_id) return
    if (topic_id == -1) {
      await topicStore.getTopicSummary()
      topic_options.value = [defaultTopic, ...topicStore.topicSummary]
      apiData.value.topic_id = -1
      topic_desc.value = ""
      return
    } else {
      for (const item of topic_options.value) {
        if (item.tp_id == topic_id) {
          apiData.value.topic_id = topic_id
          topic_desc.value = item.description
          break
        }
      }
    }

  } catch (error) {
    console.error('Error fetching topic options:', error);
  }
};

const copyToClipboard = async () => {
  try {
    await navigator.clipboard.writeText(apiData.value.api_key);
    buttonIcon.value = faCheck; // 复制成功后变成 "✓"

    // 1秒后恢复图标
    setTimeout(() => {
      buttonIcon.value = faCopy;
    }, 1000);
  } catch (err) {
    console.error("复制失败:", err);
  }
};

const refresh_api_key = async () => {
  apiData.value.api_key = generateSecureApiKey();
}

const showSelectKnowledge = async () => {
  showKnowledge.value = true
}

const openKnowledgeDialog = async () => {
  selectedNodes.value.length = 0
  selectedFiles.value.length = 0
  if (treeListRef.value) {
    treeListRef.value.refreshTree()
  }
  await selectRows()
}

const closeKnowledgeDialog = async () => {
  fileTable.value?.clearSelection()
  showKnowledge.value = false
}

const onMouseDown = (event: MouseEvent) => {
  isDragging = true;
  startX = event.clientX;
  startLeftColSpan = leftColSpan.value;

  // 添加鼠标事件监听
  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', onMouseUp);
};

const onMouseMove = (event: MouseEvent) => {
  if (isDragging) {
    const delta = event.clientX - startX; // 鼠标移动的距离
    const totalWidth = document.body.clientWidth; // 获取整个页面宽度
    const spanDelta = Math.round((delta / totalWidth) * 24); // 计算移动的 span 值

    // 更新左侧列的宽度，限制在 1 到 23 之间
    leftColSpan.value = Math.min(12, Math.max(4, startLeftColSpan + spanDelta));
  }
};

const onMouseUp = () => {
  isDragging = false;

  // 移除事件监听
  document.removeEventListener('mousemove', onMouseMove);
  document.removeEventListener('mouseup', onMouseUp);
};

const handleSelectedNodeChange = async (node: any) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    cur_node_id.value = node.data.id;
    cur_node_name.value = node.data.label;
    searchData.all = false;
    totalItems.value = await fileStore.getFiles(searchData, cur_node_id.value, 1, pageSize.value);
    currentPage.value = 1;
    tData.value = fileStore.knowledgeFileList;
  } finally {
    loading.close();
  }
};

const handleSelectionChange = (files: KnowledgeFile[], file: KnowledgeFile) => {

  const isSelected = files.some(item => item.file_id === file.file_id);


  if (!isSelected) {
    delFiles(file)


  } else {
    selectedFiles.value.push(file)
    // 去重：基于 `file_id` 生成 Map
    selectedFiles.value = Array.from(new Map(selectedFiles.value.map(item => [item.file_id, item])).values());

    const fileIds = new Set([
      ...selectedFiles.value.map(item => item.file_id).filter(id => id !== undefined) // 只取有效的 file_id
    ]);

    // 转回数组
    apiData.value.knowledge_file_ids = Array.from(fileIds);
  }


};


/**
 * 处理每页显示条目数变化
 * @param {number} val - 新的每页显示条目数
 */
const handleSizeChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await fileStore.getFiles(searchData, cur_node_id.value, currentPage.value, val);
    pageSize.value = val;
    tData.value = fileStore.knowledgeFileList;
    await selectRows()
  } finally {
    loading.close();
  }
};

const handleCurrentChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await fileStore.getFiles(searchData, cur_node_id.value, val, pageSize.value);
    currentPage.value = val;
    tData.value = fileStore.knowledgeFileList;
    await selectRows()
  } finally {
    loading.close();
  }
};

const selectRows = async () => {
  if (fileTable.value) {
    await nextTick(); // 确保数据已渲染
    if (selectedFiles.value && apiData.value.knowledge_file_ids.length == 0) {
      selectedFiles.value.forEach((selectedRow) => {
        const matchedRow = tData.value.find(row => row.file_id === selectedRow.file_id);
        if (matchedRow) {
          fileTable.value!.toggleRowSelection(matchedRow, true)
        }
      })
    } else {
      if (selectedFiles.value.length == 0) {
        await load_file_name()
      }
      apiData.value.knowledge_file_ids.forEach(id => {
        const matchedRow = tData.value.find(row => row.file_id === id);
        if (matchedRow) {
          fileTable.value!.toggleRowSelection(matchedRow, true)
        }
      })
    }
  }
}

const getKnowledgeNodesAndFiles = async () => {
  if (treeListRef.value) {
    apiData.value.knowledge_node_ids = treeListRef.value.getSelectedItems()
    apiData.value.knowledge_file_ids = apiData.value.knowledge_file_ids.filter(num => num !== -1);
    await closeKnowledgeDialog()
  }
}

const delNodes = async (id: number) => {
  node_ids.value = node_ids.value.filter(item => item !== id);
  apiData.value.knowledge_node_ids = node_ids.value;
  treeListRef.value?.setSelectedItems(node_ids.value)
}

const delFiles = async (file: KnowledgeFile | undefined) => {
  if (!file) return

  selectedFiles.value = selectedFiles.value.filter(item => item.file_id !== file.file_id);
  if (fileTable.value) {
    const matchedRow = tData.value.find(row => row.file_id === file.file_id);
    if (matchedRow) {
      fileTable.value!.toggleRowSelection(matchedRow, false)
    }
  }
  apiData.value.knowledge_file_ids = Array.from(
      new Set(
          selectedFiles.value
              .map(item => item.file_id)
              .filter((id): id is number => id !== undefined) // 过滤 undefined，确保类型是 number[]
      )
  );

  await selectRows()
}

const handleNodeChange = async (newIds: number[], nodes: Node[]) => {
  node_ids.value = newIds
  selectedNodes.value = nodes
  apiData.value.knowledge_node_ids = node_ids.value;
}
const ini_dialog = async () => {
  await fetchOptionsRobot(-1)
  await fetchOptionsTopic(-1)
}
// 组件挂载时调用
onMounted(async () => {
  apiData.value.api_key = generateSecureApiKey();

  document.removeEventListener('mousemove', onMouseMove);
  document.removeEventListener('mouseup', onMouseUp);
  const ps_key = localStorage.getItem("file_page_size");
  searchData.all = true;

  if (ps_key) {
    pageSize.value = Number(ps_key);
  }
  await handleCurrentChange(1);  // 初始化加载第一页数据
});

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: apiDialog.value,
    "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((apiDialog).value = $event)),
    width: "1100px",
    onOpen: ini_dialog,
    "align-center": "",
    center: "",
    draggable: "",
    "close-on-click-modal": false,
    "show-close": false
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('message.menu-51')), 1),
        _createVNode(_component_el_button, {
          type: "danger",
          onClick: closeDialog,
          circle: "",
          size: "small"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, { icon: "xmark" })
          ]),
          _: 1
        })
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[21] || (_cache[21] = ($event: any) => (save_apiconfig(apiForm.value)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "check",
              class: "icon-text-button"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('message.save')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "danger",
          onClick: closeDialog,
          plain: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "xmark",
              class: "icon-text-button"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('message.cancel')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref_key: "apiForm",
        ref: apiForm,
        model: apiData.value,
        rules: apiRules.value,
        "label-width": "90px",
        "label-position": 'right'
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              (newApiMessage.value)
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 0,
                    type: "warning",
                    description: newApiMessage.value,
                    closable: false,
                    "show-icon": "",
                    effect: 'light',
                    class: "reg-alert"
                  }, null, 8, ["description"]))
                : _createCommentVNode("", true),
              _createVNode(_component_el_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_el_form_item, {
                          label: _ctx.$t('apiconfig.api_key'),
                          prop: "api_key"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_3, [
                              _createVNode(_component_el_text, {
                                modelValue: apiData.value.api_key,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((apiData.value.api_key) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(apiData.value.api_key), 1)
                                ]),
                                _: 1
                              }, 8, ["modelValue"]),
                              _createElementVNode("div", null, [
                                _createVNode(_component_el_button, {
                                  type: "primary",
                                  size: "small",
                                  circle: "",
                                  plain: "",
                                  onClick: copyToClipboard,
                                  style: {"margin-left":"10px"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_font_awesome_icon, { icon: buttonIcon.value }, null, 8, ["icon"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_el_button, {
                                  type: "primary",
                                  size: "small",
                                  circle: "",
                                  plain: "",
                                  onClick: refresh_api_key,
                                  style: {"margin-left":"10px"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_font_awesome_icon, { icon: "arrows-rotate" })
                                  ]),
                                  _: 1
                                })
                              ])
                            ])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('apiconfig.name'),
                      prop: "name"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: apiData.value.name,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((apiData.value.name) = $event)),
                          placeholder: _ctx.$t('apiconfig.name-tip'),
                          clearable: "",
                          "show-word-limit": "",
                          maxlength: "100",
                          style: {"width":"100%"}
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('apiconfig.description'),
                      prop: "description"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: apiData.value.description,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((apiData.value.description) = $event)),
                          placeholder: _ctx.$t('apiconfig.description-tip'),
                          clearable: "",
                          type: "textarea",
                          rows: 3,
                          "show-word-limit": "",
                          maxlength: "200"
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('message.robot'),
                      prop: "robot_desc"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: robot.value,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((robot).value = $event)),
                          "value-key": "rb_id",
                          style: {"width":"100%"},
                          change: fetchOptionsRobot(Number(robot.value.rb_id))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(robot_options.value, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.rb_id,
                                label: item.name,
                                value: item
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "change"]),
                        _createVNode(_component_el_text, null, {
                          default: _withCtx(() => [
                            (robot_desc.value)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                  _createVNode(_component_el_text, { class: "multi-line-text" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(robot_desc.value), 1)
                                    ]),
                                    _: 1
                                  })
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('apiconfig.api_path'),
                      prop: "api_path"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: apiData.value.api_path,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((apiData.value.api_path) = $event)),
                          placeholder: _ctx.$t('apiconfig.api_path-tip'),
                          clearable: "",
                          "show-word-limit": "",
                          maxlength: "100"
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('apiconfig.llm_type'),
                      prop: "llm_type",
                      style: {"height":"28px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_radio_group, {
                          modelValue: apiData.value.llm_type,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((apiData.value.llm_type) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_radio, { value: "main_llm" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('chat.main-llm')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_radio, { value: "small_llm" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('chat.small-llm')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_radio, { value: "logic_llm" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('chat.logic-llm')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_row, { style: {"width":"100%"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, { span: 12 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: _ctx.$t('apiconfig.output_json'),
                              prop: "output_json",
                              style: {"height":"25px"}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_switch, {
                                  modelValue: apiData.value.output_json,
                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((apiData.value.output_json) = $event)),
                                  "inline-prompt": "",
                                  "active-text": _ctx.$t('message.yes'),
                                  "inactive-text": _ctx.$t('message.no')
                                }, null, 8, ["modelValue", "active-text", "inactive-text"])
                              ]),
                              _: 1
                            }, 8, ["label"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_col, { span: 12 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: _ctx.$t('message.publish'),
                              prop: "publish",
                              style: {"height":"25px"}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_switch, {
                                  modelValue: apiData.value.publish,
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((apiData.value.publish) = $event)),
                                  "inline-prompt": "",
                                  "active-text": _ctx.$t('message.yes'),
                                  "inactive-text": _ctx.$t('message.no')
                                }, null, 8, ["modelValue", "active-text", "inactive-text"])
                              ]),
                              _: 1
                            }, 8, ["label"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('message.menu-13'),
                      prop: "topic_desc"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: topic.value,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((topic).value = $event)),
                          "value-key": "tp_id",
                          style: {"width":"100%"},
                          change: fetchOptionsTopic(Number(topic.value.tp_id))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(topic_options.value, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.tp_id,
                                label: item.name,
                                value: item
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "change"]),
                        _createVNode(_component_el_text, null, {
                          default: _withCtx(() => [
                            (topic_desc.value)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                  _createVNode(_component_el_text, { class: "multi-line-text" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(topic_desc.value), 1)
                                    ]),
                                    _: 1
                                  })
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_el_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_el_form_item, {
                          label: _ctx.$t('message.menu-4'),
                          prop: "apiData.knowledge_node_ids",
                          style: {"height":"25px"}
                        }, {
                          default: _withCtx(() => [
                            _withDirectives(_createElementVNode("input", {
                              type: "hidden",
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((apiData.value.knowledge_node_ids) = $event))
                            }, null, 512), [
                              [_vModelText, apiData.value.knowledge_node_ids]
                            ]),
                            _withDirectives(_createElementVNode("input", {
                              type: "hidden",
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((apiData.value.knowledge_file_ids) = $event))
                            }, null, 512), [
                              [_vModelText, apiData.value.knowledge_file_ids]
                            ]),
                            _createVNode(_component_el_button, {
                              onClick: showSelectKnowledge,
                              round: "",
                              type: "primary"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('apiconfig.select-knowledge') + _ctx.$t('message.menu-4')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_row, {
                    gutter: 20,
                    style: {"display":"flex","align-items":"flex-start"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, {
                        span: 11,
                        class: "display-card"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_divider, { "content-position": "left" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('apiconfig.knowledge-node-ids')), 1)
                            ]),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_10, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedNodes.value, (tag) => {
                              return (_openBlock(), _createBlock(_component_el_tag, {
                                key: tag.id,
                                round: "",
                                effect: "plain"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_11, _toDisplayString(tag.label), 1)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_col, {
                        span: 12,
                        class: "display-card"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_divider, { "content-position": "left" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('apiconfig.knowledge-file-ids')), 1)
                            ]),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_13, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedFiles.value, (tag) => {
                              return (_openBlock(), _createBlock(_component_el_tag, {
                                key: tag.file_id,
                                round: "",
                                effect: "plain"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_14, _toDisplayString(tag.name), 1)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"]),
      _createVNode(_component_el_dialog, {
        modelValue: showKnowledge.value,
        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((showKnowledge).value = $event)),
        width: "1200px",
        style: {"min-width":"1200px"},
        onOpen: openKnowledgeDialog,
        "append-to-body": "",
        center: "",
        draggable: "",
        "align-center": "",
        "close-on-click-modal": false,
        "show-close": false
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('apiconfig.select-knowledge') + _ctx.$t('message.menu-4')), 1),
            _createVNode(_component_el_button, {
              type: "danger",
              onClick: closeKnowledgeDialog,
              circle: "",
              size: "small"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, { icon: "xmark" })
              ]),
              _: 1
            })
          ])
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: getKnowledgeNodesAndFiles
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "check",
                  class: "icon-text-button"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('message.confirm')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              type: "danger",
              onClick: closeKnowledgeDialog,
              plain: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "xmark",
                  class: "icon-text-button"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('message.cancel')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_row, { class: "top-row" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { span: 23 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form, {
                    ref: "searchForm",
                    model: searchData,
                    inline: true,
                    class: "search-form",
                    onSubmit: _cache[15] || (_cache[15] = _withModifiers(() => {}, ["prevent"]))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        prop: "searchAll",
                        class: "top-input"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(t)("knowledge.all")), 1),
                          _createVNode(_component_el_switch, {
                            modelValue: searchData.all,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((searchData.all) = $event)),
                            "inline-prompt": "",
                            "active-text": _ctx.$t('message.yes'),
                            "inactive-text": _ctx.$t('message.no')
                          }, null, 8, ["modelValue", "active-text", "inactive-text"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_divider, {
                        direction: "vertical",
                        class: "set-color"
                      }),
                      _createVNode(_component_el_form_item, {
                        prop: "searchKey",
                        class: "top-input"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: searchData.keyword,
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((searchData.keyword) = $event)),
                            placeholder: _ctx.$t('message.search-key'),
                            onKeyup: _cache[13] || (_cache[13] = _withKeys(($event: any) => (searchFiles(searchData)), ["enter"])),
                            style: {"width":"800px"},
                            clearable: ""
                          }, null, 8, ["modelValue", "placeholder"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_button, {
                        type: "primary",
                        onClick: _cache[14] || (_cache[14] = ($event: any) => (searchFiles(searchData))),
                        circle: "",
                        plain: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_font_awesome_icon, { icon: "magnifying-glass" })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["model"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, { class: "resizable-row" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                span: leftColSpan.value,
                class: "left-col"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(TreeList, {
                      ref_key: "treeListRef",
                      ref: treeListRef,
                      treeName: "file",
                      node_ids: node_ids.value,
                      m_select: true,
                      onSelectedNodeChanged: handleSelectedNodeChange,
                      onUpdateNodeIds: handleNodeChange
                    }, null, 8, ["node_ids"])
                  ])
                ]),
                _: 1
              }, 8, ["span"]),
              _createVNode(_component_el_divider, {
                direction: "vertical",
                class: "divider",
                onMousedown: onMouseDown
              }),
              _createVNode(_component_el_col, {
                span: 23-leftColSpan.value,
                class: "right-col"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(ElTable), {
                    ref_key: "fileTable",
                    ref: fileTable,
                    data: tData.value,
                    "row-key": "file_id",
                    "highlight-current-row": "",
                    height: 350,
                    onSelect: handleSelectionChange
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_table_column, {
                        fixed: "left",
                        type: "selection",
                        width: "35"
                      }),
                      _createVNode(_component_el_table_column, {
                        prop: "name",
                        label: _ctx.$t('knowledge.file-name'),
                        sortable: "",
                        "min-width": "200"
                      }, {
                        default: _withCtx(({ row }) => [
                          _createVNode(_component_el_text, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(row.name), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_el_table_column, {
                        prop: "version",
                        label: _ctx.$t('knowledge.file-version'),
                        width: "80",
                        align: "center",
                        sortable: ""
                      }, {
                        default: _withCtx(({ row }) => [
                          _createVNode(_component_el_text, {
                            size: "default",
                            type: "primary"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("V " + _toDisplayString(String(row.version).padStart(2, '0')), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_el_table_column, {
                        prop: "modify_datetime",
                        label: _ctx.$t('knowledge.upload-date'),
                        width: "150",
                        align: "center",
                        sortable: ""
                      }, {
                        default: _withCtx(({ row }) => [
                          _createTextVNode(_toDisplayString(_unref(formatDate)(_unref(t),row.modify_datetime, 'yyyy-MM-dd HH:mm')), 1)
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }, 8, ["data"]),
                  _createVNode(_component_el_pagination, {
                    "current-page": currentPage.value,
                    "onUpdate:currentPage": _cache[16] || (_cache[16] = ($event: any) => ((currentPage).value = $event)),
                    "page-size": pageSize.value,
                    "onUpdate:pageSize": _cache[17] || (_cache[17] = ($event: any) => ((pageSize).value = $event)),
                    "page-sizes": [20, 50,100,500],
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: totalItems.value,
                    onSizeChange: _cache[18] || (_cache[18] = ($event: any) => (handleSizeChange(pageSize.value))),
                    onCurrentChange: _cache[19] || (_cache[19] = ($event: any) => (handleCurrentChange(currentPage.value))),
                    style: {"justify-content":"center"}
                  }, null, 8, ["current-page", "page-size", "total"])
                ]),
                _: 1
              }, 8, ["span"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, {
            gutter: 20,
            style: {"display":"flex","align-items":"flex-start"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                span: 11,
                class: "display-card"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_divider, { "content-position": "left" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('apiconfig.knowledge-node-ids')), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_19, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedNodes.value, (tag) => {
                      return (_openBlock(), _createBlock(_component_el_tag, {
                        key: tag.id,
                        closable: "",
                        round: "",
                        effect: "dark",
                        "disable-transitions": false,
                        onClose: ($event: any) => (delNodes(tag.id))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_20, _toDisplayString(tag.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClose"]))
                    }), 128))
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                span: 12,
                class: "display-card"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_divider, { "content-position": "left" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t('apiconfig.knowledge-file-ids')), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_22, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedFiles.value, (tag) => {
                      return (_openBlock(), _createBlock(_component_el_tag, {
                        key: tag.file_id,
                        closable: "",
                        round: "",
                        effect: "dark",
                        "disable-transitions": false,
                        onClose: ($event: any) => (delFiles(tag))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_23, _toDisplayString(tag.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClose"]))
                    }), 128))
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})