<script setup lang="ts">
import {computed, ref, watch} from "vue" // 导入 Vue 的核心功能
import {useI18n} from "vue-i18n" // 导入国际化插件
import {FormInstance} from 'element-plus' // 导入 Element Plus 的表单和上传组件
import {Robot} from "@/types/Robot" // 导入 User 类型定义
import {useRobotStore} from "@/stores/robotStore" // 导入用户存储
import {showNotification} from "@/services/tools"
import {Prompt} from "@/types/Prompt"; // 导入工具函数

// 使用用户存储和国际化
const robotStore = useRobotStore() // 使用用户存储
const {t} = useI18n() // 使用国际化

// 响应式状态变量
const newRobotMessage = ref<string>('') // 新模板信息提示
const robotForm = ref<FormInstance>() // 模板表单实例
const robotDialog = ref(false) // 创建对话框显示状态
const robot = ref<Robot>({} as Robot) // 创建用户对象
const RobotData = ref({ // 模板数据对象
  name: '',
  description: '',
  system_role_content: '',
  command: '',
  output_parser: '',
  publish: false,
  prompts: [
    {
      prompt_id: -1,
      rb_id: -1,
      name: '',
      description: '',
      command: '',
      publish: false,
      modify_datetime: null,
      create_datetime: null
    } as Prompt  // 初始时包含一个空项目
  ]
})
// 初始化模板数据
const defaultRobotData = {
  name: '',
  description: '',
  system_role_content: '',
  command: '',
  output_parser: '',
  publish: false,
  prompts: [
    {
      prompt_id: -1,
      rb_id: -1,
      name: '',
      description: '',
      command: '',
      publish: false,
      modify_datetime: null,
      create_datetime: null
    } as Prompt  // 初始时包含一个空项目
  ]
}

// 定义组件的 props 和 emits
const props = defineProps({ // 定义 props
  isShow: {
    type: Boolean,
    default: false
  },
  robot_info: {
    type: Object as () => Robot,
    default: null
  }
})
const emit = defineEmits(["refreshList", "update:isShow"]) // 定义 emits

// 监听 props.isShow 的变化并初始化相关状态
watch(() => props.isShow, (val) => {
  robotDialog.value = val // 设置对话框显示状态
  newRobotMessage.value = "" // 清空提示信息
}, {immediate: true}) // 立即执行

// 监听 props.robot_info 的变化并初始化相关状态
watch(() => props.robot_info, (newVal) => {
  if (newVal) {
    robot.value = newVal;
    ini_form(robot.value)
  } else {
    robot.value = {} as Robot;
  }
}, {immediate: true}) // 立即执行

// 表单验证规则
const robotRules = computed(() => ({
  name: [{required: true, max: 50, message: t('robot.name-check'), trigger: 'blur'}], // 名称验证规则
  description: [{required: true, max: 200, message: t('robot.description-check'), trigger: 'blur'}], // 描述验证规则
  system_role_content: [{required: true, max: 1000, message: t('robot.system_role_content-check'), trigger: 'blur'}]
}))

// 实用函数：刷新父列表
const refreshFatherList = () => {
  try {
    emit("refreshList") // 触发 refreshList 事件
  } catch (error) {
    console.error('Error refreshing father list:', error) // 打印错误信息
  }
}

// 关闭对话框并重置表单
const closeDialog = async () => {
  try {
    robotDialog.value = false // 隐藏对话框
    newRobotMessage.value = "" // 清空提示信息
    refreshFatherList()
    emit('update:isShow', false) // 触发 update:isShow 事件
  } catch (error) {
    console.error('Error closing dialog:', error) // 打印错误信息
  }
}

// 上传用户信息
const save_robot = async (formEl: FormInstance | undefined) => {
  if (!formEl) return


  try {
    robot.value.name = RobotData.value.name // 设置模板名称
    robot.value.description = RobotData.value.description // 设置模板描述
    robot.value.system_role_content = RobotData.value.system_role_content // 设置模板系统角色内容
    robot.value.command = RobotData.value.command // 设置模板命令
    robot.value.output_parser = RobotData.value.output_parser // 设置模板输出解析器
    robot.value.publish = RobotData.value.publish // 设置模板发布状态


    newRobotMessage.value = "" // 清空提示信息
    try {
      const valid = await formEl.validate() // 验证表单

      if (valid) {
        await robotStore.createOrUpdateRobot(robot.value)
        RobotData.value = {...defaultRobotData} //清空PromptData数据
        refreshFatherList() // 刷新父组件列表
        await closeDialog() // 关闭对话框
        showNotification('success', t('message.success'), t('robot.new-success-info')) // 显示成功通知
      }
    } catch (error: any) {
      newRobotMessage.value = "" // 清除错误提示
      let detail = "null"
      if (error.response) {
        detail = error.response.data.detail;
      }
      switch (detail) {
        case 'InvalidInput':
          newRobotMessage.value = "传入参数无效";
          break;
        case 'RobotHasExisted':
          newRobotMessage.value = t('robot.name-exist') // 显示用户名已存在提示
          break;
        case 'null':
          break;
        default:
          console.log(error.response.data)
          showNotification('error', '未知错误', t('user.unknown-error'));
          break;
      }
    }

  } catch (error) {
    console.error('Failed to validate form:', error) // 打印错误信息
    newRobotMessage.value = t('user.unknown-error') // 显示未知错误提示
  }
}

const ini_form = async (robot: Robot) => {
  if (robot) {
    RobotData.value.name = robot.name || '' // 设置用户头像 URL
    RobotData.value.description = robot.description || ''// 设置用户昵称
    RobotData.value.command = robot.command || '' // 设置用户登录名
    RobotData.value.system_role_content = robot.system_role_content || '' // 设置用户密码
    RobotData.value.output_parser = robot.output_parser || ''// 设置用户手机
    RobotData.value.publish = robot.publish || false //设置管发布状态
    RobotData.value.prompts = robot.prompts || [{
      prompt_id: -1,
      rb_id: -1,
      name: '',
      description: '',
      command: '', // 添加 command 字段
      publish: false, // 添加 publish 字段
      modify_datetime: null,
      create_datetime: null
    }] // 设置项目
  }
}

</script>

<template>
  <el-dialog :visible="robotDialog" width="1100px"
             align-center center draggable :close-on-click-modal="false" :show-close="false">
    <template #header="">
      <div class="dialog-header">
        <span>{{ $t('robot.new-title') }}</span>
        <el-button type="danger" @click="closeDialog" circle size="small">
          <font-awesome-icon icon="xmark"/>
        </el-button>
      </div>
    </template>
    <el-form ref="robotForm" :model="RobotData" :rules="robotRules" label-width="80px" :label-position="'right'"
    >

      <el-row>
        <el-alert v-if="newRobotMessage" type="warning" :description="newRobotMessage" :closable="false" show-icon
                  :effect="'light'" class="reg-alert">
        </el-alert>

        <el-col :span="12">
          <div style="width: 100%;">
            <el-form-item :label="$t('robot.name')" prop="name">
              <el-input v-model="RobotData.name"
                        :placeholder="$t('robot.name-tip')"
                        clearable
                        show-word-limit
                        maxlength="50"
                        style="width: 100%;"></el-input>
            </el-form-item>
            <el-form-item :label="$t('robot.description')" prop="description">
              <el-input
                  v-model="RobotData.description"
                  :placeholder="$t('robot.description-tip')"
                  clearable
                  type="textarea"
                  :rows="4"
                  show-word-limit
                  maxlength="200"></el-input>
            </el-form-item>
            <el-form-item :label="$t('robot.system_role_content')" prop="system_role_content">
              <el-input v-model="RobotData.system_role_content"
                        :placeholder="$t('robot.system_role_content-tip')"
                        clearable
                        type="textarea"
                        :rows="4"
                        show-word-limit
                        maxlength="1000"></el-input>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="width: 100%;">
            <el-form-item :label="$t('message.publish')" prop="publish">
              <el-switch
                  v-model="RobotData.publish"
                  inline-prompt
                  :active-text="$t('message.yes')"
                  :inactive-text="$t('message.no')"
              />
            </el-form-item>
            <el-form-item :label="$t('robot.command')" prop="command">
              <el-input v-model="RobotData.command"
                        :placeholder="$t('robot.command-tip')"
                        clearable
                        type="textarea"
                        :rows="4"
                        show-word-limit
                        maxlength="1000"></el-input>
            </el-form-item>
            <el-form-item :label="$t('robot.output_parser')" prop="output_parser">
              <el-input v-model="RobotData.output_parser"
                        :placeholder="$t('robot.output_parser-tip')"
                        clearable
                        type="textarea"
                        :rows="4"
                        show-word-limit
                        maxlength="1000"></el-input>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <div>
        <el-button type="primary" @click="save_robot(robotForm)">
          <font-awesome-icon icon="check" class="icon-text-button"/>
          {{ $t('message.save') }}
        </el-button>
        <el-button type="danger" @click="closeDialog" plain>
          <font-awesome-icon icon="xmark" class="icon-text-button"/>
          {{ $t('message.cancel') }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

.div_right {
  justify-content: flex-end;
  width: 99%;
  display: flex;
}

.divider_text {
  font-weight: bold;
  color: $header-font-color-light;
}

/* 注册专用警告框样式 */
.reg-alert {
  min-height: 50px; /* 最小高度适用于较少内容 */
  margin: 5px 0 10px 0;
  padding: 0 30px 0 30px; /* 无顶部外边距 */
  width: 97%;
}

.div-row {
  height: 148px;
  width: 148px;
  display: flex; /* 使用 flex 布局以居中图片 */
  justify-content: center;
  align-items: center;
  margin-right: 5px;

}


</style>
