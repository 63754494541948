<script setup lang="ts">
import {computed, nextTick, onMounted, reactive, ref, watch} from "vue" // 导入 Vue 的核心功能
import {useI18n} from "vue-i18n" // 导入国际化插件
import {ElTable, FormInstance} from 'element-plus' // 导入 Element Plus 的表单和上传组件
import {API} from "@/types/API" // 导入 User 类型定义
import {useApiStore} from "@/stores/apiStore" // 导入用户存储
import {formatDate, generateSecureApiKey, showLoading, showNotification} from "@/services/tools"
import {TreeNode} from "@/types/system";
import {Robot} from "@/types/Robot"; // 导入工具函数
import {Topic} from "@/types/Topic"
import {useRobotStore} from "@/stores/robotStore";
import {useTopicStore} from "@/stores/topicStore"
import {faCheck, faCopy} from "@fortawesome/free-solid-svg-icons"
import TreeList from "@/components/TreeList.vue";
import {KnowledgeFile} from "@/types/KnowledgeFile";
import {useFileStore} from "@/stores/fileStore";
import {get_nodes_name} from "@/services/systemService";

const fileTable = ref<InstanceType<typeof ElTable> | null>(null);
const robot_options = ref<Robot[]>([])
const robot = ref<Robot>({} as Robot)
const robot_desc = ref<string>('')
const robotStore = useRobotStore()

const topic_options = ref<Topic[]>([])
const topic = ref<Topic>({} as Topic)
const topic_desc = ref<string>('')
const topicStore = useTopicStore()


// 使用用户存储和国际化
const apiStore = useApiStore() // 使用用户存储
const {t} = useI18n() // 使用国际化

// 响应式状态变量
interface Node {
  id: number;
  label: string;
}

let isDragging = false; // 是否正在拖拽
let startX = 0; // 鼠标起始位置
let startLeftColSpan = 8; // 拖拽前的左侧宽度
const leftColSpan = ref<number>(8);
const selectedNodes = ref<Node[]>([])
const selectedFiles = ref<KnowledgeFile[]>([]);
const treeListRef = ref<InstanceType<typeof TreeList> | null>(null);
const cur_node_id = ref(-1);  // 当前选中的节点ID
const cur_node_name = ref(""); // 当前选中的节点名称
const currentPage = ref(1);  // 当前页码
const pageSize = ref(10);  // 每页显示条目数
const totalItems = ref(0);  // 总条目数
const tData = ref<KnowledgeFile[]>([]);  // 表格数据
const fileStore = useFileStore();
const searchData = reactive({
  keyword: '',  // 搜索关键字
  all: true,
  text: "-1",
  graph: "-1",
  vectorization: "-1"
});

const buttonIcon = ref(faCopy)
const showKnowledge = ref(false)
const newApiMessage = ref<string>('') // 新模板信息提示
const apiForm = ref<FormInstance>() // 模板表单实例
const apiDialog = ref(false) // 创建对话框显示状态
const apiconfig = ref<API>({} as API) // 创建用户对象
const node_ids = ref<number[]>([])

const apiData = ref({ // 模板数据对象
  name: '',
  description: '',
  api_path: '',
  api_key: '',
  llm_type: 'main_llm',
  output_json: false,
  knowledge_node_ids: [] as number[],
  knowledge_file_ids: [] as number[],
  robot_id: -1,
  topic_id: -1,
  publish: false
})
// 初始化模板数据
const defaultApiData = {
  name: '',
  description: '',
  api_path: '',
  api_key: '',
  llm_type: 'main_llm',
  output_json: false,
  knowledge_node_ids: [] as number[],
  knowledge_file_ids: [] as number[],
  robot_id: -1,
  topic_id: -1,
  publish: false
}
// 定义一个默认的Robot对象
const defaultRobot: Robot = {
  rb_id: -1,
  name: t('message.select'),
  description: '',
  system_role_content: '',
  command: '',
  output_parser: '',
  publish: false,
  create_datetime: null,
  modify_datetime: null
};
// 定义一个默认的Topic对象
const defaultTopic: Topic = {
  tp_id: -1,
  name: t('message.select'),
  description: '',
  create_datetime: null,
  modify_datetime: null
};

// 定义组件的 props 和 emits
const props = defineProps({ // 定义 props
  isShow: {
    type: Boolean,
    default: false
  },
  api_info: {
    type: Object as () => API,
    default: null
  }
})
const emit = defineEmits(["refreshList", "update:isShow"]) // 定义 emits

// 监听 props.isShow 的变化并初始化相关状态
watch(() => props.isShow, (val) => {
  apiDialog.value = val // 设置对话框显示状态
  newApiMessage.value = "" // 清空提示信息
}, {immediate: true}) // 立即执行

// 监听 props.api_info 的变化并初始化相关状态
watch(() => props.api_info, (newVal) => {
  if (newVal) {
    apiconfig.value = newVal;
    ini_form(apiconfig.value)
  } else {
    apiconfig.value = {} as API;
  }
}, {immediate: true}) // 立即执行

// 表单验证规则
const apiRules = computed(() => ({
  name: [{required: true, max: 100, message: t('apiconfig.name-check'), trigger: 'blur'}], // 名称验证规则
  description: [{required: true, max: 200, message: t('apiconfig.description-check'), trigger: 'blur'}], // 描述验证规则
  api_path: [{required: true, max: 100, message: t('apiconfig.api_path-check'), trigger: 'blur'},
    {validator: validateApiPath, trigger: 'blur'}]
}))

const validateApiPath = (rule: any, value: string, callback: any) => {
  // 移除 http:// 或 https://
  const cleanPath = value.replace(/^https?:\/\//, "");
  apiData.value.api_path = cleanPath; // 直接修改 v-model

  // 正则校验路径格式（必须以 `/` 开头，只允许字母、数字、-、_ 和 /）
  const isValid = /^\/[a-zA-Z0-9\-_/]*$/.test(cleanPath);

  if (!isValid) {
    callback(new Error(t('apiconfig.api_path-check-validator')));
  } else {
    callback(); // 校验通过
  }
};

// 实用函数：刷新父列表
const refreshFatherList = () => {
  try {
    emit("refreshList") // 触发 refreshList 事件
  } catch (error) {
    console.error('Error refreshing father list:', error) // 打印错误信息
  }
}

// 关闭对话框并重置表单
const closeDialog = async () => {
  try {
    apiDialog.value = false // 隐藏对话框
    newApiMessage.value = "" // 清空提示信息
    selectedFiles.value.length = 0
    selectedNodes.value.length = 0
    apiData.value.api_key = generateSecureApiKey();
    refreshFatherList()
    emit('update:isShow', false) // 触发 update:isShow 事件

  } catch (error) {
    console.error('Error closing dialog:', error) // 打印错误信息
  }
}

const searchFiles = async (searchData: any) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await fileStore.getFiles(searchData, cur_node_id.value, 1, pageSize.value);
    tData.value = fileStore.knowledgeFileList
    await selectRows()
  } finally {
    loading.close();
  }
};

// 上传用户信息
const save_apiconfig = async (formEl: FormInstance | undefined) => {
  if (!formEl) return

  try {
    apiconfig.value.name = apiData.value.name // 设置模板名称
    apiconfig.value.description = apiData.value.description // 设置模板描述
    apiconfig.value.api_path = apiData.value.api_path // 设置模板系统角色内容
    apiconfig.value.api_key = apiData.value.api_key // 设置模板命令
    apiconfig.value.llm_type = apiData.value.llm_type // 设置模板输出解析器
    apiconfig.value.output_json = apiData.value.output_json
    apiconfig.value.publish = apiData.value.publish // 设置模板发布状态
    apiconfig.value.knowledge_node_ids = JSON.stringify(apiData.value.knowledge_node_ids)
    apiconfig.value.knowledge_file_ids = JSON.stringify(apiData.value.knowledge_file_ids)
    apiconfig.value.robot_id = apiData.value.robot_id
    apiconfig.value.topic_id = apiData.value.topic_id


    newApiMessage.value = "" // 清空提示信息
    try {
      const valid = await formEl.validate() // 验证表单

      if (valid) {
        await apiStore.createOrUpdateAPI(apiconfig.value)
        apiData.value = {...defaultApiData} //清空PromptData数据
        refreshFatherList() // 刷新父组件列表
        await closeDialog() // 关闭对话框
        showNotification('success', t('message.success'), t('apiconfig.new-success-info')) // 显示成功通知
      }
    } catch (error: any) {
      newApiMessage.value = "" // 清除错误提示
      let detail = "null"
      if (error.response) {
        detail = error.response.data.detail;
      }
      switch (detail) {
        case 'InvalidInput':
        case 'MissingApiId':
          newApiMessage.value = "传入参数无效";
          break;
        case 'APIHasExisted':
          newApiMessage.value = t('apiconfig.name-exist') // 显示用户名已存在提示
          break;
        case 'PathHasExisted':
          newApiMessage.value = t('apiconfig.api_path-exist') // 显示用户名已存在提示
          break;
        case 'APINotFound':
          newApiMessage.value = "更新的API找不到，可能已经被删除。" // 显示用户名已存在提示
          break;
        case 'null':
          break;
        default:
          console.log(error.response.data)
          showNotification('error', '未知错误', t('user.unknown-error'));
          break;
      }
    }

  } catch (error) {
    console.error('Failed to validate form:', error) // 打印错误信息
    newApiMessage.value = t('user.unknown-error') // 显示未知错误提示
  }
}

const load_file_name = async () => {
  await fileStore.getFilesByIds(apiData.value.knowledge_file_ids)
  selectedFiles.value = fileStore.knowledgeFileList
}

const load_node_name = async () => {
  const temp_nodes: TreeNode[] | null | undefined = await get_nodes_name(apiData.value.knowledge_node_ids)
  if (temp_nodes) {
    selectedNodes.value.length = 0
    temp_nodes.forEach((node: TreeNode) => {
      selectedNodes.value.push({id: node.id, label: node.node_name});
    });
  }
}

const ini_form = async (_api: API) => {
  if (_api) {
    apiData.value.name = _api.name // 设置模板名称
    apiData.value.description = _api.description || '' // 设置模板描述
    apiData.value.api_path = _api.api_path // 设置模板系统角色内容
    apiData.value.api_key = _api.api_key // 设置模板命令
    apiData.value.llm_type = _api.llm_type // 设置模板输出解析器
    apiData.value.publish = _api.publish // 设置模板发布状态
    apiData.value.output_json = _api.output_json
    apiData.value.knowledge_node_ids = _api.knowledge_node_ids ? JSON.parse(_api.knowledge_node_ids) : [];
    apiData.value.knowledge_file_ids = _api.knowledge_file_ids ? JSON.parse(_api.knowledge_file_ids) : [];
    apiData.value.robot_id = _api.robot_id || -1
    apiData.value.topic_id = _api.topic_id || -1
    node_ids.value = apiData.value.knowledge_node_ids


    await load_file_name()
    await load_node_name()

    if (_api.robot_id && _api.robot_id != -1) {
      await robotStore.getRobotById(_api.robot_id)
      robot.value = robotStore.robot
    } else {
      robot.value = defaultRobot
    }

    if (_api.topic_id && _api.topic_id != -1) {
      await topicStore.getTopicById(_api.topic_id)
      topic.value = topicStore.topic
    } else {
      topic.value = defaultTopic
    }

  }
}

// 获取选项数据的函数
const fetchOptionsRobot = async (robot_id: number) => {
  try {
    if (!robot_id) return
    if (robot_id == -1) {
      await robotStore.getRobotsSummary()
      robot_options.value = [defaultRobot, ...robotStore.robotSummary]
      apiData.value.robot_id = -1
      robot_desc.value = ""
      return
    } else {
      for (const item of robot_options.value) {
        if (item.rb_id == robot_id) {
          apiData.value.robot_id = robot_id
          robot_desc.value = item.description
          break
        }
      }
    }

  } catch (error) {
    console.error('Error fetching robot options:', error);
  }
}

// 获取选项数据的函数
const fetchOptionsTopic = async (topic_id: number) => {
  try {
    if (!topic_id) return
    if (topic_id == -1) {
      await topicStore.getTopicSummary()
      topic_options.value = [defaultTopic, ...topicStore.topicSummary]
      apiData.value.topic_id = -1
      topic_desc.value = ""
      return
    } else {
      for (const item of topic_options.value) {
        if (item.tp_id == topic_id) {
          apiData.value.topic_id = topic_id
          topic_desc.value = item.description
          break
        }
      }
    }

  } catch (error) {
    console.error('Error fetching topic options:', error);
  }
};

const copyToClipboard = async () => {
  try {
    await navigator.clipboard.writeText(apiData.value.api_key);
    buttonIcon.value = faCheck; // 复制成功后变成 "✓"

    // 1秒后恢复图标
    setTimeout(() => {
      buttonIcon.value = faCopy;
    }, 1000);
  } catch (err) {
    console.error("复制失败:", err);
  }
};

const refresh_api_key = async () => {
  apiData.value.api_key = generateSecureApiKey();
}

const showSelectKnowledge = async () => {
  showKnowledge.value = true
}

const openKnowledgeDialog = async () => {
  selectedNodes.value.length = 0
  selectedFiles.value.length = 0
  if (treeListRef.value) {
    treeListRef.value.refreshTree()
  }
  await selectRows()
}

const closeKnowledgeDialog = async () => {
  fileTable.value?.clearSelection()
  showKnowledge.value = false
}

const onMouseDown = (event: MouseEvent) => {
  isDragging = true;
  startX = event.clientX;
  startLeftColSpan = leftColSpan.value;

  // 添加鼠标事件监听
  document.addEventListener('mousemove', onMouseMove);
  document.addEventListener('mouseup', onMouseUp);
};

const onMouseMove = (event: MouseEvent) => {
  if (isDragging) {
    const delta = event.clientX - startX; // 鼠标移动的距离
    const totalWidth = document.body.clientWidth; // 获取整个页面宽度
    const spanDelta = Math.round((delta / totalWidth) * 24); // 计算移动的 span 值

    // 更新左侧列的宽度，限制在 1 到 23 之间
    leftColSpan.value = Math.min(12, Math.max(4, startLeftColSpan + spanDelta));
  }
};

const onMouseUp = () => {
  isDragging = false;

  // 移除事件监听
  document.removeEventListener('mousemove', onMouseMove);
  document.removeEventListener('mouseup', onMouseUp);
};

const handleSelectedNodeChange = async (node: any) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    cur_node_id.value = node.data.id;
    cur_node_name.value = node.data.label;
    searchData.all = false;
    totalItems.value = await fileStore.getFiles(searchData, cur_node_id.value, 1, pageSize.value);
    currentPage.value = 1;
    tData.value = fileStore.knowledgeFileList;
  } finally {
    loading.close();
  }
};

const handleSelectionChange = (files: KnowledgeFile[], file: KnowledgeFile) => {

  const isSelected = files.some(item => item.file_id === file.file_id);


  if (!isSelected) {
    delFiles(file)


  } else {
    selectedFiles.value.push(file)
    // 去重：基于 `file_id` 生成 Map
    selectedFiles.value = Array.from(new Map(selectedFiles.value.map(item => [item.file_id, item])).values());

    const fileIds = new Set([
      ...selectedFiles.value.map(item => item.file_id).filter(id => id !== undefined) // 只取有效的 file_id
    ]);

    // 转回数组
    apiData.value.knowledge_file_ids = Array.from(fileIds);
  }


};


/**
 * 处理每页显示条目数变化
 * @param {number} val - 新的每页显示条目数
 */
const handleSizeChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await fileStore.getFiles(searchData, cur_node_id.value, currentPage.value, val);
    pageSize.value = val;
    tData.value = fileStore.knowledgeFileList;
    await selectRows()
  } finally {
    loading.close();
  }
};

const handleCurrentChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await fileStore.getFiles(searchData, cur_node_id.value, val, pageSize.value);
    currentPage.value = val;
    tData.value = fileStore.knowledgeFileList;
    await selectRows()
  } finally {
    loading.close();
  }
};

const selectRows = async () => {
  if (fileTable.value) {
    await nextTick(); // 确保数据已渲染
    if (selectedFiles.value && apiData.value.knowledge_file_ids.length == 0) {
      selectedFiles.value.forEach((selectedRow) => {
        const matchedRow = tData.value.find(row => row.file_id === selectedRow.file_id);
        if (matchedRow) {
          fileTable.value!.toggleRowSelection(matchedRow, true)
        }
      })
    } else {
      if (selectedFiles.value.length == 0) {
        await load_file_name()
      }
      apiData.value.knowledge_file_ids.forEach(id => {
        const matchedRow = tData.value.find(row => row.file_id === id);
        if (matchedRow) {
          fileTable.value!.toggleRowSelection(matchedRow, true)
        }
      })
    }
  }
}

const getKnowledgeNodesAndFiles = async () => {
  if (treeListRef.value) {
    apiData.value.knowledge_node_ids = treeListRef.value.getSelectedItems()
    apiData.value.knowledge_file_ids = apiData.value.knowledge_file_ids.filter(num => num !== -1);
    await closeKnowledgeDialog()
  }
}

const delNodes = async (id: number) => {
  node_ids.value = node_ids.value.filter(item => item !== id);
  apiData.value.knowledge_node_ids = node_ids.value;
  treeListRef.value?.setSelectedItems(node_ids.value)
}

const delFiles = async (file: KnowledgeFile | undefined) => {
  if (!file) return

  selectedFiles.value = selectedFiles.value.filter(item => item.file_id !== file.file_id);
  if (fileTable.value) {
    const matchedRow = tData.value.find(row => row.file_id === file.file_id);
    if (matchedRow) {
      fileTable.value!.toggleRowSelection(matchedRow, false)
    }
  }
  apiData.value.knowledge_file_ids = Array.from(
      new Set(
          selectedFiles.value
              .map(item => item.file_id)
              .filter((id): id is number => id !== undefined) // 过滤 undefined，确保类型是 number[]
      )
  );

  await selectRows()
}

const handleNodeChange = async (newIds: number[], nodes: Node[]) => {
  node_ids.value = newIds
  selectedNodes.value = nodes
  apiData.value.knowledge_node_ids = node_ids.value;
}
const ini_dialog = async () => {
  await fetchOptionsRobot(-1)
  await fetchOptionsTopic(-1)
}
// 组件挂载时调用
onMounted(async () => {
  apiData.value.api_key = generateSecureApiKey();

  document.removeEventListener('mousemove', onMouseMove);
  document.removeEventListener('mouseup', onMouseUp);
  const ps_key = localStorage.getItem("file_page_size");
  searchData.all = true;

  if (ps_key) {
    pageSize.value = Number(ps_key);
  }
  await handleCurrentChange(1);  // 初始化加载第一页数据
});
</script>

<template>
  <el-dialog v-model="apiDialog"
             width="1100px"
             @open="ini_dialog"
             align-center center draggable :close-on-click-modal="false" :show-close="false">
    <template #header="">
      <div class="dialog-header">
        <span>{{ $t('message.menu-51') }}</span>
        <el-button type="danger" @click="closeDialog" circle size="small">
          <font-awesome-icon icon="xmark"/>
        </el-button>
      </div>
    </template>
    <el-form ref="apiForm" :model="apiData" :rules="apiRules" label-width="90px" :label-position="'right'"
    >
      <el-row>
        <el-alert v-if="newApiMessage" type="warning" :description="newApiMessage" :closable="false" show-icon
                  :effect="'light'" class="reg-alert">
        </el-alert>
        <el-row>
          <el-col :span="24">
            <div class="api-key">
              <el-form-item :label="$t('apiconfig.api_key')" prop="api_key">
                <div style="display: flex; justify-content: space-between; width: 600px">
                  <el-text v-model="apiData.api_key">{{ apiData.api_key }}</el-text>
                  <div>
                    <el-button type="primary" size="small" circle plain @click="copyToClipboard"
                               style="margin-left: 10px">
                      <font-awesome-icon :icon="buttonIcon"/>
                    </el-button>
                    <el-button type="primary" size="small" circle plain @click="refresh_api_key"
                               style="margin-left: 10px">
                      <font-awesome-icon icon="arrows-rotate"/>
                    </el-button>
                  </div>
                </div>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-col :span="12">
          <div style="width: 100%;">
            <el-form-item :label="$t('apiconfig.name')" prop="name">
              <el-input v-model="apiData.name"
                        :placeholder="$t('apiconfig.name-tip')"
                        clearable
                        show-word-limit
                        maxlength="100"
                        style="width: 100%;"></el-input>
            </el-form-item>
            <el-form-item :label="$t('apiconfig.description')" prop="description">
              <el-input
                  v-model="apiData.description"
                  :placeholder="$t('apiconfig.description-tip')"
                  clearable
                  type="textarea"
                  :rows="3"
                  show-word-limit
                  maxlength="200"></el-input>
            </el-form-item>
            <el-form-item :label="$t('message.robot')" prop="robot_desc">
              <el-select
                  v-model="robot"
                  value-key="rb_id"
                  style="width: 100%"
                  :change="fetchOptionsRobot(Number(robot.rb_id))"
              >
                <el-option
                    v-for="item in robot_options"
                    :key="item.rb_id"
                    :label="item.name"
                    :value="item"
                />
              </el-select>
              <el-text>
                <div v-if="robot_desc" style="margin-top: 10px">
                  <el-text class="multi-line-text">{{ robot_desc }}</el-text>
                </div>
              </el-text>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="width: 100%;">
            <el-form-item :label="$t('apiconfig.api_path')" prop="api_path">
              <el-input v-model="apiData.api_path"
                        :placeholder="$t('apiconfig.api_path-tip')"
                        clearable
                        show-word-limit
                        maxlength="100"></el-input>
            </el-form-item>
            <el-form-item :label="$t('apiconfig.llm_type')" prop="llm_type" style="height: 28px">
              <el-radio-group v-model="apiData.llm_type">
                <el-radio value="main_llm">{{ $t('chat.main-llm') }}</el-radio>
                <el-radio value="small_llm"> {{ $t('chat.small-llm') }}</el-radio>
                <el-radio value="logic_llm">{{ $t('chat.logic-llm') }}</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-row style="width: 100%">
              <el-col :span="12">
                <el-form-item :label="$t('apiconfig.output_json')" prop="output_json" style="height: 25px">
                  <el-switch
                      v-model="apiData.output_json"
                      inline-prompt
                      :active-text="$t('message.yes')"
                      :inactive-text="$t('message.no')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$t('message.publish')" prop="publish" style="height: 25px">
                  <el-switch
                      v-model="apiData.publish"
                      inline-prompt
                      :active-text="$t('message.yes')"
                      :inactive-text="$t('message.no')"
                  />
                </el-form-item>
              </el-col>

            </el-row>
            <el-form-item :label="$t('message.menu-13')" prop="topic_desc">
              <el-select
                  v-model="topic"
                  value-key="tp_id"
                  style="width: 100%"
                  :change="fetchOptionsTopic(Number(topic.tp_id))"
              >
                <el-option
                    v-for="item in topic_options"
                    :key="item.tp_id"
                    :label="item.name"
                    :value="item"
                />
              </el-select>
              <el-text>
                <div v-if="topic_desc" style="margin-top: 10px">
                  <el-text class="multi-line-text">{{ topic_desc }}</el-text>
                </div>
              </el-text>
            </el-form-item>
          </div>
        </el-col>
        <el-row>
          <el-col :span="24">
            <div style="width: 100%; display: flex; justify-content: flex-start;">
              <el-form-item :label="$t('message.menu-4')" prop="apiData.knowledge_node_ids" style="height: 25px">
                <input type="hidden" v-model="apiData.knowledge_node_ids"/>
                <input type="hidden" v-model="apiData.knowledge_file_ids"/>
                <el-button @click="showSelectKnowledge" round type="primary">
                  {{ $t('apiconfig.select-knowledge') + $t('message.menu-4') }}
                </el-button>
              </el-form-item>
            </div>
          </el-col>
          <el-row :gutter="20" style="display: flex; align-items: flex-start">
            <el-col :span="11" class="display-card">
              <el-divider content-position="left">
                <div class="title_text">{{ $t('apiconfig.knowledge-node-ids') }}</div>
              </el-divider>
              <div class="tags-row">
                <el-tag
                    v-for="tag in selectedNodes"
                    :key="tag.id"
                    round
                    effect="plain"
                >
                  <div class="truncate-tag">
                    {{ tag.label }}
                  </div>
                </el-tag>
              </div>
            </el-col>
            <el-col :span="12" class="display-card">
              <el-divider content-position="left">
                <div class="title_text">{{ $t('apiconfig.knowledge-file-ids') }}</div>
              </el-divider>
              <div class="tags-row">
                <el-tag
                    v-for="tag in selectedFiles"
                    :key="tag.file_id"
                    round
                    effect="plain"
                >
                  <div class="truncate-tag">
                    {{ tag.name }}
                  </div>
                </el-tag>
              </div>
            </el-col>
          </el-row>
        </el-row>
      </el-row>
    </el-form>
    <el-dialog
        v-model="showKnowledge"
        width="1200px"
        style="min-width: 1200px"
        @open="openKnowledgeDialog"
        append-to-body center draggable align-center :close-on-click-modal="false" :show-close="false"
    >
      <template #header="">
        <div class="dialog-header">
          <span>{{ $t('apiconfig.select-knowledge') + $t('message.menu-4') }}</span>
          <el-button type="danger" @click="closeKnowledgeDialog" circle size="small">
            <font-awesome-icon icon="xmark"/>
          </el-button>
        </div>
      </template>
      <el-row class="top-row">
        <el-col :span="23">
          <el-form ref="searchForm"
                   :model="searchData"
                   :inline="true"
                   class="search-form"
                   @submit.prevent
          >
            <el-form-item prop="searchAll" class="top-input">
              <span style="margin-right: 6px">{{ t("knowledge.all") }}</span>
              <el-switch
                  v-model="searchData.all"
                  inline-prompt
                  :active-text="$t('message.yes')"
                  :inactive-text="$t('message.no')"
              />
            </el-form-item>
            <el-divider direction="vertical" class="set-color"/>
            <el-form-item prop="searchKey" class="top-input">
              <el-input v-model="searchData.keyword" :placeholder="$t('message.search-key')"
                        @keyup.enter="searchFiles(searchData)"
                        style="width: 800px;"
                        clearable></el-input>
            </el-form-item>
            <el-button type="primary" @click="searchFiles(searchData)" circle plain>
              <template #default>
                <font-awesome-icon icon="magnifying-glass"/>
              </template>
            </el-button>
          </el-form>
        </el-col>
      </el-row>
      <el-row class="resizable-row">
        <el-col :span="leftColSpan" class="left-col">
          <div style="width: 100%;">
            <TreeList ref="treeListRef" treeName="file"
                      :node_ids=node_ids
                      :m_select="true"
                      @selected-node-changed="handleSelectedNodeChange"
                      @updateNodeIds="handleNodeChange"
            ></TreeList>
          </div>
        </el-col>
        <el-divider
            direction="vertical"
            class="divider"
            @mousedown="onMouseDown"
        ></el-divider>
        <el-col :span="23-leftColSpan" class="right-col">
          <el-table
              ref="fileTable"
              :data="tData"
              row-key="file_id"
              highlight-current-row
              :height="350"
              @select="handleSelectionChange">
            <el-table-column fixed="left" type="selection" width="35"/>
            <el-table-column prop="name" :label="$t('knowledge.file-name')" sortable min-width="200">
              <template #default="{ row }">
                <el-text>{{ row.name }}</el-text>
              </template>
            </el-table-column>
            <el-table-column prop="version" :label="$t('knowledge.file-version')" width="80" align="center" sortable>
              <template #default="{ row }">
                <el-text size="default" type="primary">V {{ String(row.version).padStart(2, '0') }}</el-text>
              </template>
            </el-table-column>
            <el-table-column prop="modify_datetime" :label="$t('knowledge.upload-date')" width="150" align="center"
                             sortable>
              <template #default="{ row }">
                {{ formatDate(t,row.modify_datetime, 'yyyy-MM-dd HH:mm') }}
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              v-model:current-page="currentPage"
              v-model:page-size="pageSize"
              :page-sizes="[20, 50,100,500]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalItems"
              @size-change="handleSizeChange(pageSize)"
              @current-change="handleCurrentChange(currentPage)"
              style="justify-content: center"
          />
        </el-col>
      </el-row>
      <el-row :gutter="20" style="display: flex; align-items: flex-start">
        <el-col :span="11" class="display-card">
          <el-divider content-position="left">
            <div class="title_text">{{ $t('apiconfig.knowledge-node-ids') }}</div>
          </el-divider>
          <div class="tags-row">
            <el-tag
                v-for="tag in selectedNodes"
                :key="tag.id"
                closable round
                effect="dark"
                :disable-transitions="false"
                @close="delNodes(tag.id)"
            >
              <div class="truncate-tag">
                {{ tag.label }}
              </div>
            </el-tag>
          </div>
        </el-col>
        <el-col :span="12" class="display-card">
          <el-divider content-position="left">
            <div class="title_text">{{ $t('apiconfig.knowledge-file-ids') }}</div>
          </el-divider>
          <div class="tags-row">
            <el-tag
                v-for="tag in selectedFiles"
                :key="tag.file_id"
                closable round
                effect="dark"
                :disable-transitions="false"
                @close="delFiles(tag)"
            >
              <div class="truncate-tag">
                {{ tag.name }}
              </div>
            </el-tag>
          </div>
        </el-col>
      </el-row>
      <template #footer>
        <div>
          <el-button type="primary" @click="getKnowledgeNodesAndFiles">
            <font-awesome-icon icon="check" class="icon-text-button"/>
            {{ $t('message.confirm') }}
          </el-button>
          <el-button type="danger" @click="closeKnowledgeDialog" plain>
            <font-awesome-icon icon="xmark" class="icon-text-button"/>
            {{ $t('message.cancel') }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <template #footer>
      <div>
        <el-button type="primary" @click="save_apiconfig(apiForm)">
          <font-awesome-icon icon="check" class="icon-text-button"/>
          {{ $t('message.save') }}
        </el-button>
        <el-button type="danger" @click="closeDialog" plain>
          <font-awesome-icon icon="xmark" class="icon-text-button"/>
          {{ $t('message.cancel') }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';


.top-row {
  margin-bottom: 15px;
  height: auto;
}

.top-input {
  margin: 0 5px 0 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  width: auto;
}


.el-divider {
  background-color: #EBEDF0;

  html.dark & {
    background-color: $main-background-dark;
  }
}

:deep(.el-divider__text) {
  background-color: $menu-bg !important;
  border-radius: 10px !important;
  padding: 1px 15px 2px 15px;
}

.div_right {
  justify-content: flex-end;
  width: 99%;
  display: flex;
}

.divider_text {
  font-weight: bold;
  color: $header-font-color-light;
}

/* 注册专用警告框样式 */
.reg-alert {
  min-height: 50px; /* 最小高度适用于较少内容 */
  margin: 5px 0 10px 0;
  padding: 0 30px 0 30px; /* 无顶部外边距 */
  width: 97%;
}


.div-row {
  height: 148px;
  width: 148px;
  display: flex; /* 使用 flex 布局以居中图片 */
  justify-content: center;
  align-items: center;
  margin-right: 5px;

}

.api-key {
  height: 35px;
  padding-top: 4px;
  width: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  background: $notification-bg;

  html.dark & {
    background: $notification-dark-bg;
  }
}


.resizable-row {
  display: flex;
  align-items: stretch;
  height: fit-content;
}

.left-col,
.right-col {
  overflow: hidden; /* 内容超出时隐藏 */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: center;
  align-content: center;
}

.divider {
  cursor: col-resize;
  user-select: none;
  height: auto;
  width: 2px;
  background: $header-background-light;

  html.dark & {
    background-color: $footer-font-color-light;
  }
}

.divider:hover {
  background-color: $enabled;

  html.dark & {
    background-color: $header-font-color-light;
  }
}

.display-card {
  border-radius: 25px;
  background: $chat-pop;
  margin: 15px 0 10px 10px;
  padding: 5px 30px 20px 30px;
  overflow: hidden; /* 内容超出时隐藏 */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 150px;

  html.dark & {
    background: $footer-font-color-light;
  }
}


</style>