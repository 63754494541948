<script setup lang="ts" xmlns:el-col="http://www.w3.org/1999/html">
import {ref} from "vue" // 导入 Vue 的核心功能
import {useI18n} from "vue-i18n" // 导入国际化插件
import {ElScrollbar} from "element-plus";
import {autoHeight} from "@/services/tools";
import {Network} from 'vis-network';
import 'vis-network/styles/vis-network.css';
import {useFileStore} from "@/stores/fileStore"

const {t} = useI18n()

const graphDialog = ref<boolean>(false)
const graphHeight = autoHeight(240);
const sliceHeight = autoHeight(240);
const currentPage = ref(1);  // 当前页码
const pageSize = ref(10);  // 每页显示条目数
const totalItems = ref(0);  // 总条目数
const networkContainer = ref<HTMLDivElement | null>(null);
const networkInstance = ref<Network | null>(null);
const fileStore = useFileStore();
const data = ref<{ nodes: any[], edges: any[], total: number }>({nodes: [], edges: [], total: 0});
const activeTab = ref<string>('slice') // 默认打开“概览”页
const slice_loading = ref<boolean>(false)
const options = {
  nodes: {
    shape: 'circle',
    font: {
      size: 14,
    },
    color: {
      highlight: {
        background: "rgb(248, 152.1, 152.1)", // 背景红色
        border: "rgb(248, 152.1, 152.1)"  // 背景红色
      }
    },
  },
  edges: {
    arrows: 'to',
    font: {
      align: 'middle',
      size: 12,
    },
  },
  groups: {
    Document: {
      color: {
        background: 'rgb(51.2, 126.4, 204)',
        border: 'rgb(51.2, 126.4, 204)',
      },
      widthConstraint: {
        minimum: 70,
        maximum: 70, // 固定大小
      },
    },
    DocumentSlice: {
      color: {
        background: 'rgb(121.3, 187.1, 255)',
        border: 'rgb(121.3, 187.1, 255)',
      },
      widthConstraint: {
        minimum: 50,
        maximum: 50, // 固定大小
      },
    },
    Entity: {
      color: {
        background: 'rgb(247.5, 227.1, 196.5)',
        border: 'rgb(247.5, 227.1, 196.5)',
      },
      widthConstraint: {
        minimum: 30,
        maximum: 30, // 固定大小
      },
    },
    Concept: {
      color: {
        background: 'rgb(209.4, 236.7, 195.9)',
        border: 'rgb(209.4, 236.7, 195.9)',
      },
      widthConstraint: {
        minimum: 30,
        maximum: 30, // 固定大小
      },
    },
  },
  physics: {
    enabled: true,
  },
  layout: {
    improvedLayout: false
  }
}


// 定义组件的 props 和 emits
const props = defineProps({ // 定义 props
  file_hash: String,
  file_name: String
})
const emit = defineEmits(["update:isShow"]) // 定义 emits


const ini_dialog = async () => {

  await handleCurrentChange(1)
}

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

const load_graph = async (name: string) => {
  if (name != 'graph') return
  if (networkContainer.value && data.value) {
    slice_loading.value = true

    await sleep(200)
    networkInstance.value = new Network(networkContainer.value, data.value, options);

    slice_loading.value = false
  }
}

const save_graph = async () => {

}

// 关闭对话框并重置表单
const closeDialog = async () => {
  try {
    graphDialog.value = false // 隐藏对话框
    data.value = {nodes: [], edges: [], total: 0}
    activeTab.value = 'slice'
    // 清除图形时
    if (networkInstance.value) {
      networkInstance.value.destroy();
      networkInstance.value = null;
    }

    emit('update:isShow', false) // 触发 update:isShow 事件

  } catch (error) {
    console.error('Error closing dialog:', error) // 打印错误信息
  }
};

const handleSizeChange = async (val: number) => {
  if (props.file_hash) {
    slice_loading.value = true
    data.value = await fileStore.getFileGraph(props.file_hash, currentPage.value, val)
    totalItems.value = data.value.total
    pageSize.value = val;
    if (activeTab.value=="graph"){
      await load_graph("graph")
    }
    slice_loading.value = false
  }
};

const handleCurrentChange = async (val: number) => {
  if (props.file_hash) {
    slice_loading.value = true
    data.value = await fileStore.getFileGraph(props.file_hash, val, pageSize.value)
    totalItems.value = data.value.total
    currentPage.value = val;
    if (activeTab.value=="graph"){
      await load_graph("graph")
    }
    slice_loading.value = false
  }
};

</script>

<template>
  <el-dialog :visible="graphDialog"
             width="90%"
             @open="ini_dialog"
             align-center center :close-on-click-modal="false" :show-close="false" style="min-width: 1300px">
    <template #header="">
      <div class="dialog-header">
        <span>{{ file_name }}</span>
        <el-button type="danger" @click="closeDialog" circle size="small">
          <font-awesome-icon icon="xmark"/>
        </el-button>
      </div>
    </template>
    <el-tabs v-model="activeTab" type="border-card" class="dialog-graph" @tab-change="load_graph">
      <el-tab-pane name="slice">
        <template #label>
          <div>
            {{ $t("knowledge.slice") }}
          </div>
        </template>
        <el-scrollbar v-loading="slice_loading" class="chat-content" :height="sliceHeight">
          <div v-if="data.nodes">
            <div v-for="node in data.nodes" :key="node.id">
              <div v-if="node.group =='DocumentSlice'">
                <el-card shadow="hover" class="system-card">
                  <template #header>
                    <div class="card-header">
                      <el-text tag="b" size="large">{{ $t("knowledge.slice-num", {number: node.label}) }}</el-text>
                    </div>
                  </template>
                  <el-text>{{ node.title }}</el-text>
                  <template #footer>
                    <div class="show-gap" style="flex-direction: column">
                      <div>
                        <el-text tag="b" size="large">
                          {{ $t("knowledge.entity") }}
                        </el-text>
                      </div>
                      <div class="show-gap">
                        <el-tag
                            v-for="entity in data.nodes.filter
                              (n => data.edges.some(e => e.from === node.id && e.to === n.id && n.group === 'Entity'))"
                            type="warning"
                            round
                            :key="entity.id">
                          {{ entity.title }}
                        </el-tag>
                      </div>
                    </div>
                    <br/>
                    <div class="show-gap" style="flex-direction: column">
                      <div>
                        <el-text tag="b" size="large">
                          {{ $t("knowledge.concept") }}
                        </el-text>
                      </div>
                      <div class="show-gap">
                        <el-tag
                            v-for="concept in data.nodes.filter
                              (n => data.edges.some(e => e.from === node.id && e.to === n.id && n.group === 'Concept'))"
                            type="success"
                            round
                            :key="concept.id">
                          {{ concept.title }}
                        </el-tag>
                      </div>
                    </div>
                  </template>
                </el-card>
              </div>
            </div>
          </div>
        </el-scrollbar>
        <el-pagination
            v-model:current-page="currentPage"
            v-model:page-size="pageSize"
            :page-sizes="[10,20, 30]"
            layout="total, sizes ,prev, pager, next, jumper"
            :total="totalItems"
            @size-change="handleSizeChange(pageSize)"
            @current-change="handleCurrentChange(currentPage)"
        />
      </el-tab-pane>
      <el-tab-pane name="graph">
        <template #label>
          <div>
            {{ $t("knowledge.graph") }}
          </div>
        </template>
        <el-row :gutter="10">
          <el-col :span="21">
            <el-scrollbar v-loading="slice_loading" class="chat-content" :height="graphHeight">
              <div ref="networkContainer" :style="{ width: '100%', height: graphHeight + 'px' }"></div>
            </el-scrollbar>
          </el-col>
          <el-col :span="3">
            <el-text line-clamp="10">左侧知识图谱实时展示当前文档页面的内容切片、实体与知识点间的关联关系。<br/><br/>可视化网络结构直观呈现知识节点间的逻辑关系与架构。<br/><br/>支持翻页浏览及鼠标滚轮缩放操作。</el-text>
          </el-col>
        </el-row>
        <el-pagination
            v-model:current-page="currentPage"
            v-model:page-size="pageSize"
            :page-sizes="[10,20, 30]"
            layout="total, sizes ,prev, pager, next, jumper"
            :total="totalItems"
            @size-change="handleSizeChange(pageSize)"
            @current-change="handleCurrentChange(currentPage)"
        />
      </el-tab-pane>
    </el-tabs>
    <template #footer>
      <div>
        <el-button type="danger" @click="closeDialog" plain>
          <font-awesome-icon icon="xmark" class="icon-text-button"/>
          {{ $t('message.close') }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';

.chat-content {
  padding: 15px;
  margin: 0;
  overflow-y: auto;
  will-change: transform; /* 提示浏览器优化 */
  html.dark & {
    background-color: $main-background-dark; /* 深色模式背景色 */
  }
}

.dialog-graph {
  min-width: 1000px;
  min-height: 300px;
}

.el-card :deep(.el-card__header) {
  background-color: $menu-hover-bg;
  border: 0;
  padding: 10px 20px 10px 20px;

  html.dark & {
    background-color: $menu-sub-dark-bg
  }
}

.el-card :deep(.el-card__footer) {
  border: 1px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  padding: 20px 0 20px 0;
}

.show-gap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-right: 20px;
  padding-left: 20px
}
</style>