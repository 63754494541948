<script setup lang="ts">
import {onMounted, ref} from 'vue';
import {autoHeight, showNotification} from '@/services/tools';
import treeIcon from '@/components/treeIcon.vue'; // 引入你的自定义图标组件
import {TreeNode} from "@/types/system";
import {useFileStore} from "@/stores/fileStore";
import {useI18n} from "vue-i18n"
import {get_tree} from "@/services/systemService"; // 导入国际化插件

const treeData = ref<TreeNode[]>([]);
const selectTreeNodeDialog = ref<boolean>(false)
const selectedNode = ref<any>(null); // 存储当前选中的节点
const fileStore = useFileStore();  // 使用用户存储
const treeRef = ref();
const errorMessage = ref<string>('')
const contentHeight = autoHeight(300);

// 使用国际化功能
const {t} = useI18n();

// 定义 trans
interface Props {
  // 传递的 treeName 必须是字符串
  file_ids: number[]
  treeName: string
}

// 使用 defineProps 接收父组件传递的 props
const props = defineProps<Props>();
const emit = defineEmits(["refreshList", "update:isShow"]) // 定义 emits

const tree_node = {
  value: 'id',
  children: 'children',
  label: 'label'
};

const handleClickNode = (data: any, node: any) => {
  selectedNode.value = node;  // 将选中的节点存储
  errorMessage.value =""
}

const refreshTree = async () => {
  errorMessage.value = ""
  treeRef.value = null
  treeData.value = await get_tree(props.treeName)
  selectedNode.value = null
}


const refreshFatherList = () => {
  try {
    emit("refreshList") // 触发 refreshList 事件
  } catch (error) {
    console.error('Error refreshing father list:', error) // 打印错误信息
  }
}
// 关闭对话框并重置表单
const closeDialog = async () => {
  try {
    selectTreeNodeDialog.value = false // 隐藏对话框
    errorMessage.value = ""
    await refreshTree()
    refreshFatherList()
    emit('update:isShow', false) // 触发 update:isShow 事件
  } catch (error) {
    console.error('Error closing dialog:', error) // 打印错误信息
  }
}

const move_file = async () => {
  try {
    if (selectedNode.value) {
      errorMessage.value =""
      const response = await fileStore.moveFiles(selectedNode.value.data.id, props.file_ids)
      const count = response.message
      showNotification("success", t("knowledge.move-title"), t("knowledge.move-file-success", {count}))
      await closeDialog()
    } else {
      errorMessage.value = t('message.last-one')
    }
  } catch (error: any) {
    errorMessage.value = ""
    let detail = "null"
    if (error.response) {
      detail = error.response.data.detail;
    }
    switch (detail) {
      case 'InvalidInput':
        errorMessage.value = t('message.last-one')
        break;
      case 'null':
        break;
      default:
        showNotification('error', '未知状态', error.response.data.detail);
        break;
    }
  }
}

onMounted(async () => {
  try {
    treeData.value = await get_tree(props.treeName)

  } catch (error) {
    console.error('Error fetching tree data:', error);
  }
});

</script>

<template>
  <el-dialog :visible="selectTreeNodeDialog"  width="1100px"
             align-center center draggable :close-on-click-modal="false" :show-close="false">
    <template #header="">
      <div class="dialog-header">
        <span>{{ $t('knowledge.move-title') }}</span>
        <el-button type="danger" @click="closeDialog" circle size="small">
          <font-awesome-icon icon="xmark"/>
        </el-button>
      </div>
    </template>
    <el-alert v-if="errorMessage" type="warning" :description="errorMessage" :closable="false" show-icon
              :effect="'light'" class="error_alert">
    </el-alert>
    <el-scrollbar class="tree-content" :height="contentHeight">
      <el-tree
          ref="treeRef"
          :data="treeData"
          :props="tree_node"
          node-key="id"
          highlight-current
          :default-expanded-keys="[-1]"
          accordion
          :icon="treeIcon"
          @node-click="handleClickNode"
          class="tree-top"
      >
        <template #default="{ data }">
          <div class="top-row">
            <div style="display: flex;flex-direction: row; justify-content: center;align-content: center">
              <div v-if="data.file_count!=0">
                <el-badge :value="data.file_count" :max="999999" :offset="[15, 9]"
                          type="warning">
                  <div style="display: flex; flex-direction: row;gap: 10px">
                    <div v-if="data.children.length === 0 && data.parent_id != -1">
                      <font-awesome-icon icon="circle" size="xs"/>
                    </div>

                    <el-text v-if="data.parent_id === -1" type="primary" tag="b">
                      {{ data.label }}
                    </el-text>
                    <span v-else>{{ data.label }}</span>
                  </div>

                </el-badge>
              </div>
              <div v-else>
                <div style="display: flex; flex-direction: row;gap: 10px">
                  <div v-if="data.children.length === 0 && data.parent_id != -1">
                    <font-awesome-icon icon="circle" size="xs"/>
                  </div>
                  <el-text v-if="data.parent_id === -1" type="primary" tag="b">
                    {{ data.label }}
                  </el-text>
                  <span v-else>{{ data.label }}</span>
                </div>
              </div>
            </div>
            <div style="display: flex;flex-direction: row;gap: 15px">
              <div v-if="data.label_names" class="permission_tag">
                <div v-for="(item, index) in data.label_names.slice(0, 6)" :key="index">
                  <el-tag
                      :key="index"
                      type="success"
                      style="margin-right: 3px;"
                  >
                    <div v-if="index<5">{{ item }}</div>
                    <div v-else-if="index==5">...</div>
                  </el-tag>
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-tree>
      <div class="overlay-text">
        <el-text size="large" tag="b" type="primary">{{ $t('knowledge.title') }}</el-text>
        <el-button type="primary" size="small" @click="refreshTree" circle style="margin-top: 8px">
          <template #default>
            <font-awesome-icon icon="arrow-rotate-right"/>
          </template>
        </el-button>

      </div>
    </el-scrollbar>
    <template #footer>
      <div>
        <el-button type="primary" @click="move_file">
          <font-awesome-icon icon="check" class="icon-text-button"/>
          {{ $t('message.move') }}
        </el-button>
        <el-button type="danger" @click="closeDialog" plain>
          <font-awesome-icon icon="xmark" class="icon-text-button"/>
          {{ $t('message.cancel') }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';

.item {
  padding-right: 50px;
  margin: 5px 0 5px 0;
}

.permission_tag {
  padding-left: 30px;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
}

.el-badge {
  border: 0;
}

.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  width: 100%;
  padding-right: 20px;
  padding-left: 5px;
}

.el-tree :deep(.el-tree-node__content) {
  height: 38px;
  border-radius: 20px;
}

.el-tree :deep(.el-tree-node.is-current > .el-tree-node__content) {
  background-color: $notification-bg;

  html.dark & {
    background-color: $menu-sub-dark-bg;
  }
}

.tree-content {
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 15px;


  html.dark & {
    background-color: transparent; /* 深色模式背景色 */
  }
}

.tree-top {
  margin-top: 3px;
}

.overlay-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* 确保宽度和 el-tree 一样 */
  height: 40px;
  background-color: $table-header-bg-color;
  z-index: 10; /* 确保覆盖在树的上方 */
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid $header-font-color-light;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  html.dark & {
    background-color: $menu-sub-dark-bg;
  }
}


.error_alert {
  min-height: 50px; /* 最小高度适用于较少内容 */
  margin: 5px 0 10px 0;
  padding: 0 30px 0 30px; /* 无顶部外边距 */
  width: 97%;
}

</style>